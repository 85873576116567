/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$arrow-2down-icon-name: 'arrow-2down_icon';
$arrow-2down-icon-x: 244px;
$arrow-2down-icon-y: 0px;
$arrow-2down-icon-offset-x: -244px;
$arrow-2down-icon-offset-y: 0px;
$arrow-2down-icon-width: 12px;
$arrow-2down-icon-height: 12px;
$arrow-2down-icon-total-width: 256px;
$arrow-2down-icon-total-height: 216px;
$arrow-2down-icon-image: '../img/sprite/sprite.png';
$arrow-2down-icon: (244px, 0px, -244px, 0px, 12px, 12px, 256px, 216px, '../img/sprite/sprite.png', 'arrow-2down_icon', );
$arrow-double-down-icon-name: 'arrow-double-down_icon';
$arrow-double-down-icon-x: 66px;
$arrow-double-down-icon-y: 166px;
$arrow-double-down-icon-offset-x: -66px;
$arrow-double-down-icon-offset-y: -166px;
$arrow-double-down-icon-width: 24px;
$arrow-double-down-icon-height: 16px;
$arrow-double-down-icon-total-width: 256px;
$arrow-double-down-icon-total-height: 216px;
$arrow-double-down-icon-image: '../img/sprite/sprite.png';
$arrow-double-down-icon: (66px, 166px, -66px, -166px, 24px, 16px, 256px, 216px, '../img/sprite/sprite.png', 'arrow-double-down_icon', );
$arrow-double-up-icon-name: 'arrow-double-up_icon';
$arrow-double-up-icon-x: 100px;
$arrow-double-up-icon-y: 166px;
$arrow-double-up-icon-offset-x: -100px;
$arrow-double-up-icon-offset-y: -166px;
$arrow-double-up-icon-width: 24px;
$arrow-double-up-icon-height: 16px;
$arrow-double-up-icon-total-width: 256px;
$arrow-double-up-icon-total-height: 216px;
$arrow-double-up-icon-image: '../img/sprite/sprite.png';
$arrow-double-up-icon: (100px, 166px, -100px, -166px, 24px, 16px, 256px, 216px, '../img/sprite/sprite.png', 'arrow-double-up_icon', );
$basket-white-icon-name: 'basket-white_icon';
$basket-white-icon-x: 102px;
$basket-white-icon-y: 52px;
$basket-white-icon-offset-x: -102px;
$basket-white-icon-offset-y: -52px;
$basket-white-icon-width: 15px;
$basket-white-icon-height: 19px;
$basket-white-icon-total-width: 256px;
$basket-white-icon-total-height: 216px;
$basket-white-icon-image: '../img/sprite/sprite.png';
$basket-white-icon: (102px, 52px, -102px, -52px, 15px, 19px, 256px, 216px, '../img/sprite/sprite.png', 'basket-white_icon', );
$basket-icon-name: 'basket_icon';
$basket-icon-x: 0px;
$basket-icon-y: 197px;
$basket-icon-offset-x: 0px;
$basket-icon-offset-y: -197px;
$basket-icon-width: 15px;
$basket-icon-height: 19px;
$basket-icon-total-width: 256px;
$basket-icon-total-height: 216px;
$basket-icon-image: '../img/sprite/sprite.png';
$basket-icon: (0px, 197px, 0px, -197px, 15px, 19px, 256px, 216px, '../img/sprite/sprite.png', 'basket_icon', );
$building-tab-icon-name: 'building-tab_icon';
$building-tab-icon-x: 214px;
$building-tab-icon-y: 0px;
$building-tab-icon-offset-x: -214px;
$building-tab-icon-offset-y: 0px;
$building-tab-icon-width: 20px;
$building-tab-icon-height: 20px;
$building-tab-icon-total-width: 256px;
$building-tab-icon-total-height: 216px;
$building-tab-icon-image: '../img/sprite/sprite.png';
$building-tab-icon: (214px, 0px, -214px, 0px, 20px, 20px, 256px, 216px, '../img/sprite/sprite.png', 'building-tab_icon', );
$cart-red-icon-name: 'cart-red_icon';
$cart-red-icon-x: 127px;
$cart-red-icon-y: 52px;
$cart-red-icon-offset-x: -127px;
$cart-red-icon-offset-y: -52px;
$cart-red-icon-width: 29px;
$cart-red-icon-height: 23px;
$cart-red-icon-total-width: 256px;
$cart-red-icon-total-height: 216px;
$cart-red-icon-image: '../img/sprite/sprite.png';
$cart-red-icon: (127px, 52px, -127px, -52px, 29px, 23px, 256px, 216px, '../img/sprite/sprite.png', 'cart-red_icon', );
$cart-white-icon-name: 'cart-white_icon';
$cart-white-icon-x: 106px;
$cart-white-icon-y: 136px;
$cart-white-icon-offset-x: -106px;
$cart-white-icon-offset-y: -136px;
$cart-white-icon-width: 24px;
$cart-white-icon-height: 20px;
$cart-white-icon-total-width: 256px;
$cart-white-icon-total-height: 216px;
$cart-white-icon-image: '../img/sprite/sprite.png';
$cart-white-icon: (106px, 136px, -106px, -136px, 24px, 20px, 256px, 216px, '../img/sprite/sprite.png', 'cart-white_icon', );
$cart-icon-name: 'cart_icon';
$cart-icon-x: 72px;
$cart-icon-y: 136px;
$cart-icon-offset-x: -72px;
$cart-icon-offset-y: -136px;
$cart-icon-width: 24px;
$cart-icon-height: 20px;
$cart-icon-total-width: 256px;
$cart-icon-total-height: 216px;
$cart-icon-image: '../img/sprite/sprite.png';
$cart-icon: (72px, 136px, -72px, -136px, 24px, 20px, 256px, 216px, '../img/sprite/sprite.png', 'cart_icon', );
$checkbox-check-icon-name: 'checkbox-check_icon';
$checkbox-check-icon-x: 67px;
$checkbox-check-icon-y: 52px;
$checkbox-check-icon-offset-x: -67px;
$checkbox-check-icon-offset-y: -52px;
$checkbox-check-icon-width: 25px;
$checkbox-check-icon-height: 24px;
$checkbox-check-icon-total-width: 256px;
$checkbox-check-icon-total-height: 216px;
$checkbox-check-icon-image: '../img/sprite/sprite.png';
$checkbox-check-icon: (67px, 52px, -67px, -52px, 25px, 24px, 256px, 216px, '../img/sprite/sprite.png', 'checkbox-check_icon', );
$checkbox-icon-name: 'checkbox_icon';
$checkbox-icon-x: 53px;
$checkbox-icon-y: 86px;
$checkbox-icon-offset-x: -53px;
$checkbox-icon-offset-y: -86px;
$checkbox-icon-width: 24px;
$checkbox-icon-height: 24px;
$checkbox-icon-total-width: 256px;
$checkbox-icon-total-height: 216px;
$checkbox-icon-image: '../img/sprite/sprite.png';
$checkbox-icon: (53px, 86px, -53px, -86px, 24px, 24px, 256px, 216px, '../img/sprite/sprite.png', 'checkbox_icon', );
$cityscape-tab-icon-name: 'cityscape-tab_icon';
$cityscape-tab-icon-x: 214px;
$cityscape-tab-icon-y: 59px;
$cityscape-tab-icon-offset-x: -214px;
$cityscape-tab-icon-offset-y: -59px;
$cityscape-tab-icon-width: 19px;
$cityscape-tab-icon-height: 19px;
$cityscape-tab-icon-total-width: 256px;
$cityscape-tab-icon-total-height: 216px;
$cityscape-tab-icon-image: '../img/sprite/sprite.png';
$cityscape-tab-icon: (214px, 59px, -214px, -59px, 19px, 19px, 256px, 216px, '../img/sprite/sprite.png', 'cityscape-tab_icon', );
$click-gesture-icon-name: 'click-gesture_icon';
$click-gesture-icon-x: 187px;
$click-gesture-icon-y: 37px;
$click-gesture-icon-offset-x: -187px;
$click-gesture-icon-offset-y: -37px;
$click-gesture-icon-width: 16px;
$click-gesture-icon-height: 28px;
$click-gesture-icon-total-width: 256px;
$click-gesture-icon-total-height: 216px;
$click-gesture-icon-image: '../img/sprite/sprite.png';
$click-gesture-icon: (187px, 37px, -187px, -37px, 16px, 28px, 256px, 216px, '../img/sprite/sprite.png', 'click-gesture_icon', );
$doc-tab-icon-name: 'doc-tab_icon';
$doc-tab-icon-x: 25px;
$doc-tab-icon-y: 197px;
$doc-tab-icon-offset-x: -25px;
$doc-tab-icon-offset-y: -197px;
$doc-tab-icon-width: 15px;
$doc-tab-icon-height: 19px;
$doc-tab-icon-total-width: 256px;
$doc-tab-icon-total-height: 216px;
$doc-tab-icon-image: '../img/sprite/sprite.png';
$doc-tab-icon: (25px, 197px, -25px, -197px, 15px, 19px, 256px, 216px, '../img/sprite/sprite.png', 'doc-tab_icon', );
$down-white-icon-name: 'down-white_icon';
$down-white-icon-x: 169px;
$down-white-icon-y: 166px;
$down-white-icon-offset-x: -169px;
$down-white-icon-offset-y: -166px;
$down-white-icon-width: 16px;
$down-white-icon-height: 9px;
$down-white-icon-total-width: 256px;
$down-white-icon-total-height: 216px;
$down-white-icon-image: '../img/sprite/sprite.png';
$down-white-icon: (169px, 166px, -169px, -166px, 16px, 9px, 256px, 216px, '../img/sprite/sprite.png', 'down-white_icon', );
$down-icon-name: 'down_icon';
$down-icon-x: 72px;
$down-icon-y: 197px;
$down-icon-offset-x: -72px;
$down-icon-offset-y: -197px;
$down-icon-width: 16px;
$down-icon-height: 9px;
$down-icon-total-width: 256px;
$down-icon-total-height: 216px;
$down-icon-image: '../img/sprite/sprite.png';
$down-icon: (72px, 197px, -72px, -197px, 16px, 9px, 256px, 216px, '../img/sprite/sprite.png', 'down_icon', );
$enter-arrow-icon-name: 'enter-arrow_icon';
$enter-arrow-icon-x: 31px;
$enter-arrow-icon-y: 166px;
$enter-arrow-icon-offset-x: -31px;
$enter-arrow-icon-offset-y: -166px;
$enter-arrow-icon-width: 25px;
$enter-arrow-icon-height: 17px;
$enter-arrow-icon-total-width: 256px;
$enter-arrow-icon-total-height: 216px;
$enter-arrow-icon-image: '../img/sprite/sprite.png';
$enter-arrow-icon: (31px, 166px, -31px, -166px, 25px, 17px, 256px, 216px, '../img/sprite/sprite.png', 'enter-arrow_icon', );
$eye-icon-name: 'eye__icon';
$eye-icon-x: 134px;
$eye-icon-y: 166px;
$eye-icon-offset-x: -134px;
$eye-icon-offset-y: -166px;
$eye-icon-width: 25px;
$eye-icon-height: 15px;
$eye-icon-total-width: 256px;
$eye-icon-total-height: 216px;
$eye-icon-image: '../img/sprite/sprite.png';
$eye-icon: (134px, 166px, -134px, -166px, 25px, 15px, 256px, 216px, '../img/sprite/sprite.png', 'eye__icon', );
$fail-tab-icon-name: 'fail-tab_icon';
$fail-tab-icon-x: 159px;
$fail-tab-icon-y: 86px;
$fail-tab-icon-offset-x: -159px;
$fail-tab-icon-offset-y: -86px;
$fail-tab-icon-width: 15px;
$fail-tab-icon-height: 19px;
$fail-tab-icon-total-width: 256px;
$fail-tab-icon-total-height: 216px;
$fail-tab-icon-image: '../img/sprite/sprite.png';
$fail-tab-icon: (159px, 86px, -159px, -86px, 15px, 19px, 256px, 216px, '../img/sprite/sprite.png', 'fail-tab_icon', );
$loupe-red-icon-name: 'loupe-red_icon';
$loupe-red-icon-x: 214px;
$loupe-red-icon-y: 88px;
$loupe-red-icon-offset-x: -214px;
$loupe-red-icon-offset-y: -88px;
$loupe-red-icon-width: 18px;
$loupe-red-icon-height: 18px;
$loupe-red-icon-total-width: 256px;
$loupe-red-icon-total-height: 216px;
$loupe-red-icon-image: '../img/sprite/sprite.png';
$loupe-red-icon: (214px, 88px, -214px, -88px, 18px, 18px, 256px, 216px, '../img/sprite/sprite.png', 'loupe-red_icon', );
$loupe-white-icon-name: 'loupe-white_icon';
$loupe-white-icon-x: 214px;
$loupe-white-icon-y: 116px;
$loupe-white-icon-offset-x: -214px;
$loupe-white-icon-offset-y: -116px;
$loupe-white-icon-width: 18px;
$loupe-white-icon-height: 18px;
$loupe-white-icon-total-width: 256px;
$loupe-white-icon-total-height: 216px;
$loupe-white-icon-image: '../img/sprite/sprite.png';
$loupe-white-icon: (214px, 116px, -214px, -116px, 18px, 18px, 256px, 216px, '../img/sprite/sprite.png', 'loupe-white_icon', );
$loupe-icon-name: 'loupe_icon';
$loupe-icon-x: 214px;
$loupe-icon-y: 144px;
$loupe-icon-offset-x: -214px;
$loupe-icon-offset-y: -144px;
$loupe-icon-width: 18px;
$loupe-icon-height: 18px;
$loupe-icon-total-width: 256px;
$loupe-icon-total-height: 216px;
$loupe-icon-image: '../img/sprite/sprite.png';
$loupe-icon: (214px, 144px, -214px, -144px, 18px, 18px, 256px, 216px, '../img/sprite/sprite.png', 'loupe_icon', );
$mail-icon-name: 'mail_icon';
$mail-icon-x: 140px;
$mail-icon-y: 136px;
$mail-icon-offset-x: -140px;
$mail-icon-offset-y: -136px;
$mail-icon-width: 30px;
$mail-icon-height: 15px;
$mail-icon-total-width: 256px;
$mail-icon-total-height: 216px;
$mail-icon-image: '../img/sprite/sprite.png';
$mail-icon: (140px, 136px, -140px, -136px, 30px, 15px, 256px, 216px, '../img/sprite/sprite.png', 'mail_icon', );
$marker-icon-name: 'marker_icon';
$marker-icon-x: 187px;
$marker-icon-y: 109px;
$marker-icon-offset-x: -187px;
$marker-icon-offset-y: -109px;
$marker-icon-width: 15px;
$marker-icon-height: 20px;
$marker-icon-total-width: 256px;
$marker-icon-total-height: 216px;
$marker-icon-image: '../img/sprite/sprite.png';
$marker-icon: (187px, 109px, -187px, -109px, 15px, 20px, 256px, 216px, '../img/sprite/sprite.png', 'marker_icon', );
$mastercard-default-icon-name: 'mastercard-default_icon';
$mastercard-default-icon-x: 0px;
$mastercard-default-icon-y: 136px;
$mastercard-default-icon-offset-x: 0px;
$mastercard-default-icon-offset-y: -136px;
$mastercard-default-icon-width: 28px;
$mastercard-default-icon-height: 20px;
$mastercard-default-icon-total-width: 256px;
$mastercard-default-icon-total-height: 216px;
$mastercard-default-icon-image: '../img/sprite/sprite.png';
$mastercard-default-icon: (0px, 136px, 0px, -136px, 28px, 20px, 256px, 216px, '../img/sprite/sprite.png', 'mastercard-default_icon', );
$mastercard-icon-name: 'mastercard_icon';
$mastercard-icon-x: 127px;
$mastercard-icon-y: 0px;
$mastercard-icon-offset-x: -127px;
$mastercard-icon-offset-y: 0px;
$mastercard-icon-width: 50px;
$mastercard-icon-height: 42px;
$mastercard-icon-total-width: 256px;
$mastercard-icon-total-height: 216px;
$mastercard-icon-image: '../img/sprite/sprite.png';
$mastercard-icon: (127px, 0px, -127px, 0px, 50px, 42px, 256px, 216px, '../img/sprite/sprite.png', 'mastercard_icon', );
$operator-icon-name: 'operator_icon';
$operator-icon-x: 0px;
$operator-icon-y: 0px;
$operator-icon-offset-x: 0px;
$operator-icon-offset-y: 0px;
$operator-icon-width: 57px;
$operator-icon-height: 76px;
$operator-icon-total-width: 256px;
$operator-icon-total-height: 216px;
$operator-icon-image: '../img/sprite/sprite.png';
$operator-icon: (0px, 0px, 0px, 0px, 57px, 76px, 256px, 216px, '../img/sprite/sprite.png', 'operator_icon', );
$original-icon-name: 'original_icon';
$original-icon-x: 87px;
$original-icon-y: 86px;
$original-icon-offset-x: -87px;
$original-icon-offset-y: -86px;
$original-icon-width: 24px;
$original-icon-height: 24px;
$original-icon-total-width: 256px;
$original-icon-total-height: 216px;
$original-icon-image: '../img/sprite/sprite.png';
$original-icon: (87px, 86px, -87px, -86px, 24px, 24px, 256px, 216px, '../img/sprite/sprite.png', 'original_icon', );
$payment-method-icon-name: 'payment-method_icon';
$payment-method-icon-x: 187px;
$payment-method-icon-y: 0px;
$payment-method-icon-offset-x: -187px;
$payment-method-icon-offset-y: 0px;
$payment-method-icon-width: 17px;
$payment-method-icon-height: 27px;
$payment-method-icon-total-width: 256px;
$payment-method-icon-total-height: 216px;
$payment-method-icon-image: '../img/sprite/sprite.png';
$payment-method-icon: (187px, 0px, -187px, 0px, 17px, 27px, 256px, 216px, '../img/sprite/sprite.png', 'payment-method_icon', );
$phone-call-icon-name: 'phone-call_icon';
$phone-call-icon-x: 0px;
$phone-call-icon-y: 166px;
$phone-call-icon-offset-x: 0px;
$phone-call-icon-offset-y: -166px;
$phone-call-icon-width: 21px;
$phone-call-icon-height: 21px;
$phone-call-icon-total-width: 256px;
$phone-call-icon-total-height: 216px;
$phone-call-icon-image: '../img/sprite/sprite.png';
$phone-call-icon: (0px, 166px, 0px, -166px, 21px, 21px, 256px, 216px, '../img/sprite/sprite.png', 'phone-call_icon', );
$reviews-tab-icon-name: 'reviews-tab_icon';
$reviews-tab-icon-x: 214px;
$reviews-tab-icon-y: 172px;
$reviews-tab-icon-offset-x: -214px;
$reviews-tab-icon-offset-y: -172px;
$reviews-tab-icon-width: 19px;
$reviews-tab-icon-height: 13px;
$reviews-tab-icon-total-width: 256px;
$reviews-tab-icon-total-height: 216px;
$reviews-tab-icon-image: '../img/sprite/sprite.png';
$reviews-tab-icon: (214px, 172px, -214px, -172px, 19px, 13px, 256px, 216px, '../img/sprite/sprite.png', 'reviews-tab_icon', );
$skyscraper-tab-icon-name: 'skyscraper-tab_icon';
$skyscraper-tab-icon-x: 214px;
$skyscraper-tab-icon-y: 30px;
$skyscraper-tab-icon-offset-x: -214px;
$skyscraper-tab-icon-offset-y: -30px;
$skyscraper-tab-icon-width: 19px;
$skyscraper-tab-icon-height: 19px;
$skyscraper-tab-icon-total-width: 256px;
$skyscraper-tab-icon-total-height: 216px;
$skyscraper-tab-icon-image: '../img/sprite/sprite.png';
$skyscraper-tab-icon: (214px, 30px, -214px, -30px, 19px, 19px, 256px, 216px, '../img/sprite/sprite.png', 'skyscraper-tab_icon', );
$slider-arrow-next-icon-name: 'slider-arrow-next_icon';
$slider-arrow-next-icon-x: 187px;
$slider-arrow-next-icon-y: 139px;
$slider-arrow-next-icon-offset-x: -187px;
$slider-arrow-next-icon-offset-y: -139px;
$slider-arrow-next-icon-width: 12px;
$slider-arrow-next-icon-height: 17px;
$slider-arrow-next-icon-total-width: 256px;
$slider-arrow-next-icon-total-height: 216px;
$slider-arrow-next-icon-image: '../img/sprite/sprite.png';
$slider-arrow-next-icon: (187px, 139px, -187px, -139px, 12px, 17px, 256px, 216px, '../img/sprite/sprite.png', 'slider-arrow-next_icon', );
$slider-arrow-prev-icon-name: 'slider-arrow-prev_icon';
$slider-arrow-prev-icon-x: 50px;
$slider-arrow-prev-icon-y: 197px;
$slider-arrow-prev-icon-offset-x: -50px;
$slider-arrow-prev-icon-offset-y: -197px;
$slider-arrow-prev-icon-width: 12px;
$slider-arrow-prev-icon-height: 17px;
$slider-arrow-prev-icon-total-width: 256px;
$slider-arrow-prev-icon-total-height: 216px;
$slider-arrow-prev-icon-image: '../img/sprite/sprite.png';
$slider-arrow-prev-icon: (50px, 197px, -50px, -197px, 12px, 17px, 256px, 216px, '../img/sprite/sprite.png', 'slider-arrow-prev_icon', );
$tag-icon-name: 'tag_icon';
$tag-icon-x: 187px;
$tag-icon-y: 75px;
$tag-icon-offset-x: -187px;
$tag-icon-offset-y: -75px;
$tag-icon-width: 17px;
$tag-icon-height: 24px;
$tag-icon-total-width: 256px;
$tag-icon-total-height: 216px;
$tag-icon-image: '../img/sprite/sprite.png';
$tag-icon: (187px, 75px, -187px, -75px, 17px, 24px, 256px, 216px, '../img/sprite/sprite.png', 'tag_icon', );
$visa-default-icon-name: 'visa-default_icon';
$visa-default-icon-x: 121px;
$visa-default-icon-y: 86px;
$visa-default-icon-offset-x: -121px;
$visa-default-icon-offset-y: -86px;
$visa-default-icon-width: 28px;
$visa-default-icon-height: 20px;
$visa-default-icon-total-width: 256px;
$visa-default-icon-total-height: 216px;
$visa-default-icon-image: '../img/sprite/sprite.png';
$visa-default-icon: (121px, 86px, -121px, -86px, 28px, 20px, 256px, 216px, '../img/sprite/sprite.png', 'visa-default_icon', );
$visa-icon-name: 'visa_icon';
$visa-icon-x: 67px;
$visa-icon-y: 0px;
$visa-icon-offset-x: -67px;
$visa-icon-offset-y: 0px;
$visa-icon-width: 50px;
$visa-icon-height: 42px;
$visa-icon-total-width: 256px;
$visa-icon-total-height: 216px;
$visa-icon-image: '../img/sprite/sprite.png';
$visa-icon: (67px, 0px, -67px, 0px, 50px, 42px, 256px, 216px, '../img/sprite/sprite.png', 'visa_icon', );
$wallet-default-icon-name: 'wallet-default_icon';
$wallet-default-icon-x: 38px;
$wallet-default-icon-y: 136px;
$wallet-default-icon-offset-x: -38px;
$wallet-default-icon-offset-y: -136px;
$wallet-default-icon-width: 24px;
$wallet-default-icon-height: 20px;
$wallet-default-icon-total-width: 256px;
$wallet-default-icon-total-height: 216px;
$wallet-default-icon-image: '../img/sprite/sprite.png';
$wallet-default-icon: (38px, 136px, -38px, -136px, 24px, 20px, 256px, 216px, '../img/sprite/sprite.png', 'wallet-default_icon', );
$wallet-icon-name: 'wallet_icon';
$wallet-icon-x: 0px;
$wallet-icon-y: 86px;
$wallet-icon-offset-x: 0px;
$wallet-icon-offset-y: -86px;
$wallet-icon-width: 43px;
$wallet-icon-height: 40px;
$wallet-icon-total-width: 256px;
$wallet-icon-total-height: 216px;
$wallet-icon-image: '../img/sprite/sprite.png';
$wallet-icon: (0px, 86px, 0px, -86px, 43px, 40px, 256px, 216px, '../img/sprite/sprite.png', 'wallet_icon', );
$arrow-2down-icon-2x-name: 'arrow-2down_icon-2x';
$arrow-2down-icon-2x-x: 488px;
$arrow-2down-icon-2x-y: 0px;
$arrow-2down-icon-2x-offset-x: -488px;
$arrow-2down-icon-2x-offset-y: 0px;
$arrow-2down-icon-2x-width: 24px;
$arrow-2down-icon-2x-height: 24px;
$arrow-2down-icon-2x-total-width: 512px;
$arrow-2down-icon-2x-total-height: 432px;
$arrow-2down-icon-2x-image: '../img/sprite/sprite-2x.png';
$arrow-2down-icon-2x: (488px, 0px, -488px, 0px, 24px, 24px, 512px, 432px, '../img/sprite/sprite-2x.png', 'arrow-2down_icon-2x', );
$arrow-double-down-icon-2x-name: 'arrow-double-down_icon-2x';
$arrow-double-down-icon-2x-x: 132px;
$arrow-double-down-icon-2x-y: 332px;
$arrow-double-down-icon-2x-offset-x: -132px;
$arrow-double-down-icon-2x-offset-y: -332px;
$arrow-double-down-icon-2x-width: 48px;
$arrow-double-down-icon-2x-height: 32px;
$arrow-double-down-icon-2x-total-width: 512px;
$arrow-double-down-icon-2x-total-height: 432px;
$arrow-double-down-icon-2x-image: '../img/sprite/sprite-2x.png';
$arrow-double-down-icon-2x: (132px, 332px, -132px, -332px, 48px, 32px, 512px, 432px, '../img/sprite/sprite-2x.png', 'arrow-double-down_icon-2x', );
$arrow-double-up-icon-2x-name: 'arrow-double-up_icon-2x';
$arrow-double-up-icon-2x-x: 200px;
$arrow-double-up-icon-2x-y: 332px;
$arrow-double-up-icon-2x-offset-x: -200px;
$arrow-double-up-icon-2x-offset-y: -332px;
$arrow-double-up-icon-2x-width: 48px;
$arrow-double-up-icon-2x-height: 32px;
$arrow-double-up-icon-2x-total-width: 512px;
$arrow-double-up-icon-2x-total-height: 432px;
$arrow-double-up-icon-2x-image: '../img/sprite/sprite-2x.png';
$arrow-double-up-icon-2x: (200px, 332px, -200px, -332px, 48px, 32px, 512px, 432px, '../img/sprite/sprite-2x.png', 'arrow-double-up_icon-2x', );
$basket-white-icon-2x-name: 'basket-white_icon-2x';
$basket-white-icon-2x-x: 204px;
$basket-white-icon-2x-y: 104px;
$basket-white-icon-2x-offset-x: -204px;
$basket-white-icon-2x-offset-y: -104px;
$basket-white-icon-2x-width: 30px;
$basket-white-icon-2x-height: 38px;
$basket-white-icon-2x-total-width: 512px;
$basket-white-icon-2x-total-height: 432px;
$basket-white-icon-2x-image: '../img/sprite/sprite-2x.png';
$basket-white-icon-2x: (204px, 104px, -204px, -104px, 30px, 38px, 512px, 432px, '../img/sprite/sprite-2x.png', 'basket-white_icon-2x', );
$basket-icon-2x-name: 'basket_icon-2x';
$basket-icon-2x-x: 0px;
$basket-icon-2x-y: 394px;
$basket-icon-2x-offset-x: 0px;
$basket-icon-2x-offset-y: -394px;
$basket-icon-2x-width: 30px;
$basket-icon-2x-height: 38px;
$basket-icon-2x-total-width: 512px;
$basket-icon-2x-total-height: 432px;
$basket-icon-2x-image: '../img/sprite/sprite-2x.png';
$basket-icon-2x: (0px, 394px, 0px, -394px, 30px, 38px, 512px, 432px, '../img/sprite/sprite-2x.png', 'basket_icon-2x', );
$building-tab-icon-2x-name: 'building-tab_icon-2x';
$building-tab-icon-2x-x: 428px;
$building-tab-icon-2x-y: 0px;
$building-tab-icon-2x-offset-x: -428px;
$building-tab-icon-2x-offset-y: 0px;
$building-tab-icon-2x-width: 40px;
$building-tab-icon-2x-height: 40px;
$building-tab-icon-2x-total-width: 512px;
$building-tab-icon-2x-total-height: 432px;
$building-tab-icon-2x-image: '../img/sprite/sprite-2x.png';
$building-tab-icon-2x: (428px, 0px, -428px, 0px, 40px, 40px, 512px, 432px, '../img/sprite/sprite-2x.png', 'building-tab_icon-2x', );
$cart-red-icon-2x-name: 'cart-red_icon-2x';
$cart-red-icon-2x-x: 254px;
$cart-red-icon-2x-y: 104px;
$cart-red-icon-2x-offset-x: -254px;
$cart-red-icon-2x-offset-y: -104px;
$cart-red-icon-2x-width: 58px;
$cart-red-icon-2x-height: 46px;
$cart-red-icon-2x-total-width: 512px;
$cart-red-icon-2x-total-height: 432px;
$cart-red-icon-2x-image: '../img/sprite/sprite-2x.png';
$cart-red-icon-2x: (254px, 104px, -254px, -104px, 58px, 46px, 512px, 432px, '../img/sprite/sprite-2x.png', 'cart-red_icon-2x', );
$cart-white-icon-2x-name: 'cart-white_icon-2x';
$cart-white-icon-2x-x: 212px;
$cart-white-icon-2x-y: 272px;
$cart-white-icon-2x-offset-x: -212px;
$cart-white-icon-2x-offset-y: -272px;
$cart-white-icon-2x-width: 48px;
$cart-white-icon-2x-height: 40px;
$cart-white-icon-2x-total-width: 512px;
$cart-white-icon-2x-total-height: 432px;
$cart-white-icon-2x-image: '../img/sprite/sprite-2x.png';
$cart-white-icon-2x: (212px, 272px, -212px, -272px, 48px, 40px, 512px, 432px, '../img/sprite/sprite-2x.png', 'cart-white_icon-2x', );
$cart-icon-2x-name: 'cart_icon-2x';
$cart-icon-2x-x: 144px;
$cart-icon-2x-y: 272px;
$cart-icon-2x-offset-x: -144px;
$cart-icon-2x-offset-y: -272px;
$cart-icon-2x-width: 48px;
$cart-icon-2x-height: 40px;
$cart-icon-2x-total-width: 512px;
$cart-icon-2x-total-height: 432px;
$cart-icon-2x-image: '../img/sprite/sprite-2x.png';
$cart-icon-2x: (144px, 272px, -144px, -272px, 48px, 40px, 512px, 432px, '../img/sprite/sprite-2x.png', 'cart_icon-2x', );
$checkbox-check-icon-2x-name: 'checkbox-check_icon-2x';
$checkbox-check-icon-2x-x: 134px;
$checkbox-check-icon-2x-y: 104px;
$checkbox-check-icon-2x-offset-x: -134px;
$checkbox-check-icon-2x-offset-y: -104px;
$checkbox-check-icon-2x-width: 50px;
$checkbox-check-icon-2x-height: 48px;
$checkbox-check-icon-2x-total-width: 512px;
$checkbox-check-icon-2x-total-height: 432px;
$checkbox-check-icon-2x-image: '../img/sprite/sprite-2x.png';
$checkbox-check-icon-2x: (134px, 104px, -134px, -104px, 50px, 48px, 512px, 432px, '../img/sprite/sprite-2x.png', 'checkbox-check_icon-2x', );
$checkbox-icon-2x-name: 'checkbox_icon-2x';
$checkbox-icon-2x-x: 106px;
$checkbox-icon-2x-y: 172px;
$checkbox-icon-2x-offset-x: -106px;
$checkbox-icon-2x-offset-y: -172px;
$checkbox-icon-2x-width: 48px;
$checkbox-icon-2x-height: 48px;
$checkbox-icon-2x-total-width: 512px;
$checkbox-icon-2x-total-height: 432px;
$checkbox-icon-2x-image: '../img/sprite/sprite-2x.png';
$checkbox-icon-2x: (106px, 172px, -106px, -172px, 48px, 48px, 512px, 432px, '../img/sprite/sprite-2x.png', 'checkbox_icon-2x', );
$cityscape-tab-icon-2x-name: 'cityscape-tab_icon-2x';
$cityscape-tab-icon-2x-x: 428px;
$cityscape-tab-icon-2x-y: 118px;
$cityscape-tab-icon-2x-offset-x: -428px;
$cityscape-tab-icon-2x-offset-y: -118px;
$cityscape-tab-icon-2x-width: 38px;
$cityscape-tab-icon-2x-height: 38px;
$cityscape-tab-icon-2x-total-width: 512px;
$cityscape-tab-icon-2x-total-height: 432px;
$cityscape-tab-icon-2x-image: '../img/sprite/sprite-2x.png';
$cityscape-tab-icon-2x: (428px, 118px, -428px, -118px, 38px, 38px, 512px, 432px, '../img/sprite/sprite-2x.png', 'cityscape-tab_icon-2x', );
$click-gesture-icon-2x-name: 'click-gesture_icon-2x';
$click-gesture-icon-2x-x: 374px;
$click-gesture-icon-2x-y: 74px;
$click-gesture-icon-2x-offset-x: -374px;
$click-gesture-icon-2x-offset-y: -74px;
$click-gesture-icon-2x-width: 32px;
$click-gesture-icon-2x-height: 56px;
$click-gesture-icon-2x-total-width: 512px;
$click-gesture-icon-2x-total-height: 432px;
$click-gesture-icon-2x-image: '../img/sprite/sprite-2x.png';
$click-gesture-icon-2x: (374px, 74px, -374px, -74px, 32px, 56px, 512px, 432px, '../img/sprite/sprite-2x.png', 'click-gesture_icon-2x', );
$doc-tab-icon-2x-name: 'doc-tab_icon-2x';
$doc-tab-icon-2x-x: 50px;
$doc-tab-icon-2x-y: 394px;
$doc-tab-icon-2x-offset-x: -50px;
$doc-tab-icon-2x-offset-y: -394px;
$doc-tab-icon-2x-width: 30px;
$doc-tab-icon-2x-height: 38px;
$doc-tab-icon-2x-total-width: 512px;
$doc-tab-icon-2x-total-height: 432px;
$doc-tab-icon-2x-image: '../img/sprite/sprite-2x.png';
$doc-tab-icon-2x: (50px, 394px, -50px, -394px, 30px, 38px, 512px, 432px, '../img/sprite/sprite-2x.png', 'doc-tab_icon-2x', );
$down-white-icon-2x-name: 'down-white_icon-2x';
$down-white-icon-2x-x: 338px;
$down-white-icon-2x-y: 332px;
$down-white-icon-2x-offset-x: -338px;
$down-white-icon-2x-offset-y: -332px;
$down-white-icon-2x-width: 32px;
$down-white-icon-2x-height: 18px;
$down-white-icon-2x-total-width: 512px;
$down-white-icon-2x-total-height: 432px;
$down-white-icon-2x-image: '../img/sprite/sprite-2x.png';
$down-white-icon-2x: (338px, 332px, -338px, -332px, 32px, 18px, 512px, 432px, '../img/sprite/sprite-2x.png', 'down-white_icon-2x', );
$down-icon-2x-name: 'down_icon-2x';
$down-icon-2x-x: 144px;
$down-icon-2x-y: 394px;
$down-icon-2x-offset-x: -144px;
$down-icon-2x-offset-y: -394px;
$down-icon-2x-width: 32px;
$down-icon-2x-height: 18px;
$down-icon-2x-total-width: 512px;
$down-icon-2x-total-height: 432px;
$down-icon-2x-image: '../img/sprite/sprite-2x.png';
$down-icon-2x: (144px, 394px, -144px, -394px, 32px, 18px, 512px, 432px, '../img/sprite/sprite-2x.png', 'down_icon-2x', );
$enter-arrow-icon-2x-name: 'enter-arrow_icon-2x';
$enter-arrow-icon-2x-x: 62px;
$enter-arrow-icon-2x-y: 332px;
$enter-arrow-icon-2x-offset-x: -62px;
$enter-arrow-icon-2x-offset-y: -332px;
$enter-arrow-icon-2x-width: 50px;
$enter-arrow-icon-2x-height: 34px;
$enter-arrow-icon-2x-total-width: 512px;
$enter-arrow-icon-2x-total-height: 432px;
$enter-arrow-icon-2x-image: '../img/sprite/sprite-2x.png';
$enter-arrow-icon-2x: (62px, 332px, -62px, -332px, 50px, 34px, 512px, 432px, '../img/sprite/sprite-2x.png', 'enter-arrow_icon-2x', );
$eye-icon-2x-name: 'eye__icon-2x';
$eye-icon-2x-x: 268px;
$eye-icon-2x-y: 332px;
$eye-icon-2x-offset-x: -268px;
$eye-icon-2x-offset-y: -332px;
$eye-icon-2x-width: 50px;
$eye-icon-2x-height: 30px;
$eye-icon-2x-total-width: 512px;
$eye-icon-2x-total-height: 432px;
$eye-icon-2x-image: '../img/sprite/sprite-2x.png';
$eye-icon-2x: (268px, 332px, -268px, -332px, 50px, 30px, 512px, 432px, '../img/sprite/sprite-2x.png', 'eye__icon-2x', );
$fail-tab-icon-2x-name: 'fail-tab_icon-2x';
$fail-tab-icon-2x-x: 318px;
$fail-tab-icon-2x-y: 172px;
$fail-tab-icon-2x-offset-x: -318px;
$fail-tab-icon-2x-offset-y: -172px;
$fail-tab-icon-2x-width: 30px;
$fail-tab-icon-2x-height: 38px;
$fail-tab-icon-2x-total-width: 512px;
$fail-tab-icon-2x-total-height: 432px;
$fail-tab-icon-2x-image: '../img/sprite/sprite-2x.png';
$fail-tab-icon-2x: (318px, 172px, -318px, -172px, 30px, 38px, 512px, 432px, '../img/sprite/sprite-2x.png', 'fail-tab_icon-2x', );
$loupe-red-icon-2x-name: 'loupe-red_icon-2x';
$loupe-red-icon-2x-x: 428px;
$loupe-red-icon-2x-y: 176px;
$loupe-red-icon-2x-offset-x: -428px;
$loupe-red-icon-2x-offset-y: -176px;
$loupe-red-icon-2x-width: 36px;
$loupe-red-icon-2x-height: 36px;
$loupe-red-icon-2x-total-width: 512px;
$loupe-red-icon-2x-total-height: 432px;
$loupe-red-icon-2x-image: '../img/sprite/sprite-2x.png';
$loupe-red-icon-2x: (428px, 176px, -428px, -176px, 36px, 36px, 512px, 432px, '../img/sprite/sprite-2x.png', 'loupe-red_icon-2x', );
$loupe-white-icon-2x-name: 'loupe-white_icon-2x';
$loupe-white-icon-2x-x: 428px;
$loupe-white-icon-2x-y: 232px;
$loupe-white-icon-2x-offset-x: -428px;
$loupe-white-icon-2x-offset-y: -232px;
$loupe-white-icon-2x-width: 36px;
$loupe-white-icon-2x-height: 36px;
$loupe-white-icon-2x-total-width: 512px;
$loupe-white-icon-2x-total-height: 432px;
$loupe-white-icon-2x-image: '../img/sprite/sprite-2x.png';
$loupe-white-icon-2x: (428px, 232px, -428px, -232px, 36px, 36px, 512px, 432px, '../img/sprite/sprite-2x.png', 'loupe-white_icon-2x', );
$loupe-icon-2x-name: 'loupe_icon-2x';
$loupe-icon-2x-x: 428px;
$loupe-icon-2x-y: 288px;
$loupe-icon-2x-offset-x: -428px;
$loupe-icon-2x-offset-y: -288px;
$loupe-icon-2x-width: 36px;
$loupe-icon-2x-height: 36px;
$loupe-icon-2x-total-width: 512px;
$loupe-icon-2x-total-height: 432px;
$loupe-icon-2x-image: '../img/sprite/sprite-2x.png';
$loupe-icon-2x: (428px, 288px, -428px, -288px, 36px, 36px, 512px, 432px, '../img/sprite/sprite-2x.png', 'loupe_icon-2x', );
$mail-icon-2x-name: 'mail_icon-2x';
$mail-icon-2x-x: 280px;
$mail-icon-2x-y: 272px;
$mail-icon-2x-offset-x: -280px;
$mail-icon-2x-offset-y: -272px;
$mail-icon-2x-width: 60px;
$mail-icon-2x-height: 30px;
$mail-icon-2x-total-width: 512px;
$mail-icon-2x-total-height: 432px;
$mail-icon-2x-image: '../img/sprite/sprite-2x.png';
$mail-icon-2x: (280px, 272px, -280px, -272px, 60px, 30px, 512px, 432px, '../img/sprite/sprite-2x.png', 'mail_icon-2x', );
$marker-icon-2x-name: 'marker_icon-2x';
$marker-icon-2x-x: 374px;
$marker-icon-2x-y: 218px;
$marker-icon-2x-offset-x: -374px;
$marker-icon-2x-offset-y: -218px;
$marker-icon-2x-width: 30px;
$marker-icon-2x-height: 40px;
$marker-icon-2x-total-width: 512px;
$marker-icon-2x-total-height: 432px;
$marker-icon-2x-image: '../img/sprite/sprite-2x.png';
$marker-icon-2x: (374px, 218px, -374px, -218px, 30px, 40px, 512px, 432px, '../img/sprite/sprite-2x.png', 'marker_icon-2x', );
$mastercard-default-icon-2x-name: 'mastercard-default_icon-2x';
$mastercard-default-icon-2x-x: 0px;
$mastercard-default-icon-2x-y: 272px;
$mastercard-default-icon-2x-offset-x: 0px;
$mastercard-default-icon-2x-offset-y: -272px;
$mastercard-default-icon-2x-width: 56px;
$mastercard-default-icon-2x-height: 40px;
$mastercard-default-icon-2x-total-width: 512px;
$mastercard-default-icon-2x-total-height: 432px;
$mastercard-default-icon-2x-image: '../img/sprite/sprite-2x.png';
$mastercard-default-icon-2x: (0px, 272px, 0px, -272px, 56px, 40px, 512px, 432px, '../img/sprite/sprite-2x.png', 'mastercard-default_icon-2x', );
$mastercard-icon-2x-name: 'mastercard_icon-2x';
$mastercard-icon-2x-x: 254px;
$mastercard-icon-2x-y: 0px;
$mastercard-icon-2x-offset-x: -254px;
$mastercard-icon-2x-offset-y: 0px;
$mastercard-icon-2x-width: 100px;
$mastercard-icon-2x-height: 84px;
$mastercard-icon-2x-total-width: 512px;
$mastercard-icon-2x-total-height: 432px;
$mastercard-icon-2x-image: '../img/sprite/sprite-2x.png';
$mastercard-icon-2x: (254px, 0px, -254px, 0px, 100px, 84px, 512px, 432px, '../img/sprite/sprite-2x.png', 'mastercard_icon-2x', );
$operator-icon-2x-name: 'operator_icon-2x';
$operator-icon-2x-x: 0px;
$operator-icon-2x-y: 0px;
$operator-icon-2x-offset-x: 0px;
$operator-icon-2x-offset-y: 0px;
$operator-icon-2x-width: 114px;
$operator-icon-2x-height: 152px;
$operator-icon-2x-total-width: 512px;
$operator-icon-2x-total-height: 432px;
$operator-icon-2x-image: '../img/sprite/sprite-2x.png';
$operator-icon-2x: (0px, 0px, 0px, 0px, 114px, 152px, 512px, 432px, '../img/sprite/sprite-2x.png', 'operator_icon-2x', );
$original-icon-2x-name: 'original_icon-2x';
$original-icon-2x-x: 174px;
$original-icon-2x-y: 172px;
$original-icon-2x-offset-x: -174px;
$original-icon-2x-offset-y: -172px;
$original-icon-2x-width: 48px;
$original-icon-2x-height: 48px;
$original-icon-2x-total-width: 512px;
$original-icon-2x-total-height: 432px;
$original-icon-2x-image: '../img/sprite/sprite-2x.png';
$original-icon-2x: (174px, 172px, -174px, -172px, 48px, 48px, 512px, 432px, '../img/sprite/sprite-2x.png', 'original_icon-2x', );
$payment-method-icon-2x-name: 'payment-method_icon-2x';
$payment-method-icon-2x-x: 374px;
$payment-method-icon-2x-y: 0px;
$payment-method-icon-2x-offset-x: -374px;
$payment-method-icon-2x-offset-y: 0px;
$payment-method-icon-2x-width: 34px;
$payment-method-icon-2x-height: 54px;
$payment-method-icon-2x-total-width: 512px;
$payment-method-icon-2x-total-height: 432px;
$payment-method-icon-2x-image: '../img/sprite/sprite-2x.png';
$payment-method-icon-2x: (374px, 0px, -374px, 0px, 34px, 54px, 512px, 432px, '../img/sprite/sprite-2x.png', 'payment-method_icon-2x', );
$phone-call-icon-2x-name: 'phone-call_icon-2x';
$phone-call-icon-2x-x: 0px;
$phone-call-icon-2x-y: 332px;
$phone-call-icon-2x-offset-x: 0px;
$phone-call-icon-2x-offset-y: -332px;
$phone-call-icon-2x-width: 42px;
$phone-call-icon-2x-height: 42px;
$phone-call-icon-2x-total-width: 512px;
$phone-call-icon-2x-total-height: 432px;
$phone-call-icon-2x-image: '../img/sprite/sprite-2x.png';
$phone-call-icon-2x: (0px, 332px, 0px, -332px, 42px, 42px, 512px, 432px, '../img/sprite/sprite-2x.png', 'phone-call_icon-2x', );
$reviews-tab-icon-2x-name: 'reviews-tab_icon-2x';
$reviews-tab-icon-2x-x: 428px;
$reviews-tab-icon-2x-y: 344px;
$reviews-tab-icon-2x-offset-x: -428px;
$reviews-tab-icon-2x-offset-y: -344px;
$reviews-tab-icon-2x-width: 38px;
$reviews-tab-icon-2x-height: 26px;
$reviews-tab-icon-2x-total-width: 512px;
$reviews-tab-icon-2x-total-height: 432px;
$reviews-tab-icon-2x-image: '../img/sprite/sprite-2x.png';
$reviews-tab-icon-2x: (428px, 344px, -428px, -344px, 38px, 26px, 512px, 432px, '../img/sprite/sprite-2x.png', 'reviews-tab_icon-2x', );
$skyscraper-tab-icon-2x-name: 'skyscraper-tab_icon-2x';
$skyscraper-tab-icon-2x-x: 428px;
$skyscraper-tab-icon-2x-y: 60px;
$skyscraper-tab-icon-2x-offset-x: -428px;
$skyscraper-tab-icon-2x-offset-y: -60px;
$skyscraper-tab-icon-2x-width: 38px;
$skyscraper-tab-icon-2x-height: 38px;
$skyscraper-tab-icon-2x-total-width: 512px;
$skyscraper-tab-icon-2x-total-height: 432px;
$skyscraper-tab-icon-2x-image: '../img/sprite/sprite-2x.png';
$skyscraper-tab-icon-2x: (428px, 60px, -428px, -60px, 38px, 38px, 512px, 432px, '../img/sprite/sprite-2x.png', 'skyscraper-tab_icon-2x', );
$slider-arrow-next-icon-2x-name: 'slider-arrow-next_icon-2x';
$slider-arrow-next-icon-2x-x: 374px;
$slider-arrow-next-icon-2x-y: 278px;
$slider-arrow-next-icon-2x-offset-x: -374px;
$slider-arrow-next-icon-2x-offset-y: -278px;
$slider-arrow-next-icon-2x-width: 24px;
$slider-arrow-next-icon-2x-height: 34px;
$slider-arrow-next-icon-2x-total-width: 512px;
$slider-arrow-next-icon-2x-total-height: 432px;
$slider-arrow-next-icon-2x-image: '../img/sprite/sprite-2x.png';
$slider-arrow-next-icon-2x: (374px, 278px, -374px, -278px, 24px, 34px, 512px, 432px, '../img/sprite/sprite-2x.png', 'slider-arrow-next_icon-2x', );
$slider-arrow-prev-icon-2x-name: 'slider-arrow-prev_icon-2x';
$slider-arrow-prev-icon-2x-x: 100px;
$slider-arrow-prev-icon-2x-y: 394px;
$slider-arrow-prev-icon-2x-offset-x: -100px;
$slider-arrow-prev-icon-2x-offset-y: -394px;
$slider-arrow-prev-icon-2x-width: 24px;
$slider-arrow-prev-icon-2x-height: 34px;
$slider-arrow-prev-icon-2x-total-width: 512px;
$slider-arrow-prev-icon-2x-total-height: 432px;
$slider-arrow-prev-icon-2x-image: '../img/sprite/sprite-2x.png';
$slider-arrow-prev-icon-2x: (100px, 394px, -100px, -394px, 24px, 34px, 512px, 432px, '../img/sprite/sprite-2x.png', 'slider-arrow-prev_icon-2x', );
$tag-icon-2x-name: 'tag_icon-2x';
$tag-icon-2x-x: 374px;
$tag-icon-2x-y: 150px;
$tag-icon-2x-offset-x: -374px;
$tag-icon-2x-offset-y: -150px;
$tag-icon-2x-width: 34px;
$tag-icon-2x-height: 48px;
$tag-icon-2x-total-width: 512px;
$tag-icon-2x-total-height: 432px;
$tag-icon-2x-image: '../img/sprite/sprite-2x.png';
$tag-icon-2x: (374px, 150px, -374px, -150px, 34px, 48px, 512px, 432px, '../img/sprite/sprite-2x.png', 'tag_icon-2x', );
$visa-default-icon-2x-name: 'visa-default_icon-2x';
$visa-default-icon-2x-x: 242px;
$visa-default-icon-2x-y: 172px;
$visa-default-icon-2x-offset-x: -242px;
$visa-default-icon-2x-offset-y: -172px;
$visa-default-icon-2x-width: 56px;
$visa-default-icon-2x-height: 40px;
$visa-default-icon-2x-total-width: 512px;
$visa-default-icon-2x-total-height: 432px;
$visa-default-icon-2x-image: '../img/sprite/sprite-2x.png';
$visa-default-icon-2x: (242px, 172px, -242px, -172px, 56px, 40px, 512px, 432px, '../img/sprite/sprite-2x.png', 'visa-default_icon-2x', );
$visa-icon-2x-name: 'visa_icon-2x';
$visa-icon-2x-x: 134px;
$visa-icon-2x-y: 0px;
$visa-icon-2x-offset-x: -134px;
$visa-icon-2x-offset-y: 0px;
$visa-icon-2x-width: 100px;
$visa-icon-2x-height: 84px;
$visa-icon-2x-total-width: 512px;
$visa-icon-2x-total-height: 432px;
$visa-icon-2x-image: '../img/sprite/sprite-2x.png';
$visa-icon-2x: (134px, 0px, -134px, 0px, 100px, 84px, 512px, 432px, '../img/sprite/sprite-2x.png', 'visa_icon-2x', );
$wallet-default-icon-2x-name: 'wallet-default_icon-2x';
$wallet-default-icon-2x-x: 76px;
$wallet-default-icon-2x-y: 272px;
$wallet-default-icon-2x-offset-x: -76px;
$wallet-default-icon-2x-offset-y: -272px;
$wallet-default-icon-2x-width: 48px;
$wallet-default-icon-2x-height: 40px;
$wallet-default-icon-2x-total-width: 512px;
$wallet-default-icon-2x-total-height: 432px;
$wallet-default-icon-2x-image: '../img/sprite/sprite-2x.png';
$wallet-default-icon-2x: (76px, 272px, -76px, -272px, 48px, 40px, 512px, 432px, '../img/sprite/sprite-2x.png', 'wallet-default_icon-2x', );
$wallet-icon-2x-name: 'wallet_icon-2x';
$wallet-icon-2x-x: 0px;
$wallet-icon-2x-y: 172px;
$wallet-icon-2x-offset-x: 0px;
$wallet-icon-2x-offset-y: -172px;
$wallet-icon-2x-width: 86px;
$wallet-icon-2x-height: 80px;
$wallet-icon-2x-total-width: 512px;
$wallet-icon-2x-total-height: 432px;
$wallet-icon-2x-image: '../img/sprite/sprite-2x.png';
$wallet-icon-2x: (0px, 172px, 0px, -172px, 86px, 80px, 512px, 432px, '../img/sprite/sprite-2x.png', 'wallet_icon-2x', );
$spritesheet-width: 256px;
$spritesheet-height: 216px;
$spritesheet-image: '../img/sprite/sprite.png';
$spritesheet-sprites: ($arrow-2down-icon, $arrow-double-down-icon, $arrow-double-up-icon, $basket-white-icon, $basket-icon, $building-tab-icon, $cart-red-icon, $cart-white-icon, $cart-icon, $checkbox-check-icon, $checkbox-icon, $cityscape-tab-icon, $click-gesture-icon, $doc-tab-icon, $down-white-icon, $down-icon, $enter-arrow-icon, $eye-icon, $fail-tab-icon, $loupe-red-icon, $loupe-white-icon, $loupe-icon, $mail-icon, $marker-icon, $mastercard-default-icon, $mastercard-icon, $operator-icon, $original-icon, $payment-method-icon, $phone-call-icon, $reviews-tab-icon, $skyscraper-tab-icon, $slider-arrow-next-icon, $slider-arrow-prev-icon, $tag-icon, $visa-default-icon, $visa-icon, $wallet-default-icon, $wallet-icon, );
$spritesheet: (256px, 216px, '../img/sprite/sprite.png', $spritesheet-sprites, );
$retina-spritesheet-width: 512px;
$retina-spritesheet-height: 432px;
$retina-spritesheet-image: '../img/sprite/sprite-2x.png';
$retina-spritesheet-sprites: ($arrow-2down-icon-2x, $arrow-double-down-icon-2x, $arrow-double-up-icon-2x, $basket-white-icon-2x, $basket-icon-2x, $building-tab-icon-2x, $cart-red-icon-2x, $cart-white-icon-2x, $cart-icon-2x, $checkbox-check-icon-2x, $checkbox-icon-2x, $cityscape-tab-icon-2x, $click-gesture-icon-2x, $doc-tab-icon-2x, $down-white-icon-2x, $down-icon-2x, $enter-arrow-icon-2x, $eye-icon-2x, $fail-tab-icon-2x, $loupe-red-icon-2x, $loupe-white-icon-2x, $loupe-icon-2x, $mail-icon-2x, $marker-icon-2x, $mastercard-default-icon-2x, $mastercard-icon-2x, $operator-icon-2x, $original-icon-2x, $payment-method-icon-2x, $phone-call-icon-2x, $reviews-tab-icon-2x, $skyscraper-tab-icon-2x, $slider-arrow-next-icon-2x, $slider-arrow-prev-icon-2x, $tag-icon-2x, $visa-default-icon-2x, $visa-icon-2x, $wallet-default-icon-2x, $wallet-icon-2x, );
$retina-spritesheet: (512px, 432px, '../img/sprite/sprite-2x.png', $retina-spritesheet-sprites, );

/*
These "retina group" variables are mappings for the naming and pairing of normal and retina sprites.

The list formatted variables are intended for mixins like `retina-sprite` and `retina-sprites`.
*/
$arrow-2down-icon-group-name: 'arrow-2down_icon';
$arrow-2down-icon-group: ('arrow-2down_icon', $arrow-2down-icon, $arrow-2down-icon-2x, );
$arrow-double-down-icon-group-name: 'arrow-double-down_icon';
$arrow-double-down-icon-group: ('arrow-double-down_icon', $arrow-double-down-icon, $arrow-double-down-icon-2x, );
$arrow-double-up-icon-group-name: 'arrow-double-up_icon';
$arrow-double-up-icon-group: ('arrow-double-up_icon', $arrow-double-up-icon, $arrow-double-up-icon-2x, );
$basket-white-icon-group-name: 'basket-white_icon';
$basket-white-icon-group: ('basket-white_icon', $basket-white-icon, $basket-white-icon-2x, );
$basket-icon-group-name: 'basket_icon';
$basket-icon-group: ('basket_icon', $basket-icon, $basket-icon-2x, );
$building-tab-icon-group-name: 'building-tab_icon';
$building-tab-icon-group: ('building-tab_icon', $building-tab-icon, $building-tab-icon-2x, );
$cart-red-icon-group-name: 'cart-red_icon';
$cart-red-icon-group: ('cart-red_icon', $cart-red-icon, $cart-red-icon-2x, );
$cart-white-icon-group-name: 'cart-white_icon';
$cart-white-icon-group: ('cart-white_icon', $cart-white-icon, $cart-white-icon-2x, );
$cart-icon-group-name: 'cart_icon';
$cart-icon-group: ('cart_icon', $cart-icon, $cart-icon-2x, );
$checkbox-check-icon-group-name: 'checkbox-check_icon';
$checkbox-check-icon-group: ('checkbox-check_icon', $checkbox-check-icon, $checkbox-check-icon-2x, );
$checkbox-icon-group-name: 'checkbox_icon';
$checkbox-icon-group: ('checkbox_icon', $checkbox-icon, $checkbox-icon-2x, );
$cityscape-tab-icon-group-name: 'cityscape-tab_icon';
$cityscape-tab-icon-group: ('cityscape-tab_icon', $cityscape-tab-icon, $cityscape-tab-icon-2x, );
$click-gesture-icon-group-name: 'click-gesture_icon';
$click-gesture-icon-group: ('click-gesture_icon', $click-gesture-icon, $click-gesture-icon-2x, );
$doc-tab-icon-group-name: 'doc-tab_icon';
$doc-tab-icon-group: ('doc-tab_icon', $doc-tab-icon, $doc-tab-icon-2x, );
$down-white-icon-group-name: 'down-white_icon';
$down-white-icon-group: ('down-white_icon', $down-white-icon, $down-white-icon-2x, );
$down-icon-group-name: 'down_icon';
$down-icon-group: ('down_icon', $down-icon, $down-icon-2x, );
$enter-arrow-icon-group-name: 'enter-arrow_icon';
$enter-arrow-icon-group: ('enter-arrow_icon', $enter-arrow-icon, $enter-arrow-icon-2x, );
$eye-icon-group-name: 'eye__icon';
$eye-icon-group: ('eye__icon', $eye-icon, $eye-icon-2x, );
$fail-tab-icon-group-name: 'fail-tab_icon';
$fail-tab-icon-group: ('fail-tab_icon', $fail-tab-icon, $fail-tab-icon-2x, );
$loupe-red-icon-group-name: 'loupe-red_icon';
$loupe-red-icon-group: ('loupe-red_icon', $loupe-red-icon, $loupe-red-icon-2x, );
$loupe-white-icon-group-name: 'loupe-white_icon';
$loupe-white-icon-group: ('loupe-white_icon', $loupe-white-icon, $loupe-white-icon-2x, );
$loupe-icon-group-name: 'loupe_icon';
$loupe-icon-group: ('loupe_icon', $loupe-icon, $loupe-icon-2x, );
$mail-icon-group-name: 'mail_icon';
$mail-icon-group: ('mail_icon', $mail-icon, $mail-icon-2x, );
$marker-icon-group-name: 'marker_icon';
$marker-icon-group: ('marker_icon', $marker-icon, $marker-icon-2x, );
$mastercard-default-icon-group-name: 'mastercard-default_icon';
$mastercard-default-icon-group: ('mastercard-default_icon', $mastercard-default-icon, $mastercard-default-icon-2x, );
$mastercard-icon-group-name: 'mastercard_icon';
$mastercard-icon-group: ('mastercard_icon', $mastercard-icon, $mastercard-icon-2x, );
$operator-icon-group-name: 'operator_icon';
$operator-icon-group: ('operator_icon', $operator-icon, $operator-icon-2x, );
$original-icon-group-name: 'original_icon';
$original-icon-group: ('original_icon', $original-icon, $original-icon-2x, );
$payment-method-icon-group-name: 'payment-method_icon';
$payment-method-icon-group: ('payment-method_icon', $payment-method-icon, $payment-method-icon-2x, );
$phone-call-icon-group-name: 'phone-call_icon';
$phone-call-icon-group: ('phone-call_icon', $phone-call-icon, $phone-call-icon-2x, );
$reviews-tab-icon-group-name: 'reviews-tab_icon';
$reviews-tab-icon-group: ('reviews-tab_icon', $reviews-tab-icon, $reviews-tab-icon-2x, );
$skyscraper-tab-icon-group-name: 'skyscraper-tab_icon';
$skyscraper-tab-icon-group: ('skyscraper-tab_icon', $skyscraper-tab-icon, $skyscraper-tab-icon-2x, );
$slider-arrow-next-icon-group-name: 'slider-arrow-next_icon';
$slider-arrow-next-icon-group: ('slider-arrow-next_icon', $slider-arrow-next-icon, $slider-arrow-next-icon-2x, );
$slider-arrow-prev-icon-group-name: 'slider-arrow-prev_icon';
$slider-arrow-prev-icon-group: ('slider-arrow-prev_icon', $slider-arrow-prev-icon, $slider-arrow-prev-icon-2x, );
$tag-icon-group-name: 'tag_icon';
$tag-icon-group: ('tag_icon', $tag-icon, $tag-icon-2x, );
$visa-default-icon-group-name: 'visa-default_icon';
$visa-default-icon-group: ('visa-default_icon', $visa-default-icon, $visa-default-icon-2x, );
$visa-icon-group-name: 'visa_icon';
$visa-icon-group: ('visa_icon', $visa-icon, $visa-icon-2x, );
$wallet-default-icon-group-name: 'wallet-default_icon';
$wallet-default-icon-group: ('wallet-default_icon', $wallet-default-icon, $wallet-default-icon-2x, );
$wallet-icon-group-name: 'wallet_icon';
$wallet-icon-group: ('wallet_icon', $wallet-icon, $wallet-icon-2x, );
$retina-groups: ($arrow-2down-icon-group, $arrow-double-down-icon-group, $arrow-double-up-icon-group, $basket-white-icon-group, $basket-icon-group, $building-tab-icon-group, $cart-red-icon-group, $cart-white-icon-group, $cart-icon-group, $checkbox-check-icon-group, $checkbox-icon-group, $cityscape-tab-icon-group, $click-gesture-icon-group, $doc-tab-icon-group, $down-white-icon-group, $down-icon-group, $enter-arrow-icon-group, $eye-icon-group, $fail-tab-icon-group, $loupe-red-icon-group, $loupe-white-icon-group, $loupe-icon-group, $mail-icon-group, $marker-icon-group, $mastercard-default-icon-group, $mastercard-icon-group, $operator-icon-group, $original-icon-group, $payment-method-icon-group, $phone-call-icon-group, $reviews-tab-icon-group, $skyscraper-tab-icon-group, $slider-arrow-next-icon-group, $slider-arrow-prev-icon-group, $tag-icon-group, $visa-default-icon-group, $visa-icon-group, $wallet-default-icon-group, $wallet-icon-group, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}

Here are example usages in HTML:

`display: block` sprite:
<div class="icon-home"></div>

`display: inline-block` sprite:
<img class="icon-home" />
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `retina-sprite` mixin sets up rules and a media query for a sprite/retina sprite.
  It should be used with a "retina group" variable.

The media query is from CSS Tricks: https://css-tricks.com/snippets/css/retina-display-media-query/

$icon-home-group: ('icon-home', $icon-home, $icon-home-2x, );

.icon-home {
  @include retina-sprite($icon-home-group);
}
*/
@mixin sprite-background-size($sprite) {
  $sprite-total-width: nth($sprite, 7);
  $sprite-total-height: nth($sprite, 8);
  background-size: $sprite-total-width $sprite-total-height;
}

@mixin retina-sprite($retina-group) {
  $normal-sprite: nth($retina-group, 2);
  $retina-sprite: nth($retina-group, 3);
  @include sprite($normal-sprite);

  @media (-webkit-min-device-pixel-ratio: 2),
         (min-resolution: 192dpi) {
    @include sprite-image($retina-sprite);
    @include sprite-background-size($normal-sprite);
  }
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}

/*
The `retina-sprites` mixin generates a CSS rule and media query for retina groups
  This yields the same output as CSS retina template but can be overridden in SCSS

@include retina-sprites($retina-groups);
*/
@mixin retina-sprites($retina-groups) {
  @each $retina-group in $retina-groups {
    $sprite-name: nth($retina-group, 1);
    .#{$sprite-name} {
      @include retina-sprite($retina-group);
    }
  }
}
