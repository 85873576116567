@import "../libs/bourbon/app/assets/stylesheets/bourbon";

@import "common/vars";
@import "common/mixins";

html {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
  //font-family: $default-fonts;
}

.hidden {
  display: none;
}


img {
  height: auto;
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}


body{
  font-size: 14px;
  font-family: $default-fonts;
  font-weight: 300;
  position: relative;
  background-color: #eff0f2;
  // -ms-overflow-x: hidden;
}
.wrapper{
  overflow-x: hidden;
  @media only screen and #{$d-mobile} {
    overflow: hidden;
  }
}

.title{
  color: $accent-color;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.68;
  text-transform: uppercase;
  margin-bottom: 25px;
  &--center{text-align: center;}
  @media only screen and #{$d-mobile-hzl} {margin-left: 8px;}
  @media only screen and #{$d-tablet} {
    text-align: center;
    margin-left: 0;
  }
  @media only screen and #{$d-desktop} {
    text-align: left;
    margin-left: 2px;
  }
}

a{
  transition: all .3s ease-out;
  &:hover,
  &:focus{outline: 0;}
  &:active{opacity: .7;}
}

input[type=search],input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

/* -------------- footer bottom --------------*/
 html {
    height: 100%;
  }
  body {
    min-height: 100%;
  }

// body {
//   display: flex;
//   flex-direction: column;
//   min-height: 100%;
//   height: 100vh; 
// }

.wrapper{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%; 
}

.l-header,
.l-footer {
  flex: 0 0 auto;
  flex-shrink: 0;
}

.l-main {
  flex: 1 0 auto;
}



