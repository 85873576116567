$default-fonts: 'Neris', 'Museo Sans', sans-serif, -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue;
$accent-fonts:  'Museo Sans', 'Neris', sans-serif, -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue;
$main-color: #2f73fc;
$accent-color: #222;


$desktop: "(min-width: 1199px)";
$desktop-small: "(min-width: 1140px)";
$laptops: "(min-width: 1025px)";
$tablet-hzl: "(min-width: 961px)";
$mobile-hzl: "(min-width: 769px)";
$tablet: "(min-width: 461px)";
$mobile: "(max-width: 460px)";
$retina: "(min-resolution: 144dpi), (min-resolution: 1.5dppx)";

//$d-desktop: "(min-width: 1300px)";
$d-desktop-small: "(min-width: 1200px)";
$d-laptop-max: "(max-width: 1199px)";



// new variables
$d-desktop: "(min-width: 1200px)";

$d-tablet-hzl-max: "(max-width: 1199px)";

$d-tablet-hzl: "(min-width: 1024px)";
$d-tablet: "(min-width: 768px)";
$d-mobile-hzl: "(min-width: 568px)";
$d-mobile: "(max-width: 567px)";

// @media only screen and #{$d-tablet-hzl} {

// @media only screen and #{$retina} {
//    background-image: url($loupe-icon-2x-image);
//    background-size: $loupe-icon-2x-total-width / 2 $loupe-icon-2x-total-height / 2;
// }	
