@import "../libs/bourbon/app/assets/stylesheets/bourbon";

@import "common/normalize";

/*  import sprite  */
@import "../img/sprite/sprite";
//@include retina-sprites($retina-groups);

@import "common/vars";
@import "common/mixins";
@import "common/general";


/* -------------- grid  --------------*/	
.container{
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
	@media only screen and #{$d-desktop} {
		width: 1093px;
		margin: 0 auto;
		padding-left: 0;
		padding-right: 0;
	}
}	


/* -------------- info --------------*/		
.info{
	display: inline-flex;
	align-items: center;
	flex-shrink: 1;
	&__icon{
		flex-shrink: 0;
		display: inline-block;
		margin-right: 10px;
		&--map{@include sprite($marker-icon);}
		// &--tel{@include sprite($call-icon);}
		// &--map-blue{@include sprite($marker-blue-icon);}
		&--mail{@include sprite($mail-icon);}

		&--margin{
			margin-left: 0;
		}
		&--margin0{margin-right: 0;}
		&--mr13{margin-right: 13px;}
		&--desktop{display: none;}

	}
	&__text{
		color: #000;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.2;
		text-align: left;
		-ms-flex: 0 1 auto;
		&--flex{
			display: flex;
			align-items: center;
		}
	}
	&__desktop-text{display: none;}
	&__number{
		color: #555;
		font-weight: 400;
		text-decoration: none;
		&:hover{color: $main-color;}
	}
	&__map{
		font-family: $default-fonts;
		-ms-flex: 0 1 auto;
		transition: all .3s ease-out;

		color: #000;
		font-size: 12px;
		font-weight: 100;
		text-decoration: underline;
		text-decoration-color: #000;
		text-decoration-style: dashed;
		&:hover,
		&:active{
			text-decoration: none;
			opacity: .7;
		}
		&:active{opacity: .5;}
	}
	&__mail{
		display: flex;
		text-decoration: none;
		color: #444;
		font-size: 13px;
		font-weight: 300;
		&:hover{color: $main-color;}
	}
	&__currency{
		margin-right: 10px;
	}

	@media only screen and (min-width: 400px) {
		&__text{
			&--flex{align-items: baseline;}
		}
	}

	@media only screen and #{$d-desktop} {
		&__map{
			color: #1c48a2;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.2;
			text-decoration: none;
			&:after{
				content: '';
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 3px 2.5px 0 2.5px;
				border-color: #000000 transparent transparent transparent;
				display: inline-block;
				line-height: 1;
				vertical-align: middle;
				margin-left: 10px;
			}
			&:hover{
				color: #ff3d00;
			}
		}
		&__icon{
			&--desktop{display: block;}
			&--margin{margin-left: 10px;}
		}
		&__text{
			&--flex{
				flex-wrap: wrap;
			}
		}
		&__desktop-text{display: block};
	}
}

/* -------------- call --------------*/		
.call{
	display: flex;
	align-items: center;
	-ms-flex: 0 1 auto;
	color: #222;
	font-size: 14px;
	font-weight: 600;
	line-height: 1.3;
	text-decoration: none;
	&:before{
		content: '';	
		display: block;
		margin-right: 18px;
		@include sprite($phone-call-icon);
		@media only screen and #{$retina} {
		   background-image: url($phone-call-icon-2x-image);
    		background-size: $phone-call-icon-2x-total-width / 2 $phone-call-icon-2x-total-height / 2;
		}
	}
	&:hover,
	&:active{
		color: $main-color;
	}
	&:active{
		opacity: .7
	}
}


/* -------------- basket --------------*/		
.basket{
	display: flex;
	align-items: center;
	text-decoration: none;
	flex-wrap: wrap;
   justify-content: center;

	&:hover{
		text-decoration: none;
		opacity: .7;
		.basket__price{
			text-decoration: none;
		}
	}
	&:active{opacity: .5;}
	&__icon{
		display: block;
		@include sprite($basket-icon);
		@media only screen and #{$retina} {
		   background-image: url($basket-icon-2x-image);
    		background-size: $basket-icon-2x-total-width / 2 $basket-icon-2x-total-height / 2;
		}
		&-box{
			display: inline-block;
			position: relative;
			padding-right: 13px;
			margin-right: 7px;
		}
	}
	&__price{
		color: #222;
		font-size: 14px;
		font-weight: 600;
		line-height: 1.4;
		&-val{
			font-size: 16px;
		}
		&-text{display: none;}
		.price-currency{font-weight: 400;}
	}
	&__count{
		position: absolute;
		bottom: 8px;
    	right: 0;
    	display: block;
    	min-width: 20px;
    	text-align: center;
    	padding: 4px 2px 5px;
    	background-color: $main-color;
	   border-radius: 50%;
	   color: #fff;
	   font-size: 11px;
	   font-weight: 900;
	   line-height: 1;
	   text-transform: uppercase;  
	}

	&--product{
		.basket__count{
			bottom: -4px;
		   border: 0;
		   padding: 4px;
		   min-width: 20px;
		}
		.basket__price{
			text-decoration: none;
		   color: #fff;
		   margin: 0 0 0 13px;
		}
	}

	@media only screen and #{$d-desktop} {
		&__price-text{display: inline;}
	}
}

.lg-backdrop{z-index: 1050;}

/*-- =========================
     components
============================== --*/	

/* -------------- logo --------------*/		
.logo{
	display: block;
	text-decoration: none;
	text-align: center;
	&__text{
		color: #fff;
		font-size: 19px;
		font-family: 'Cleanvertising Black','Museo Sans', sans-serif, -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue;
		line-height: 1;
		text-transform: uppercase;
		transition: all 1s ease-out;
	}
	&__number{
		display: block;
		font-size: 36px;
		line-height: 1;
		margin-bottom: 7px;
    	margin-top: -7px;
	}
	&:hover,
	&:active{
		.logo__text{
			color: #ff3d00;
		}
	}
	&:active{opacity: .5;}
	&--dark{
		.logo__text{color: #222;}
	}
}


/* -------------- social --------------*/		
.social{ 
	display: flex;
	justify-content: space-between;
	width: 100%;
	&--block{width: 120px;}
	&__icon{
		display: block;
		text-decoration: none;
		&::before{
			font-family: "fontello";
			font-size: 14px;
			line-height: 1;
			color: #444;
		}
		&:hover,
		&:focus{
			text-decoration: none;
			opacity: .8;
			&:before{
				color: $main-color;
			}
		}
	
		&--fb{
			&::before{
				content: '\e801';
			}
		}
		&--tw{
			&::before{
				content: '\f099';
			}
		}
		&--vk{
			&::before{
				content: '\e802';
			}
		}
		&--ok{
			&::before{
				content: '\f263';
			}
		}
	}

	&--default-color{
		.social__icon{
			&--fb{
				&::before{
					color: #468ac7;
				}
			}
			&--tw{
				&::before{
					color: #468ac7;
				}
			}
			&--vk{
				&::before{
					color: #6a87a7;
				}
			}
			&--ok{
				&::before{
					color: #cc9208;
				}
			}
		}
	}

	&--white{
		.social__icon{
			&:before{
				color: #fff;
			}
		}
	}

	&--large{//ok
		.social__icon{
			&:before{
				font-size: 23px;
			}
		}
	}

	&--footer{
		display: inline-flex;
		width: 160px;
		margin-bottom: 35px;
	}

	&--w88{ 
		width: 88px;
	}
}

.social-round,
.social-square{
	display: flex;
	justify-content: space-between;
	width: 100%;
	&__icon{
		display: block;
		position: relative;
		width: 27px;
		height: 27px;
		border-radius: 50%;
		text-decoration: none;
		transition: all .5s ease;
		&::before{
			font-family: "fontello";
			font-size: 15px;
			line-height: 1;
			color: #fff;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
		&:hover,
		&:focus{
			text-decoration: none;
			opacity: .8;
		}
		&:hover,
		&:active{
			background-color: #90908f;
		}
	
		&--fb{
			background-color: $main-color;
			&::before{
				content: '\e801';
			}
		}
		&--vk{
			background-color: $main-color;
			&::before{
				content: '\e802';
			}
		}
		&--ok{
			background-color: $main-color;
			&::before{
				content: '\f263';
			}
		}
	}

	&--small{
		.social-round__icon{
			width: 27px;
			height: 27px;
		}
		&::before{
			font-size: 15px;
		}
	}

}

.social-square{
	&__icon{
		border-radius: 0;
	}
	&--small{
		.social-square__icon{
			width: 29px;
			height: 29px;
			&::before{font-size: 18px;}
			&--fb{
				&:before{
					top: auto;
					bottom: -1px;
				   right: 6px;
				   left: auto;
				   transform: none;
		    		font-size: 26px;
				}
			}
		}
	}
	&--default-color{
		.social-square__icon{
			&--fb{background-color: #3c5c97;}
			&--vk{background-color: #4c75a3;}
			&--ok{background-color: #f07d00;}
		}
	}
}

.social-content{
	display: flex;
	justify-content: flex-end;
	align-items: center;
	&__text{
		-ms-flex: 0 1 auto;
		color: #222;
		font-size: 14px;
		font-weight: 300;
		line-height: 24px;
		line-height: 1.6;
		margin-right: 15px;
	}
	&__w-83{width: 83px;}
	&__w-102{width: 102px;}
	&__w-107{width: 107px;}

	&--footer{
		display: block;
		text-align: center;
		.social-content__text{
			margin: 0 0 11px;
		}
		.social{margin: 0 auto;}
	}
	@media only screen and #{$d-tablet} {
		&--footer{
			text-align: left;
		}
		.social{margin: 0;}
	}
}

/* -------------- button --------------*/		
.btn{
	width: 100%;
	display: inline-block;
   line-height: 1;
   text-align: center;
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
   vertical-align: middle;
   user-select: none;
   padding: 17px 10px;
   font-size: 14px;
   font-weight: 400;
   color: #fff;
   background-color: $main-color;
   border: none;
   transition: all .2s ease-in-out;
   text-decoration: none;
   &:hover{
   	text-decoration: none;
   	cursor: pointer;
   	opacity: .8;
   }
   &:focus{
   	box-shadow: 0 0 0 2px rgba(204,204,204,.5);
   	text-decoration: none;
   	outline: 0;
   }
   &:active{
   	opacity: .7;
   }
   &[disabled]{
   	opacity: .3;
   	box-shadow: none;
   	cursor: not-allowed;
   	pointer-events: none;
   	&:hover{box-shadow: none;}

   }
	&--default{
		padding: 14px 10px 15px;
   	background-color: #222;
		background-image: linear-gradient(9deg, #2f73fc 0%, #1c48a2 100%);
		border-radius: 2px;
		box-shadow: 0 5px 20px rgba(20, 57, 133, 0.4);
		&:active,
		&:hover{
			background-image: linear-gradient(9deg, #1c48a2 0%, #2f73fc 100%);
			color: #fff;
		}
		&-large{
			padding: 16px 10px;
			font-size: 18px;
			font-weight: 600;
		}
   }
   &--basket{
   	width: 75px;
		height: 44px;
   	position: relative;
   	background-image: linear-gradient(9deg, #2f73fc 0%, #1c48a2 100%);
		&:before{
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			@include sprite($basket-white-icon);
			@media only screen and #{$retina} {
	   		background-image: url($basket-white-icon-2x-image);
	    		background-size: $basket-white-icon-2x-total-width / 2 $basket-white-icon-2x-total-height / 2;
	   	}
		}
		&:hover{box-shadow: 0 1px 12px rgba(0, 0, 0, 0.5);}
   }
	&--call{
		padding: 19px 10px;
   	font-size: 18px;
   	font-weight: 600;
		background-color: #416dad;
		border: 2px solid #fff;
		border-radius: 28px;
   }
   &--order{
		font-size: 12px;
		font-weight: 900;
		background-color: #416dad;
		border: 2px solid #fff;
		border-radius: 28px;
		text-transform: uppercase;
	}
	&--way{font-weight: 600;}
   &--icon-arrow{
   	&:after{
   		content: '';
   		display: inline-block;
   		margin-left: 10px;
   		@include sprite($arrow-2down-icon);
   		@media only screen and #{$retina} {
   			background-image: url($arrow-2down-icon-2x-image);
    			background-size: $arrow-2down-icon-2x-total-width / 2 $arrow-2down-icon-2x-total-height / 2;
   		}
   	}
   	&-up{
   		margin-top: 0;
   		&:after{
   			transform: rotate(180deg);
   		}
   	}

   }
   
   // size
 	&--w146{width: 146px;}

}

/* -------------- btn-menu --------------*/		
.btn-menu{ 
	position: relative;
	display: block;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background-color: #fff;
	&:focus{
		background-color: #fff;
	}

	&:active{
		opacity: .7;
		background-color: $main-color;
		.btn-menu__lines{
			background-color: #fff;
			&:before,
			&:after{background-color: #fff;}
		}
	}
	&__lines{
		position: absolute;
		left: 50%;
    	top: 50%;
    	transform: translate(-50%, -50%);
		width: 13px;
		height: 1px;
		display: block;
		background-color: #245ac7;
		&:before,
		&:after{
			content: '';
			position: absolute;
			width: 13px;
			height: 1px;
			display: block;
			background-color: #245ac7;
		}
		&:before{top: -6px;}
		&:after{bottom: -6px;}
	}
	&__text{
		display: none;
		color: #fff;
		font-size: 8px;
		font-weight: 600;
		line-height: 1;
		text-transform: uppercase;
		text-align: center;
		margin-top: 13px;
	}

	@media only screen and #{$d-desktop} {
		width: 65px;
    	height: 65px;
		&:hover{
			background-color: $main-color;
			opacity: 1;
			.btn-menu{
				&__lines{
					background-color: #fff;
					opacity: 1;
					&:before,
					&:after{
						background-color: #fff;
					}
				}
			}
		}
		&__lines{
			width: 19px;
			box-shadow: 0 4px 4px rgba(25, 40, 71, 0.3);
			&:before,
			&:after{
				width: 19px;
				box-shadow: 0 4px 4px rgba(25, 40, 71, 0.3);
			}
		}
		&__text{display: block;}
	}

}

/* -------------- btn-control --------------*/		
.btn-control{
	display: inline-block;
	padding: 16px 9px;
	border-radius: 4px;
	&:hover{
		background-color: $main-color;
	}
	&__dots{
		position: relative;
		display: block;
		width: 8px;
		height: 8px;
		border-radius: 50%;
		border: 1px solid #fff;
		&:before,
		&:after{
			position: absolute;
			left: -1px;
			content: '';
			display: block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			border: 1px solid #fff;
		}
		&:before{top: -11px;}
		&:after{bottom: -11px;}
	}

	&--desktop{
		padding: 25px 34px;
		&:hover{background-color: transparent;}
		.btn-control{
			&__dots{
				border-color: #000;
				&:after,
				&:before{
					border-color: #000;
				}
				&:before{
					top: -1px;
				   right: -11px;
				   left: auto;		
				}
				&:after{
					bottom: -1px;
    				left: -11px;
				}
			}
		}
	}
}


.btn--default-scroll.btn--icon-arrow-up {margin-top: 12px;}

.btn-icon{
	display: inline-block;
	transition: all .3s ease-out;
	&:hover{
		opacity: .7;
	}
	&:active{
		opacity: .4;
	}

	&--menu{
		//@include sprite($menu-icon);
	}
	&--form-loupe{ 
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 21px;
		padding: 0;
		border: 0;
		background-color: transparent;
		transition: all linear .01s;
		@include sprite($loupe-icon); 
		@media only screen and #{$retina} {
   		background-image: url($loupe-icon-2x-image);
    		background-size: $loupe-icon-2x-total-width / 2 $loupe-icon-2x-total-height / 2;
   	}
   	&:focus{
			outline: none;
		}
		&:hover{
			transition: all linear .01s;
			opacity: 1;
			@include sprite($loupe-red-icon); 
			@media only screen and #{$retina} {
	   		background-image: url($loupe-red-icon-2x-image);
	    		background-size: $loupe-red-icon-2x-total-width / 2 $loupe-red-icon-2x-total-height / 2;
	   	}
		}
		&-white{
			@include sprite($loupe-white-icon); 
			@media only screen and #{$retina} {
	   		background-image: url($loupe-white-icon-2x-image);
	    		background-size: $loupe-white-icon-2x-total-width / 2 $loupe-white-icon-2x-total-height / 2;
   		}
		}
	}

	// &--up{
	// 	@include sprite($up-icon);
	// 	@media only screen and #{$retina} {
	// 		background-image: url($up-icon-2x-image);
	//       background-size: $up-icon-2x-total-width / 2 $up-icon-2x-total-height / 2;
	// 	}
	// }
	&--form-arrow{ 
		position: absolute;
		top: 53%;
		transform: translateY(-50%);
		right: 19px;
		padding: 0;
		border: 0;
		background-color: transparent;
		@include sprite($enter-arrow-icon); 
		@media only screen and #{$retina} {
			background-image: url($enter-arrow-icon-2x-image);
	      background-size: $enter-arrow-icon-2x-total-width / 2 $enter-arrow-icon-2x-total-height / 2;
		}
	}
}

/* -------------- scroll overlay --------------*/		
.scroll-hidden{
	overflow: hidden;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	.scroll-hidden-over{
		&:after{
			content: '';
			position: fixed;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			background-color: #000;
			opacity: .5;
		}
	}
	@media only screen and #{$d-desktop} {
		overflow: auto;
		position: relative;
	}
}

/* -------------- form --------------*/		
.form{
	&-control{
		display: block;
	   width: 100%;  
    	padding: 10px 28px 9px;
	   font-size: 14px;
		font-weight: 300;
	   line-height: 1.25;
	   color: #fff;
	   background-color: #fff;
	   background-image: none;
	   background-clip: padding-box;
	   border: none;
	   border-radius: 0;
	   &:focus{
	   	outline: 0;
	   	//outline: 1px solid #35b6d8;
	   }
	   &::-webkit-input-placeholder{
			color: #000;
			text-overflow:ellipsis;
		} 
		&::-moz-placeholder{color: #000;text-indent: 15px;text-overflow:ellipsis;} 
		&:-moz-placeholder{color: #000;text-indent: 15px;text-overflow:ellipsis;}           
		&:-ms-input-placeholder{color: #000;text-indent: 15px;text-overflow:ellipsis;} 

		&--default{
	   	padding: 15px 17px 14px;
	   	border-radius: 0;
	   	color: #000;
	   	background: #fff;
	   	border: 1px solid #cfd3d5;
	   	overflow: hidden;
	   	text-overflow: ellipsis;
	   	&::-webkit-input-placeholder{color: #000;text-align: left;}
			&::-moz-placeholder{color: #000;text-align: left;}
			&:-moz-placeholder{color: #000;text-align: left;}
			&:-ms-input-placeholder{color: #000;text-align: left;}

			&-textarea{
	   		height: 168px;
	   		line-height: 1.5;
	   		background-color: #fff;
				border: 1px solid #1c48a2;
	   		padding: 11px 21px;
	   		resize: vertical;
	   		color: #666;
				font-size: 14px;
				font-weight: 300;
				margin-bottom: 10px;
	   	}
	   	&-comment,
	   	&-captcha{
	   		border-color: #2c55a9;
	   		padding: 12px 17px 11px;
	   	}
	   }
	   &--default-select{ // ok
	   	 ~ .chosen-container{
	   	 	width: 100%;
	   	 	font-size: 16px;
	   	 	font-weight: 300;
	   	 	text-align: left;
	   	 	background-color: transparent;
	   	 	margin-bottom: 18px;
	   	 	.chosen-single div b{
    				position: relative;
    				right: 5px;
	   	 		@include sprite($down-icon);
	   	 		@media only screen and #{$retina} {
					   background-image: url($down-icon-2x-image)!important;
					   background-size: $down-icon-2x-total-width / 2 $down-icon-2x-total-height / 2!important;
					}	
	   	 	}
	   	 	.chosen-single span{
	   	 		margin-right: 33px;
	   	 	}
	   	 	.chose-image-small{
	   	 		float: right;
	   	 		width: 23px;
	   	 		height: 15px;
	   	 		max-height: 15px;
	   	 		margin: 5px 0 0 0;
	   	 	}
	   	 	.chosen-drop{
	   	 		border: 1px solid #1f55ab;
	   	 		border-top: 2px solid #1f55ab;
	   	 		border-radius: 0;
	   	 	}
	   	 	.chosen-results{
	   	 		margin: 0;
	   	 		padding: 20px 0;
	   	 	}
	   	 	.chosen-results li{
	   	 		position: relative;
	   	 		padding: 8px 23px;
					font-size: 16px;
					font-weight: 300;
					color: #000;
					&.highlighted{
						background-color: transparent;
						color: #2c5ca5;
						text-decoration: underline;
					}
	   	 	}
	   	 	.chose-image-list{
	   	 		margin-right: 9px;
	   	 		width: 23px;
	   	 		max-height: 15px;
	   	 		float: right;
	   	 	}
	   	 }

	   	 ~ .chosen-container-multi .chosen-choices,
	   	 ~ .chosen-container-single .chosen-single{
	   	 	min-height: 48px;
	   	 	padding: 12px 0 0 0;
		   	border-radius: 0;
		   	color: #000;
		   	background: transparent;
		   	border: 0;
		   	border-bottom: 2px solid #2e5ab6;
		   	div{padding: 20px 0px 0px 0px;}
	   	 }
	   	

	   	 ~ .chosen-container-single.chosen-container-single-nosearch .chosen-search{
	   	 	visibility: hidden;
	   	 }
	   }

	   &--select{ // ok
	    position: relative;
		 -webkit-appearance: none;
		  -moz-appearance: none;
		  text-indent: 0.01px; 
		  text-overflow: '';
		  -ms-appearance: none;
		  appearance: none!important;
		  &:hover{cursor: pointer;}
	   }
	   &-select-wrap{
		  	 display: block;
		    width: 100%;
		    position: relative;
		    &:before{
			  	content: '';
			  	display: block;
			  	position: absolute;
			  	top: 50%;
			  	right: 5px;
			  	transform: translateY(-50%);
			  	z-index: 1;
				@include sprite($down-icon);
				@media only screen and #{$retina} {
					background-image: url($down-icon-2x-image);
					background-size: $down-icon-2x-total-width / 2 $down-icon-2x-total-height / 2;
				}
			}
			&--margin{margin-bottom: 33px;}
			&--tel{
				width: 33%;
    			margin-right: 15px;
				position: relative;
				&:before{
					top: 17px;
					@include sprite($down-white-icon);
					@media only screen and #{$retina} {
						background-image: url($down-white-icon-2x-image);
						background-size: $down-white-icon-2x-total-width / 2 $down-white-icon-2x-total-height / 2;
					}
				}
			}
		 }

	   &--minimal{
	   	font-size: 16px;
	   	padding: 8px 25px 8px 1px;
	   	color: #000;
	   	border-bottom: 2px solid #2e5ab6;
	   }
	   &--minimal-white{
	   	padding: 8px 25px 8px 1px;
	   	background-color: #355aa4;
	   	color: #fff;
	   	border-bottom: 2px solid #fff;
	   }

	   &--textarea{ // ok
	   	height: 166px;
	   	line-height: 1.5;
	   	color: #000;
	   	padding: 20px 24px;
			background-color: #f1f0ee;
			border-radius: 10px;
			resize: vertical;
		}
		&--search{ 
			color: #000;
			font-size: 14px;
			border: 1px solid transparent;
			padding: 22px 48px 22px 19px;
			~.btn-icon{
				right: 27px;
			} 
			&-mobi{
				border: 0;
				box-shadow: 0 23px 16px rgba(0, 0, 0, 0.08);
				padding: 13px 42px 14px 29px;
				~.btn-icon{
					right: 18px;
				} 
			}
		}
		&--dark{
			color: #fff;
			background-color: #4c7bc1;
			box-shadow: 0 23px 16px rgba(0, 0, 0, 0.08);
			&::-webkit-input-placeholder{color: #fff;}
			&::-moz-placeholder{color: #fff;}
			&:-moz-placeholder{color: #fff;}
			&:-ms-input-placeholder{color: #fff;}
		}
		&--mail{ 
			padding: 17px 52px 17px 15px;
			font-size: 14px;
			font-weight: 300;
			background-color: #e7e9fa;
			color: #000;
			overflow: hidden;
			text-overflow: ellipsis;
			
			&::-webkit-input-placeholder{color: #000;}
			&::-moz-placeholder{color: #000;}
			&:-moz-placeholder{color: #000;}
			&:-ms-input-placeholder{color: #000;}

		}

	   &-box{ // ok
	   	display: block;
	   	position: relative;
	   	width: 100%;
	   }


	}

	@media only screen and #{$d-tablet} {
		&-control{
			&--default{
				&-comment{
					-ms-flex: 0 1 auto;
					max-width: 58%;
				}
			}
		}
	}

	@media only screen and #{$d-desktop} {
		&-control{
			&-select-wrap--tel{
				display: block;
			}
		}
	}

}

// IE select отмена стандартной стрелочки
select::-ms-expand {
  display: none;
}

/* -------------- menu --------------*/		
.menu{
	@media only screen and #{$d-desktop} {
		position: relative;
		background: linear-gradient(9deg, #4d51ad 0%,#2f5da2 100%);
	}

   // menu-catalog
	&-catalog{ 
		-webkit-overflow-scrolling: touch;
		&__item{
			position: relative;
		}
		&__list{
			padding: 0 0 60px;
		}
		&__link{
			position: relative;
			display: block;
			width: 320px;
			padding: 19px 16px 18px 41px;
			color: #fff;
			font-size: 15px;
			font-weight: 300;
			line-height: 1.5;
			text-decoration: none;
			transition: all .3s ease-out;
			&:after{
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				width: 263px;
				height: 1px;
				opacity: 0.1;
				background-color: #fff;
			}
			&:active,
			&:hover{
				background-color: #3264b0;
				color: #fff;
				text-decoration: none;
			}
			&--active{
				background-color: #3264b0;
			}
		}

		&__scroll-btn{
			display: none!important; 
			opacity: 0;
			position: absolute;
			z-index: 1;
			display: block;
			height: 75px;
			left: 0;
    		right: 0;
    		background-color: #f3f2f0;
    		border-radius: 0;
    		transition: all .3s ease-out;
			&--top{
				top: 0;
				background: linear-gradient(to bottom, #335ba3 0%,#3a59a6 100%);
				&:before{
					content: '';
					@include sprite($arrow-double-up-icon);
					display: block;
					position: absolute;
					top: 50%;
					left: 53px;
					//left: 50%;
					transform: translate(-50%, -50%);
				}
				&:hover{
					&:before{opacity: .7;}
				}
			}
			&--bottom{
				bottom: 0;
				background: linear-gradient(45deg, #4952ab 0%,#5e62b5 100%);
				&:before{
					content: '';
					@include sprite($arrow-double-down-icon);
					display: block;
					position: absolute;
					top: 50%;
					left: 53px;
					//left: 50%;
					transform: translate(-50%, -50%);
				}
				&:hover{
					&:before{opacity: .7;}
				}
			}

		}


		@media screen and #{$d-tablet}{
			width: 320px;
			overflow: hidden;
			&__scroll-btn{
				display: none!important;
				opacity: 0;
				&--visible{
					display: block!important;
					opacity: 1;
				}
			}
			&__list{
				overflow: auto;
			   max-height: 546px;
			   width: 360px;
			   padding: 0;
			}
		}

		@media only (max-height: 679px) { 
			&__scroll-btn{

					&--visible{
						display: none!important;
						opacity: 0;
					}

			}
		}

		@media screen and #{$d-desktop}{
			padding-top: 11px;
    		padding-bottom: 36px;
    		position: relative;
			&__scroll-btn{
				height: 50px;
				&:before{left: 50%;}
				&.menu-catalog__scroll-btn{
					&--visible{
						display: block!important;
						opacity: 1;
					}
				}
			}
			&-box{
				&:before{
					content: '';
				   background: url(../img/svg/triangle-small.svg);
				   width: 9px;
				   height: 21px;
				   position: absolute;
				   top: 72px;
				   left: 0;
				   transform: translateX(-100%);
				}
			}
		}

	}
}

/* -------------- card --------------*/		
.card{
	text-align: center;
	&__img{
		&-box{
			display: inline-block;
			margin-bottom: 22px;
		}
		&-link{display: block;}
	}
	&__title{
		color: $accent-color;
		font-size: 21px;
		font-weight: 900;
		line-height: 1.285;
		text-transform: uppercase;
		margin-bottom: 5px;
	}
	&__text{
		color: $accent-color;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.5;
	}
	&__price{
		margin-bottom: 33px;
		&-box{margin-top: 16px;}
		&-old{
			color: #000;
			font-size: 21px;
			font-weight: 300;
			line-height: 1;
			text-decoration: none;
			margin-right: 9px;
			&-val{
				text-decoration: line-through;
				text-decoration-color: #8f8f8f; 
			}
		}
		&-new{
			color: $main-color;
			font-size: 38px;
			font-weight: 900;
			line-height: 1.105;
			text-decoration: none;
		}
	}
	&__currency-old{font-size: 18px;}
	&__currency-new{
		font-weight: 400;
		font-size: 31px;
		color: #000;
	}


	@media only screen and #{$d-desktop} {
		text-align: left;
		padding: 0 10px;
		&__img{
			&-box{
				order: 1;
				flex-shrink: 0;
				margin-bottom: 0;
				max-width: 395px;
				max-height: 344px;
			}
		}
		&__content{
			-ms-flex: 0 1 auto;
			flex-grow: 1;
			position: relative;
			&-box{
				display: flex;
				justify-content: space-between;
    			align-items: center;
			}
		}
		&__crop{
			max-height: 209px;
			overflow: hidden;
		}
		&__title{font-size: 36px;}
		&__text{font-size: 18px;}
	}
}

@media only screen and #{$d-desktop} {
	.js-card-lengthen{
		position: absolute;
		top: 0;
		width: 385px;
	}
}

/* -------------- slider --------------*/		
.slider{
	.owl-prev,
	.owl-next {
	    transition: all .3s ease-out;
	    position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
		 color: #000;
	    &:before{
			font-family: 'fontello';
			font-size: 17px;
			line-height: 1;
	    }
	    &:hover,
	    &:active{
	    	opacity: 1;
	    }
	}
	.owl-prev{
		left: -75px;
		&:before{
			content: '\e809';
		}
	}
	.owl-next{
		right: -75px;
		&:before{
			content: '\e808';
		}
	}

	.owl-dots{ 
	   width: 100%;
	   text-align: center;
	}
	.owl-dot{ 
		display: inline-block;
		vertical-align: middle;
	   margin: 0 7px;
	   margin-top: 24px;
	   width: 46px;
		height: 2px;
		opacity: 0.2;
		background-color: #222;
   	cursor: pointer;
   	transition: all .25s ease;
   	&.active{
   		background-color: #222;
			background-image: linear-gradient(9deg, #2f73fc 0%, #1c48a2 100%);
			box-shadow: 0 5px 20px rgba(20, 57, 133, 0.4);
   		opacity: 1;
   	}
	}

	&--presentation{
		width: 275px;
		margin: 0 auto;
		position: static;
		.owl-stage-outer{position: static;}
		

		.owl-prev,
		.owl-next{
			width: 174px;
		   height: 297px;
		   &:before{
		   	position: absolute;
		   	padding: 19px 13px 19px 14px;
		   }
		   &:hover{
		   	border-color: rgba(47, 115, 252, 0.7);
		   }
		}
		.owl-prev{
			top: 46%;
			left: 0;
			border: 29px solid rgba(226, 226, 226, 0.51);
			border-left-width: 0;
			&:before{
				bottom: 0;
				transform: translateY(-71px);
				left: 23px;
				box-shadow: 6px 0 10px rgba(0, 0, 0, 0.1);
			}
			&:active{
				&:before{box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);}
			}
		}
		.owl-next{
			top: 56%;
			right: 0;
			border: 29px solid rgba(226, 226, 226, 0.51);
			border-right-width: 0;
			&:before{
				top: 0;
				transform: translateY(64px);
				right: 23px;
				box-shadow: -6px 0 10px rgba(0, 0, 0, 0.1);
			}
			&:active{
				&:before{box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);}
			}
		}
	}

	&--product{
		.product-card{
			&:nth-child(2){display: none;}
		}

		.owl-prev{
			left: -41px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 46px 40px 46px 0;
			border-color: transparent #ffffff transparent transparent;
			&:before{
				position: absolute;
			   top: -8px;
			   left: 19px;
			}
			&:hover,
			&:active{
				border-right-color: rgba(47, 115, 252, 0.7);
				&:before{color: #fff;}
			}
		}

		.owl-next{
			right: -41px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 46px 0 46px 40px;
			border-color: transparent transparent transparent #ffffff;
			&:before{
				position: absolute;
			   top: -8px;
			   right: 19px;
			}
			&:hover,
			&:active{
				border-left-color: rgba(47, 115, 252, 0.7);
				&:before{color: #fff;}
			}
		}
		
	}

	&--product-desktop{
		margin-left: auto;
		margin-right: auto;
		.slider{
			&__item{
				&--margin{margin-bottom: 25px;}
			}
		}

		.owl-prev{
			left: -41px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 46px 40px 46px 0;
			border-color: transparent #ffffff transparent transparent;
			&:before{
				position: absolute;
			   top: -8px;
			   left: 19px;
			}
			&:hover,
			&:active{
				border-right-color: rgba(47, 115, 252, 0.7);
				&:before{color: #fff;}
			}
		}

		.owl-next{
			right: -41px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 46px 0 46px 40px;
			border-color: transparent transparent transparent #ffffff;
			&:before{
				position: absolute;
			   top: -8px;
			   right: 19px;
			}
			&:hover,
			&:active{
				border-left-color: rgba(47, 115, 252, 0.7);
				&:before{color: #fff;}
			}
		}
	}

	&--article{ 
		width: 100%;

		.owl-prev{
			left: -39px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 46px 40px 46px 0;
			border-color: transparent #ffffff transparent transparent;
			&:before{
				position: absolute;
			   top: -8px;
			   left: 19px;
			}
			&:hover,
			&:active{
				border-right-color: rgba(47, 115, 252, 0.7);
				&:before{color: #fff;}
			}
		}

		.owl-next{
			right: -39px;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 46px 0 46px 40px;
			border-color: transparent transparent transparent #ffffff;
			&:before{
				position: absolute;
			   top: -8px;
			   right: 19px;
			}
			&:hover,
			&:active{
				border-left-color: rgba(47, 115, 252, 0.7);
				&:before{color: #fff;}
			}
		}
	}


	&--hit{ //ok
		min-width: 320px;
		.owl-prev{left: 0;}
		.owl-next{right: 0;}
		.owl-prev:before, 
		.owl-next:before{
			font-size: 96px;
		}
	}

	&--category{
		width: 1004px;
	   margin: 0 auto;
	   position: relative;
	   left: 50%;
	   transform: translateX(-50%);
	   // .owl-next{
	   // 	@include sprite($right-icon);
	   // 	&:before{content: none;}
	   // 	right: -54px;
	   // }
	   // .owl-prev{
	   // 	@include sprite($left-icon);
	   // 	&:before{content: none;}
	   // 	left: -54px;
	   // }
		.owl-dot{
			width: 40px;
			height: 14px;
			opacity: 0.5;
			background-color: #b5b5b5;
			border-radius: 7px;
			margin-top: 40px;
			&:hover,
			&.active{
				background-color: #5d5d5d;
				opacity: 1;
			}
		}
		.slider__item{padding: 18px;} 
	}

	&--product-like{
		.owl-dots{bottom: -35px;}
		.owl-prev{left: -61px;}
		.owl-next{right: -61px;}
		.owl-prev:before, .owl-next:before{
			font-size: 43px;
		}
	}

	@media only screen and #{$d-mobile-hzl} {
		&--product{
			width: 546px;
			margin: 0 auto;
		}
		&--product-desktop{
			display: flex;
			flex-wrap: wrap;
			width: 546px;
			margin: 0 auto;
			.slider__item{
				width: 272px;
			}
			&.owl-carousel{
				display: flex;
				flex-wrap: wrap;
			}
			.product-card{
				margin-left: 1px;
				margin-right: 1px;
			}
		}
		&--article{
			width: 546px;
			margin: 0 auto;
		} 
	}

	@media only screen and #{$d-tablet-hzl} {
		&--product{
			width: 816px;
		}
		&--product-desktop{
			width: 819px;
		}
	}

	@media only screen and #{$d-desktop} {
		&--presentation{
			-ms-flex: 0 1 auto;
			width: 715px;
			.owl-prev,
			.owl-next{
				width: 147px;
			}
			.owl-prev{
				&:before{left: 38px;}
			}
			.owl-next{
				&:before{right: 38px;}
			}
		}

		&--product{
			width: 100%;
			.product-card{
				&:nth-child(2){display: block;}
			}

			.owl-dots{text-align: right;}
			.owl-dot{
				&:last-child{margin-right: 3px;}
			}
			.owl-prev,
			.owl-next{top: 46%;}
		}

		&--product-desktop{
			position: relative;
			display: block;
			width: 100%;
			&.owl-carousel{display: block;}
			.owl-dots{text-align: right;}
			.owl-dot{
				&:last-child{margin-right: 3px;}
			}
			.owl-prev,
			.owl-next{top: 46%;}

		}

		&--article{
			width: 100%;
			.owl-stage-outer{border: 2px solid #ebeef0;}
			.owl-dots{text-align: right;}
			.owl-dot{
				&:last-child{margin-right: 3px;}
			}
		}
	}

}

/* -------------- rating-stars --------------*/
.rating-stars {
   display: block;
   &__star{
	    position: relative;
	    cursor: pointer;
	    display: inline-block;
	    width: 8px;
	    height: 8px;
	    margin-right: -1px;
	    vertical-align: middle;
	    outline: none;
		 &::before{
		 	content: '';
		 	display: block;
		 	background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyI+PHBhdGggZmlsbD0idHJhbnNwYXJlbnQiIGQ9Ik02LjQ5MSAwbDEuNTA5IDVoNWwtNCAzIDIgNS00LjUtMy00LjUgMyAyLTUtNC0zaDV6IiBzdHJva2U9IiMyZjczZmMiIHN0cm9rZS13aWR0aD0iMXB4Ii8+PC9zdmc+);
		 	background-size: cover;
		 	width: 8px;
	    	height: 8px;
		 }

   	&--hover,
   	&--active{
   		&::before{
				background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyI+PHBhdGggZmlsbD0iIzJmNzNmYyIgc3Ryb2tlPSIjMmY3M2ZjIiAgZD0iTTYuNDkxIDBsMS41MDkgNWg1bC00IDMgMiA1LTQuNS0zLTQuNSAzIDItNS00LTNoNXoiLz48L3N2Zz4=);
				background-size: cover;
   		}
   	}
   }
   &__item{
   	color: #333;
   	font-size: 16px;
		font-weight: 900;
		line-height: 1.312;
		text-decoration: none;
		margin-right: 20px;
		&:last-child{margin-right: 0;}
		&--active{
			color: #1b6bb2;
			text-decoration: underline;
		}
		&:hover{color: #1b6bb2;}
   }
   &--lg {												
   	display: inline-block;
   	vertical-align: middle;
   	.rating-stars__star{
   		width: 14px;
			height: 15px;
			padding-right: 25px;
    		float: left;
    		&:last-child{padding-right: 0;}
			&::before{
				width: 19px;
				height: 19px;
			}
   	}
   }
   &--normal{
   	display: inline-block;
   	vertical-align: middle;
   	.rating-stars__star{
   		width: 13px;
			height: 13px;
			padding-right: 18px;
    		float: left;
    		&:last-child{padding-right: 0;}
			&::before{
				width: 13px;
				height: 13px;
			}
   	}
   }
   &--light{
   	display: inline-flex;
   	.rating-stars {
  			&__star{
  				display: block;
  				width: auto;					
  				height: auto;
  				padding-right: 3px;
  				&:before{content: none;}
  				&:after{
  					content: '';
  					display: block;
	  				width: 17px;
					height: 4px;
					border: 1px solid #1c48a2;
  				}
  				&:last-child{padding-right: 0;}

  				&--hover,
   			&--active{
   				&:after{
   					background-color: #1c48a2;
   				}	
   			}
  			}
  		}
   }
   &--margin{margin-bottom: 10px;}
}

.rating-stars__star, .rating-stars__star:before {
    background-size: cover;
}

/* -------------- product-card --------------*/		
.product-card{
	//width: 271px;
	width: 100%;
	background-color: #fff;
	display: block;
	margin: 0 auto;
	text-align: left;
	&__content{
		padding: 13px 21px 19px;

	}
	&__name{
		color: $accent-color;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.5;
		text-decoration: none;
		margin-bottom: 3px;
		&:focus{color: $accent-color;}
		&:hover,
		&:active{
			color: $main-color;
		}
	}
	&__img{
		display: inline-block;
	   max-width: 100%;
	   max-height: 189px;
	   margin: 0 auto;
		&-box{
			padding: 15px 0 30px;
		   text-align: center;
		   height: 232px;
    		display: flex;
    		//justify-content: center;
    		align-items: center;
    		&:hover{opacity: .7;}
    		&:active{opacity: .5;}
		}
	}
	&__description{
		color: #666;
		font-size: 14px;
		font-weight: 300;
		line-height: 1;
	}
	&__stats{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__reviews{
		display: block;
		color: #999;
		font-size: 12px;
		font-weight: 600;
		line-height: 1.2;
		text-decoration: none;
		&:hover{
			color: #ff3d00;
		}
	}
	&__price{
		text-decoration: none;
		color: $accent-color;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.5;
		&-box{
			height: 45px;
			padding-left: 21px;
			padding-right: 85px;
			position: relative;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-top: 1px solid #e6e8ea;
			border-bottom: 1px solid #e6e8ea;
		}
	}
	&__btn{
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}
	&__currency{margin-left: 6px;}
	&--margin{margin-bottom: 25px;}
	&--default{
		width: 271px;
	}
}

.js-product-card__crop{ //ok
	width: 100%;
	height: 80px;
	overflow: hidden;
}

/* -------------- article --------------*/		
.article{ 
	width: 100%;
	&__img{
		width: 100%;
		background-size: cover;
		height: 285px;
		transition: all .3s ease-out;
		&-box{
			width: 100%;
			height: 285px;
			overflow: hidden;
			&:hover{
				.article__img{transform: scale(1.2);}
			}
		}
	}
	&__box{
		-ms-flex: 0 1 auto;
		padding: 15px 23px 22px;
		background-color: #fff;
		border: 2px solid #ebeef0;
	}
	&__title{
		margin-bottom: 20px;
	}
	&__link{
		color: $accent-color;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.333;
		text-decoration: none;
		&:hover,
		&:active{
			color: $main-color;
		}
	}
	&__text{
		color: $accent-color;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.5;
	}
	&__footer{
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid #e2e5e7;
		margin-top: 17px;
		padding-top: 20px;
	}
	&__views{
		display: flex;
		align-items: center;
		color: $accent-color;
		font-size: 12px;
		font-weight: 300;
		line-height: 1;
		&:before{
			content: '';
			display: block;
			margin-right: 10px;
			@include sprite($eye-icon);
			@media only screen and #{$retina} {
			   background-image: url($eye-icon-2x-image);
			   background-size: $eye-icon-2x-total-width / 2 $eye-icon-2x-total-height / 2;
			}
		}
	}
	&__date{
		color: $accent-color;
		font-size: 12px;
		font-weight: 300;
		line-height: 1;
	}

	&--default{
		width: 300px;
		margin: 0 auto;
	}

	@media only screen and #{$d-mobile-hzl} {
		display: flex;
		border: 2px solid #ebeef0;
		&--default{width: 100%;}
		&__img{
			&-box{
				width: 272px;
				height: 285px;
				flex-shrink: 0;
			}
		}
		&__box{
			flex-grow: 1;
			border: 0;
			padding: 18px 24px;
		}
		&__footer{margin-top: 25px;}
	}
		@media only screen and #{$d-desktop} {
		border: 0;
	}
}
.js-article__crop{
	height: 160px;
	overflow: hidden;
	@media only screen and #{$d-mobile-hzl} {
		height: 175px;
	}
}

/* -------------- site --------------*/		
.site{
	background-color: #fff;
	text-align: center;
	padding: 39px 5px 20px;
	&__img{
		display: inline-block;
		max-width: 100%;
		margin-bottom: 32px;
	}
	&__box{
		-ms-flex: 0 1 auto;
		text-align: left;
		padding: 0 8px 0 15px;
	}
	&__title{
		color: $accent-color;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.333;
		margin-bottom: 12px;
	}
	&__text{
		color: #444;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.5;
		margin-bottom: 21px;
	}

	@media only screen and #{$d-tablet-hzl} {
		display: flex;
		align-items: center;
		padding: 16px 20px 4px 10px;
		margin-bottom: 0;
		&__box{
			padding: 0;
			margin-left: -12px;
		} 
		&__img{
			max-width: 300px;
			flex-shrink: 0;
		}
	}
}

/* -------------- manual --------------*/		
.manual{
	background-image: url('../pic/manual-mobi.jpg');
	background-color: #fff;
	background-size: auto 100%;
   background-repeat: no-repeat;
   background-position: 100% 0;
	padding: 24px 22px 22px;
	position: relative;
	&__box{width: 170px;}
	&__item{
		padding-right: 10px;
		margin-bottom: 12px;
	}
	&__title{
		color: $accent-color;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.5;
		margin-left: 10px;
		margin-bottom: 2px;
	}
	&__text{
		color: #444;
		font-size: 13px;
		font-weight: 300;
		line-height: 1.23;
		margin-left: 10px;
	}
	&__count{
		color: #c2c2c2;
		font-size: 16px;
		font-weight: 300;
		line-height: 1;
	}

	@media only screen and #{$d-mobile-hzl} {
		padding-left: 94px;
		background-image: url('../pic/manual.jpg');
	}
	@media only screen and #{$d-tablet} {
		padding-left: 27px;
	}
	@media only screen and #{$d-tablet-hzl} {
		background-image: url('../pic/manual-mobi.jpg');
		padding-left: 22px;
		&--desktop{
			background-image: url('../pic/manual.jpg');
			.manual{
				&__box{
					width: 700px;
    				margin: 0 auto;
				}
				&__title{
					width: 100%;
				   margin-bottom: 19px;
				   text-align: center;
				}
				&__item{margin-bottom: 24px;}
				&__title{font-size: 20px;}
				&__count{font-size: 20px;}
				&__text{font-size: 15px;}
			}
		}
	}
}

/* -------------- advantages --------------*/		
.advantages{ 
	background-color: #fff;
	padding: 4px 28px 11px 24px;
	&__item{
		min-height: 57px;
		display: flex;
		align-items: center;
		border-top: 1px solid #e6e7ea;
		padding: 10px 0;
		&:first-child{
			border-top: 0;
		}
	}
	&__text{
		color: #888;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.2;
	}
	&__icon{
		display: block;
		&-box{
			width: 35px;
			flex-shrink: 0;
		}

		&--original{
			@include sprite($original-icon);
			&-white{@include sprite($original-icon);}
		}
		&--tag{
			@include sprite($tag-icon);
			&-white{@include sprite($tag-icon);}
		}
		&--click{
			@include sprite($click-gesture-icon);
			&-white{@include sprite($click-gesture-icon);}
		}
		&--payment{
			@include sprite($payment-method-icon);
			&-white{@include sprite($payment-method-icon);}
		}

		@media only screen and #{$retina} {
			&--original{
		      background-image: url($original-icon-2x-image);
		      background-size: $original-icon-2x-total-width / 2 $original-icon-2x-total-height / 2;
		      &-white{
					background-image: url($original-icon-2x-image);
		      	background-size: $original-icon-2x-total-width / 2 $original-icon-2x-total-height / 2;
		      }
		   }
		   &--tag{
		      background-image: url($tag-icon-2x-image);
		      background-size: $tag-icon-2x-total-width / 2 $tag-icon-2x-total-height / 2;
		      &-white{
		      	background-image: url($tag-icon-2x-image);
		      	background-size: $tag-icon-2x-total-width / 2 $tag-icon-2x-total-height / 2;
		      }
		   }
		   &--click{
		      background-image: url($click-gesture-icon-2x-image);
		      background-size: $click-gesture-icon-2x-total-width / 2 $click-gesture-icon-2x-total-height / 2;
		      &-white{
		      	background-image: url($click-gesture-icon-2x-image);
		      	background-size: $click-gesture-icon-2x-total-width / 2 $click-gesture-icon-2x-total-height / 2;
		      }
		   }
		   &--payment{
		      background-image: url($payment-method-icon-2x-image);
		      background-size: $payment-method-icon-2x-total-width / 2 $payment-method-icon-2x-total-height / 2;
		      &-white{
		      	background-image: url($payment-method-icon-2x-image);
		      	background-size: $payment-method-icon-2x-total-width / 2 $payment-method-icon-2x-total-height / 2;
		      }
		   }
		}

	}

	@media only screen and #{$d-mobile-hzl} {
		display: flex;
		flex-wrap: wrap;
		padding-top: 12px;
		padding-bottom: 14px;
		&__item{
			width: calc(50% - 10px);
			min-height: 75px;
			margin-right: 10px;
			&:nth-child(2){border-top: 0;}
			&:nth-child(2n){
				margin-right: 0;
				margin-left: 10px;
			}
		}
	}

	@media only screen and #{$d-tablet} {
		&__item{
			&:nth-child(2n){margin-left: 0;}
		}
		&--default{
			padding-left: 28px;
			padding-right: 32px;
			.advantages__item{
				width: 100%;
				margin-right: 10px;
				&:nth-child(2){border-top: 1px solid #e6e7ea;}
			}
		}
	}
	@media only screen and #{$d-tablet-hzl} {
		width: 100%;
		padding: 0;
		&__item,
		&--default .advantages__item{
			width: 25%;
			justify-content: center;
			border: 0;
			border-right: 1px solid #e6e7ea; 
			margin-right: 0;
			&:nth-child(2n){
				border: 0;
				border-right: 1px solid #e6e7ea; 
			}
			&:last-child{border: 0;}
		}
	}

}

/* -------------- up --------------*/
.up{
	position: relative;
	display: block;
	width: 42px;
   height: 41px;
   background-image: linear-gradient(9deg, #2f73fc 0%, #1c48a2 100%);
   box-shadow: 0 5px 20px rgba(20, 57, 133, 0.4);
   border-radius: 50%;
   text-decoration: none;
   margin: 0 auto;
   &:before{
   	content: '\e80a';
		font-family: 'fontello';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		color: #b8c3da;
		font-size: 30px;
		display: inline-block;
		line-height: 1;
   }
   &:hover,
   &:active{
   	&:before{color: #fff;}
   }
}

/* -------------- adress --------------*/
.adress{
	&__mail{
		display: inline-block;
		color: $accent-color;
		font-size: 16px;
		font-weight: 600;
		line-height: 1.125;
		text-decoration: none;
		margin-bottom: 18px;
		&:hover,
		&:active{
			color: $main-color;
		}
	}
	&__text{
		color: $accent-color;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.285;
	}

	@media only screen and #{$d-tablet} {
		&__mail{margin-bottom: 22px;}
	}
}

/* -------------- support --------------*/		
.support{
	&__number{
		display: block;
		color: $accent-color;
		font-size: 24px;
		font-weight: 900;
		line-height: 1.5;
		text-decoration: none;
		margin-bottom: 8px;
		&:hover,
		&:active{
			color: $main-color;
		}
	}
	&__text{
		color: $accent-color;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.285;
	}
}

/* -------------- pay-card --------------*/		
.pay-card{ 
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	&__icon{
		display: block;
		text-decoration: none;

		&:hover,
		&:focus{
			text-decoration: none;
			opacity: .8;
		}
		&:active{
			opacity: .5;
		}
	
		&--mcard{
			@include sprite($mastercard-icon);
		}
		&--visa{
			@include sprite($visa-icon);
		}
		&--wallet{
			@include sprite($wallet-icon);
		}

		@media only screen and #{$retina} {
		   &--mcard{
		      background-image: url($mastercard-icon-2x-image);
		      background-size: $mastercard-icon-2x-total-width / 2 $mastercard-icon-2x-total-height / 2;
		    }
		    &--visa{
		      background-image: url($visa-icon-2x-image);
		      background-size: $visa-icon-2x-total-width / 2 $visa-icon-2x-total-height / 2;
		    }
		    &--wallet{
		      background-image: url($wallet-icon-2x-image);
		      background-size: $wallet-icon-2x-total-width / 2 $wallet-icon-2x-total-height / 2;
		   }
		}

	}
	&__text{
		color: $accent-color;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.2;
		margin-bottom: 13px;
	}
	&__w-176{width: 176px;}
	&--w-107{width: 107px;}

	&--default{
		margin: 0;
		.pay-card{
			&__icon{
				&--mcard{
					@include sprite($mastercard-default-icon);
				}
				&--visa{
					@include sprite($visa-default-icon);
				}
				&--wallet{
					@include sprite($wallet-default-icon);
				}

				@media only screen and #{$retina} {
				   &--mcard{
				      background-image: url($mastercard-default-icon-2x-image);
				      background-size: $mastercard-default-icon-2x-total-width / 2 $mastercard-default-icon-2x-total-height / 2;
				    }
				    &--visa{
				      background-image: url($visa-default-icon-2x-image);
				      background-size: $visa-default-icon-2x-total-width / 2 $visa-default-icon-2x-total-height / 2;
				    }
				    &--wallet{
				      background-image: url($wallet-default-icon-2x-image);
				      background-size: $wallet-default-icon-2x-total-width / 2 $wallet-default-icon-2x-total-height / 2;
				   }
				}

			}
		}
	}
}
/* -------------- pay-content --------------*/		
.pay-content{
	&__text{margin-bottom: 13px;}
}

/* -------------- offer-call --------------*/		
.offer-call{
	padding: 22px 0 3px;
	background: linear-gradient(9deg, #4d51ad 0%, #2f5da2 100%);
	&__header{
		position: relative;
	}
	&__main,
	&__footer{ 
		width: 100%;
		margin: 0 auto; 
		padding-left: 17px;
		padding-right: 17px;
	}
	&__main{
		padding-bottom: 36px;
	}
	&__footer{
		padding-bottom: 42px;
	}
	&__title{
		max-width: 300px;
		margin: 0 auto 18px auto;
		padding-bottom: 25px;
		color: #fff;
		font-size: 21px;
		font-weight: 900;
		line-height: 1.428;
		text-transform: uppercase;
		position: relative;
    	z-index: 2;
		letter-spacing: 1px;
		text-align: center;
		&:after{
			content: '';
			position: absolute;
			display: block;
			background-color: #fff;
			width: 89px;
			height: 2px;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&__text{
		width: 100%;
		max-width: 360px;
		margin: 0 auto;
		color: #fff;
		font-size: 16px;
		font-weight: 300;
		line-height: 24px;
		&--center{text-align: center;}
		&--margin{
			margin-bottom: 47px;
		}
	}
	&__operator{
		text-align: center;
		padding: 0 30px;
	}
	&__operator-icon{
		margin: 0 auto 15px auto;
		display: block;
		@include sprite($operator-icon);
		@media only screen and #{$retina} {
			background-image: url($operator-icon-2x-image);
      	background-size: $operator-icon-2x-total-width / 2 $operator-icon-2x-total-height / 2;
		}
	}
	&__operator-text{
		color: #fff;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.5;
	}
	&__input-box{
		margin-bottom: 20px;
		&:last-of-type{
			margin-bottom: 9px;
		}
	}

	&__input{
		margin-bottom: 18px;
	}

	@media only screen and #{$d-mobile-hzl} {
		&__main{
			padding-left: 36px;
			padding-right: 36px;
		}
		&__text{
			&--margin{margin-bottom: 32px;}
		}
		&__input-box:last-of-type{margin-bottom: 37px;}
	}

	@media only screen and #{$d-tablet} {
		padding-top: 57px;
	}

	@media only screen and #{$d-desktop} {
		padding-top: 46px;
		&__main{
			padding-left: 42px;
			padding-right: 42px;
		}
		&__footer{padding-bottom: 32px;}
		&__input-box:last-of-type{margin-bottom: 10px;}
	}

}

/* -------------- modal --------------*/		
.modal{
	-webkit-overflow-scrolling: touch;
	&__close{display: none;}
	&--offer-call{
		.modal-dialog{
			width: 100%;
			margin: 0 auto;
		}
		.modal__close{display: none;}
	}
	&--cart{
		.modal__close{display: none;}
		.modal-content{
			background-color: #fff;
		}
	}
	&--city{
		.modal__close{display: none;}
		.modal-content{
			padding-left: 10px;
			padding-right: 10px;
		}
	}

	&--geo{
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		right: auto;
		bottom: auto;
		.modal-dialog{
			width: 320px;
			margin-top: 0;
			background-color: #fff;
		}
		.modal__close{
			display: block;
			position: absolute;
			top: 19px;
			right: 28px;
			z-index: 100;
		}
	}

	&--agreement{
	   .modal-dialog{
	    	max-width: 700px;
	   }
	 }
   &--default-offer{
      .modal-content{display: none;}
   }

	&-content{
		// background-color: #f8fcfe;
	}

	.modal-dialog{margin-top: 36px;}


	@media only screen and (min-width: 701px){
		&--agreement{
			.modal-dialog{top: 10%;}
			.modal-content{padding-top: 0;}
			.close-modal-box{display: none;}
		}
	}

	@media only screen and #{$d-tablet} {
		&--offer-call{
			.modal-dialog{
				width: 570px;
			}
			.modal-content{padding-top: 0;}
			.close-modal-box{display: none;}
			.modal__close{
				display: block;
				position: absolute;
				top: 21px;
				right: -46px;
			}
		}
		&--cart{
			.modal-dialog{
				width: 567px;
				margin: 0 auto;
			}
			.modal-content{padding-top: 0;}
			.close-modal-box{display: none;}
			.modal__close{
				display: block;
				position: absolute;
				top: 21px;
				right: -46px;
			}
		}
		&--city{
			.modal-dialog{
				width: 569px;
				margin: 0 auto;
			}
			.modal-content{padding-top: 0;}
			.close-modal-box{display: none;}
			.modal__close{
				display: block;
				position: absolute;
				top: 21px;
				right: -46px;
			}
		}

		&--geo{
			top: 50%;
			transform: translate(-50%,-50%);
		}
	}

	@media only screen and (min-width: 820px){
		&__close{
			&--agreement{
				display: block;
				position: absolute;
				right: -58px;
    			top: 5px;
			}
		}
	}

	@media only screen and #{$tablet-hzl} {
		&--agreement{
	   .modal-dialog{
	    	max-width: 700px;
	   }
	 }
	}

	@media only screen and #{$d-tablet-hzl} {
		&__close{ //ok
	      position: absolute;
	      top: 57px;
	   	right: 0;
	      &--agreement{
				right: -58px;
    			top: 5px;
			}
	   }

		&--geo{
			position: absolute;
			top: 128px;
			left: 0;
			transform: none;
		}


		&--agreement{
		   .modal-dialog{
		   	top: 10%;
		    	max-width: 700px;
		   }
		 }
		&--default{
	      .modal-dialog{
	        max-width: 936px;
	        margin: 0 auto;
	        margin-top: 0;
	        box-sizing: border-box;
	        padding: 0 80px;
	      }
	      .modal-content{
	        max-width: 936px;
	        margin: 0 auto;
	      }
	   }

	}

	@media only screen and #{$desktop-small} {
		&--default{
	      .modal-dialog{
	        box-sizing: content-box;
	      }
	   }
	}

	@media only screen and #{$d-desktop} {
		&__close{display: block;}
		&--offer-call{
			.modal-dialog{
				width: 516px;
			}
		}
		&--cart,
		&--city{
			.modal-dialog{
				width: 507px;
			}
		}


		&--default-large{
		    .modal-dialog{max-width: 1024px;}
		    .modal-content{max-width: 1024px;}
		}
	
		&--default-offer{
	      .modal-dialog{
	        max-width: 1093px;
	        top: 10%;    
	        padding: 60px 0 0 0;
	      }
	      .modal-content{
	        display: block;
	        max-width: 1093px;
	        max-height: auto;
    		 overflow-y: visible;
	        margin: 0 auto;
	        padding: 0;
	      }
	      .modal__close{
	        top: 2px;
	        right: -43px;
	      }
	    }
	}

}

/* -------------- close --------------*/		
.close{
	display: inline-block;
	width: 24px;
	height: 24px;
	background: url('../img/svg/close.svg') center center no-repeat;
	&--small-blue{
		width: 16px;
		height: 16px;
		background: url('../img/svg/close-small-blue.svg') center center no-repeat;
	}
	&--small-dark{
		width: 15px;
		height: 15px;
		background: url('../img/svg/close-small-dark.svg') center center no-repeat;
	}
	&--dark-blue{
		background: url('../img/svg/close-small-blue.svg') center center no-repeat;
	}
	&:hover{
		opacity: .7;
	}

	&-modal{
		position: relative;
		display: flex;
		justify-content: center;
    	align-items: center;
    	background-color: #fff;
    	box-shadow: 0 4px 35px rgba(0, 0, 0, 0.2);
		padding: 7px 0 8px;
		text-decoration: none;
		z-index: 100;
		&:hover,
		&:active{
			background-color: #90908f;
		}
		&__text{ //ok
			color: #000;
			font-size: 14px;
			font-weight: 300;
			line-height: 1.5;
			margin-right: 9px;
		}
		.modal{position: relative;}

		&--fixed{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
		}
		&--fix{
			position: fixed;
			width: 100%;
			padding: 3px 0;
		}
		&--absolute{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
		&--agreement{
			position: fixed;
			top: 0;
			width: 100%;
			padding: 3px 0;
		}

		&-box{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
		}
	}
	&-mini{
		position: absolute;
		top: 1px;
    	right: 1px;
		z-index: 3;
		box-shadow: -5.2px 6px 0 rgba(0, 0, 0, 0.19);
		padding: 14px;
		background-color: #fff;
		&:hover,
		&:active{
			box-shadow: 0.6px 4px 10px rgba(0, 0, 0, 0.8);
		}
		.close{
			display: block;
			margin: 0 auto;
		}
	}

	@media only screen and (min-width: 463px) {
		&-modal{
			&--fix{
				width: 463px;
			}
		}
	}

	@media only screen and #{$tablet-hzl} {
		&-modal{
			&--fix{
				width: 480px;
			}
		}
	}

	@media only screen and #{$laptops} {
		&-modal{display: none;} 
	}

	@media only screen and #{$desktop-small} {
		&-mini{
			&--hidden-desktop{display: none!important;}
		}
	}

}

.sleek-input{ 
	&__label-text{ 
		color: #fff;
		font-size: 12px;
		font-weight: 100;
		line-height: 14px;
		cursor: text;
		text-align: center;
		text-transform: uppercase;
		transform: translateY(-24px);
		transition: all 0.3s;
		&--default{
			color: #fff;
			font-size: 18px;
			font-weight: 600;
			line-height: 18px;
			text-transform: none;
		}
	}
	&__form{ // ok
		position: relative;
    	z-index: 2;
		width: 100%;
		background-color: transparent;
    	border: 0;
    	border-radius: 1px;
      border-bottom: 2px solid #fff;
    	color: white;
    	font-size: 17px;
    	line-height: 1.4;
    	outline: 0;
    	padding: 5px 20px;
    	text-align: center;
    	transition: all 0.3s;
    	&:focus{
    		width: 100%;
    		border-color: rgba(255, 255, 255, 0.84);
    		+ .sleek-input__label-text{
    			color: rgba(255, 255, 255, 0.65);
	         font-size: 10px;
	         transform: translateY(-48px);
    		}
    	}
    	&.sleek-input__form--value-exists{
			border-color: rgba(255, 255, 255, 0.84);

    		+ .sleek-input__label-text{
    			color: rgba(255, 255, 255, 0.65);
				font-size: 10px;
		      transform: translateY(-48px);
	    	}
    	} 
	}

	&--default{ 
		.sleek-input__form{
			width: 100%;
			text-align: left;
			padding-left: 9px;
			padding-right: 9px;
		}
		.sleek-input__label-text{
			text-align: left;
		}
	}
	&--default-desktop{
		.sleek-input__label-text{text-align: left;}
		@media only screen and #{$d-tablet-hzl} {
			.sleek-input__form{
				width: 100%;
			}
		}
		@media only screen and #{$d-desktop} {
			.sleek-input__form{
				text-align: center;
				padding-left: 9px;
				padding-right: 9px;
			}
			.sleek-input__label-text{
				text-align: center;
			}
		}
	}
	&--card{ 
		display: block;
		-ms-flex: 0 1 auto;
		flex-grow: 1;
		.sleek-input__form{
			border-color: #fff;
			padding-left: 3px;
			padding-right: 3px;
		}
		.sleek-input__label-text{
			color: #fff;
			font-size: 12px;
			font-weight: 600;
			line-height: 12px;
		}
	}
	&--dark{ // ok
		.sleek-input__form{
			border-color: #999;
			color: #000;
			&:focus + .sleek-input__label-text{
				color: rgba(0, 0, 0, 0.65);
			}
			&.sleek-input__form--value-exists + .sleek-input__label-text{
				color: rgba(0, 0, 0, 0.65);
			}
		}
		.sleek-input__label-text{
			transform: translateY(-34px);
			color: #000;
			font-size: 16px;
			font-weight: 700;
			text-transform: none;
		}
	}
	&--small-text{ // ok
		.sleek-input__label-text{
			font-size: 14px;
			font-weight: 100;
		}
	}
	&--captcha{
		.sleek-input__form{
			display: inline-block;
		   width: auto;
		   padding-left: 0;
		   padding-right: 0;
		}
		.sleek-input__label-text{
			text-align: center;
			transform: translateY(-23px);
		}
		.sleek-input__form:focus + .sleek-input__label-text,
		.sleek-input__form.sleek-input__form--value-exists + .sleek-input__label-text{
			transform: translateY(-41px);
		}

	}

	&--offer{
		.sleek-input{
			&__label-text{transform: translateY(-35px);}
			&__form{text-align: left;}
		}
	}

}

/* -------------- cart --------------*/		
.cart{
	background-color: #fff;
	&__header{
		text-align: center;
		padding: 36px 0 18px;
		max-width: 520px;
		margin: 0 auto;
	}
	&__title{
		color: #000;
		font-size: 24px;
		font-weight: 600;
		line-height: 1;
		display: block;
	   max-width: 120px;
	   margin: 0 auto;
		margin-bottom: 12px;
	}
	&__text{
		color: #888;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.4;
		max-width: 280px;
		margin: 0 auto;
	}

	@media only screen and #{$d-mobile-hzl} {
		&__title,
		&__text{
			max-width: 100%;
		}
		&__header{
			max-width: 280px;
			padding-bottom: 42px;
		}
	}

	@media only screen and #{$d-tablet} {
		&__header{
			max-width: 365px;
			padding-top: 40px;
		}
	}

	@media only screen and #{$d-tablet-hzl} {
		&__header{padding-top: 36px;}
		&__text{max-width: 330px;}
	}

	@media only screen and #{$d-desktop} {
		&__header{padding-bottom: 14px;}
		&__title{ max-width: 120px;}
	}


	&-list{
		width: 100%;
	   margin-bottom: 26px;
	   display: table;
	   &__row{
	   	min-height: 97px;
	   	position: relative;
	   	padding: 4px 10px;
			background-color: #f8f5f5;
			display: flex;
			align-items: center;
			margin-bottom: 9px;
			padding-right: 38px;
	   }

	   &__cell{
	   	-ms-flex: 0 1 auto;
	      &:first-child{
	        display: flex;
	        align-items: center;
	        flex-shrink: 0;
	        padding: 0 22px 0 2px;
	      }
	      &:nth-child(2){
				overflow: hidden;
	      }
	      &:nth-child(3){
	      	width: 45px;
	      	position: absolute;
			   top: 0;
			   bottom: 0;
			   right: 0;
	      }
	   }

	   &__close{
	   	position: absolute;
	   	top: 50%;
	   	right: 10px;
	   	transform: translateY(-50%);
	   }

		&__product-img{
			max-height: 80px;
			max-width: 90px;
		}
		&__product-name{
			display: block;
			color: $accent-color;
			font-size: 13px;
			font-weight: 600;
			line-height: 1.153;
			text-decoration: none;
			margin-bottom: 5px;
			&:hover,
			&:active{
				color: $main-color;
			}
		}
		&__product-code{
			color: #888;
			font-size: 13px;
			font-weight: 300;
			line-height: 1.153;
		}

		@media only screen and #{$d-mobile-hzl} {
			&__row{padding-right: 50px;}
			&__cell{
				&:first-child{
					padding: 0 29px 0 6px;
				}
				&:nth-child(3){
		      	width: 66px;
		      }
			}
			&__close{
				right: 26px;
				&__row{padding-right: 60px;}
				&__cell{
					width: 86px;
				}
			}
		}

		@media only screen and #{$d-desktop} {
			&__cell{
				&:first-child{padding-left: 26px;}
			}
		}

	}

}

/* -------------- product-form --------------*/		
.product-form{
	width: 267px;
	margin: 0 auto;
	&__box{
		margin-bottom: 12px;
		text-align: center;
		&--tel{
			display: flex;
			margin-bottom: 3px;
			.form-control--select,
			.sleek-input__form{
				height: 35px;
			}
		}
	}
	&__title{
		color: #fff;
		font-size: 18px;
		font-weight: 900;
		line-height: 1.125;
		text-transform: uppercase;
		margin-bottom: 30px;
		letter-spacing: 1px;
	}
	&__label{
		display: block;
		color: #000;
		font-size: 14px;
		font-weight: 300;
		margin-bottom: 5px;
	}
	&__agreement{
		&-user{
			margin-bottom: 34px;
			text-align: left;
		}
		&-text{
			color: #fff;
			font-size: 12px;
			font-weight: 300;
			line-height: 1.285;
			margin-top: 32px;
		}
		&-link{
			display: inline-block;
			color: #fff;
			font-size: 12px;
			font-weight: 300;
			line-height: 1.285;
			&:hover{
				text-decoration: none;
				color: #f42a4b;
			}
		}
	}
	&__statistics{
		
		&-text{
			font-size: 12px;
			color: #000;
			font-weight: 300;
			line-height: 1.5;
		}
		&-value{
			color: $main-color;
			font-weight: 300;
		}
	}

	&--card{
		width: 100%;
		max-width: 270px;
		.product-form{
			&__title{
				max-width: 170px;
				margin: 0 auto 27px auto;
				color: #fff;
				font-size: 18px;
				font-weight: 900;
				line-height: 1.5;
				text-transform: uppercase;
			}
		}
	}

	@media only screen and (max-width: 390px) { 
		&__agreement-link{
			&--check{
				margin-top: 0;
				display: inherit;
			}
		}
		&__checkbox-box{
			.checkbox__text:before{top: 7px;}
			.checkbox__text:after{top: 8px;}
		}
	}

	@media only screen and #{$d-tablet} {
		width: 410px;
		&__box{
			&-2-item{
				display: flex;
				justify-content: space-between;
				.product-form__box{width: 48%;}
			}
		}
	}

	@media only screen and #{$d-tablet} and #{$d-tablet-hzl-max} {
		&--card{
			max-width: 430px;
			.product-form{
				&__content{
					width: 316px;
					margin-left: auto;
					margin-right: auto;
				}
				&__title{min-width: 100%;}
				&__box-2-item{width: 85%;}
				&__btn{max-width: 235px;}
			}
		}
	}

	@media only screen and #{$tablet-hzl} {
		&__title{font-weight: 700;}

	}
	@media only screen and #{$d-desktop} {
		&--card{
			 max-width: 234px;
			 .checkbox__text{padding-top: 0;}
			.product-form__title{
				margin-top: 2px;
				margin-bottom: 21px;
			}
		}
	}
}

/* -------------- city-list --------------*/		
.city-list{
	padding-top: 5px;
	&__box{ 
		margin-bottom: 62px;
		padding: 0 3px;
		&--center{
			text-align: center;
		}
	}
	&__title{//ok
	   display: block;
	   max-width: 230px;
	   margin: 0 auto;
		margin-bottom: 27px;
		color: #000;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.2;
	}
	&__btn{
		padding-top: 17px;
		padding-bottom: 17px;
	}
	&__search{
		&-input{margin-bottom: 15px;}
	}
	&__flag-icon{
		width: 23px;
		height: 15px;
		margin-right: 1px;
	}
	&__content{
		padding: 0 3px;
		&-title{
			color: #222;
			font-size: 14px;
			font-weight: 700;
			line-height: 1;
			text-transform: uppercase;
			margin-bottom: 27px;
		}
		p{
			
		}
		a{
			color: #222;
			font-size: 14px;
			font-weight: 300;
			line-height: 1.6;
			text-decoration: none;
			&:hover{
				color: $main-color;
			}
		}
	}

	@media only screen and #{$d-mobile-hzl} {
		width: 470px;
		margin: 0 auto;
	}

	@media only screen and #{$d-tablet} {
		padding-top: 42px;
	}

	@media only screen and #{$d-tablet-hzl} {
	    &__box{
	      margin-bottom: 40px;
	      &--center{margin-bottom: 40px;}
	      &--popular{margin-bottom: 0;}
	    }
	}

	@media only screen and #{$d-desktop} {
		width: 423px;
	}

}

/* -------------- product-gallery --------------*/		
.product-gallery { 
	//display: table;
	width: 100%;
	max-height: 283px!important;
	margin: 0 auto;
	overflow: visible;
	position: relative;
	transition: all .5s ease-out;

	&__thumb{
		margin: 13px 4px 13px 0px;
		overflow: hidden;
	}
	&-wrap{
		width: 302px;
		margin: 0 auto;
	}
	&-box{
		position: relative;
		max-width: 267px;
		width: 100%;
		margin: 0 auto;
		img{
			max-height: 283px;
		}

		.lSSlideWrapper{
			height: 285px;
			border: 1px solid #f1f1f1;
			background-color: #fff;
		}

		.lSSlideOuter{
			// width: 267px;
			// height: 285px;
			.lSPager.lSpg{
				margin-bottom: 10px;
				&>li{
					padding: 0 2px;
					&.active a,
					&:hover a{
						background-color: $main-color;
					}
					a{
						width: 21px;
						height: 8px;
						background-color: #e2e2e2;
						border-radius: 4px;
					}
				}
			}
			 
			.lightSlider>*{
				// display: table-cell;
			   //vertical-align: middle;
			   //float: none;
			}
			&.vertical{
				padding-left: 78px;
				padding-right: 0!important;
				//overflow: visible;

				.lSGallery{position: relative!important;}
			}
		} 

		.lSPager.lSGallery{
			margin: 0!important;

		}
		// главная
		.lSPager.lSGallery li{
			width: auto!important;
			&:hover,
			&.active{
				border-radius: 0;
				a{
					border-color: $main-color;
				}
			}
		}
		.lSPager.lSGallery li a{
			display: block;
			width: 72px;
			height: 72px;
			overflow: hidden;
			border: 1px solid #f1f1f1;
    		margin-left: 13px;
		}

		.lSNext{
			width: 0;
			&:before{
				content: '\e806';
			   font-family: fontello;
			   font-size: 40px;
			   line-height: 1;
			   color: #d8d8d8;
			   position: relative;
			   right: 0;
			}
		}
		.lSPrev{
			width: 0;
			&:before{
				content: '\e805';
			   font-family: fontello;
			   font-size: 40px;
			   line-height: 1;
			   color: #d8d8d8;
			   position: relative;
			   left: 0;
			}
		}
		.lSAction>.lSNext{right: 33px;}
		.lSAction>.lSPrev{left: 16px;}
		.lSAction>a{margin-top: 17px;}
	}

	@media only screen and #{$d-tablet} {
		&-wrap{width: 345px;}
		&-box{
			width: 345px;
			max-width: 100%;
			margin: 0 0 9px;
			.lSPager.lSGallery{
				li{
					//height: 80px!important;
					a{
						margin-left: 0;
					}
				}
			}
		}
		&__thumb{
			position: absolute;
			top: 0;
			left: 0;
			margin: 0;
		}
	}
} 

/* -------------- countdown --------------*/		
.countdown{ 
	width: 100%;
	color: #000;

	&--default{
		max-width: 302px;
		padding: 14px;
		margin: 0 auto;
		background-color: #fff;
		border: 1px solid #f3f3f3;
	}
	&__timer{
		display: table;
		width: 200px;
		margin: 0 auto;
	}
	&__title{
		color: #000;
		font-size: 10px;
		font-weight: 900;
		line-height: 1.8;
		text-transform: uppercase;
		margin-bottom: 7px;
	}
	&__item{
		position: relative;
		display: table-cell;
		width: 33.333%;
		&--text{
			width: auto;
			padding-left: 22px;
			padding-right: 5px;
			border: 0;
			&:before{
				content: none;
			}
		}
	}
	&__text{
		color: #000;
		font-size: 10px;
		font-weight: 300;
		line-height: 1;
		text-transform: uppercase;
	}
	&__hours,
	&__minutes,
	&__seconds{
		display: block;
		color: #1c48a2;
		font-size: 32px;
		font-weight: 900;
		line-height: 1;
		text-transform: uppercase;
	}
	&__hours,
	&__minutes{
		position: relative;
		&:after{
			content: ':';
			display: block;
			line-height: 1;
			position: absolute;
			top: -2px;
			right: -4px;
		}
	}

	@media only screen and #{$d-tablet} {
		&--default{
			max-width: 100%;
    		padding: 11px 18px;
    		display: flex;
    		align-items: center;
    		.countdown{
    			&__title{
    				-ms-flex: 0 1 auto;
    				margin: 0;
    				text-align: left;
    			}
				&__timer{
					flex-shrink: 0;
				}
    		}
		}
	}
}

/* -------------- product-count --------------*/		
.product-count{
	display: flex;
	align-items: baseline;
	&__text{
		color: #000;
		font-size: 11px;
		font-weight: 900;
		line-height: 1;
		text-transform: uppercase;
		letter-spacing: 1px;
	}
	&__list{
		display: flex;
		flex-direction: column-reverse;
		margin-left: 6px;
	}
	&__item{
		display: block;
		width: 8px;
		height: 6px;
		background-color: #e2b3b3;
		margin-top: 1px;
		&--active{background-color: #9e0202;}
	}
	&--center{
		justify-content: center;
	}
	@media only screen and #{$d-tablet} {
		&--center{
			justify-content: flex-start;
		}
	}
}


/* -------------- breadcrumb --------------*/		
.breadcrumb{ 
	list-style: none;
	margin: 0;
	li{
		position: relative;
		display: inline;
		color: #70757f;
		font-size: 14px;
		font-weight: 100;
		line-height: 1.285;
		padding-right: 15px;
		&::after{
			content: '';
			display: block;
			position: absolute;
			right: 4px;
			top: 50%;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background-color: #245bc9;
		}
		&:last-child{
			&::after{content: none;}
		}
		a{
			color: $main-color;
			&:hover{
				color: #000;
				text-decoration: none;
			}
		}
	}
}

/* -------------- delivery --------------*/		
.delivery{
	text-align: center;
	picture:first-child{
		.delivery__img{margin-left: 0;}
	}
	&__img{
		margin-left: 7px;
		&-box{margin-bottom: 10px;}
	}
	&__text{
		opacity: 0.67;
		color: #000;
		font-size: 12px;
		font-weight: 300;
		line-height: 1.3333;
	}
	&__city{font-weight: 700;}
}
/* -------------- product-discount --------------*/		
.product-discount{
	position: absolute;
	top: 1px;
   right: 1px;
	width: 86px;
	height: 51px;
	display: inline-block;
	text-align: center;
	background-color: #0e26b7;
background-image: linear-gradient(9deg, #2f73fc 0%, #1c48a2 100%);
border-radius: 0px 0px 0px 10px;
box-shadow: 0 5px 20px rgba(20, 57, 133, 0.4);
	line-height: 1;
	&__content{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	&__text{
		color: #fff;
		font-size: 10px;
		font-weight: 300;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 2px;
	}
	&__value{
		color: #fff;
		font-size: 24px;
		font-weight: 600;
		line-height: 1;
	}
	&--modal{
		top: -27px;
    	right: 36px;
	}
}

/* -------------- checkbox --------------*/		
.checkbox{
	position: absolute;
	width: 19px;
   height: 19px;
	z-index: 2;
	opacity: 0;
	left: 0;
	top: 0;
	&:hover{
		cursor: pointer;
	}
	&__text{
		display: inline-flex;
		flex-wrap: wrap;
		position: relative;
		padding-left: 34px;
		color: #fff;
		font-size: 14px;
		font-weight: 100;
		line-height: 1.166;
		&:before{
			content: '';
			display: block;
			position: absolute;
		    top: -2px;
		    left: 0;
		    width: 19px;
		    height: 19px;
		    opacity: 1;
		    background-color: transparent;
		    border: 1px solid #fff;
		    border-radius: 0;
		}
		&--decor{
			&:before{
				@include sprite($checkbox-icon);
				border: 0;
				@media only screen and #{$retina} {
				   background-image: url($checkbox-icon-2x-image);
				   background-size: $checkbox-icon-2x-total-width / 2 $checkbox-icon-2x-total-height / 2;
				}
			}
		}
	}
	&:checked{
		+ .checkbox__text:after{
			content: url('../img/svg/check_ico.svg');
		   position: absolute;
		   opacity: 1;
		   left: 3px;
		   width: 13px;
		   height: 14px;
		}
		+ .checkbox__text--decor:after{
			content: '';
			@include sprite($checkbox-check-icon);
			left: 0;
			@media only screen and #{$retina} {
				background-image: url($checkbox-check-icon-2x-image);
				background-size: $checkbox-check-icon-2x-total-width / 2 $checkbox-check-icon-2x-total-height / 2;
			}
		}
	}

	&-box{
		position: relative;
		display: inline-block;
		&--card{
			.checkbox{
				width: 23px;
				height: 23px;
				top: 5px;

			}
			.checkbox__text:before{
				top: 3px;
			}
			.checkbox:checked + .checkbox__text:after{
				position: absolute;
				top: 3px;
			}
		}
	}

	@media only screen and #{$d-tablet} {
		&-box{
			&--card{
				.checkbox{
					&__text{padding-top: 7px;}
				}
			}
		}
	}

	@media only screen and #{$d-desktop} {
		&-box{
			&--card{
				.product-form__agreement-link--check{display: block;}
			}
		}
	}
}



/* -------------- tab-description --------------*/		
.tab-description{
	color: #333;
	&__title{
		line-height: 1.3333;
		font-size: 14px;
		font-weight: 900;
		margin-bottom: 27px;
		text-transform: uppercase;
	}
	&__text{
		font-size: 14px;
		font-weight: 300;
		line-height: 1.7;
		&--margin{margin-bottom: 20px;}
	}
	&__item{
		font-size: 15px;
		font-weight: 600;
		line-height: 1.7;
	}
	&__list{margin-bottom: 25px;}
	&__box{margin-bottom: 25px;}

	&--margin{margin-bottom: 36px;}

	p,
	span,
	a{
		font-size: 14px;
		font-weight: 300;
		line-height: 1.7;
		margin-bottom: 20px;
	}
	span{margin-bottom: 0;}
	a{
		color: #333;
		margin-bottom: 0;
		&:hover,
		&:active{
			text-decoration: none;
			color: $main-color;
		}
	}

	h1, h2, h3, h4, h5, h6{
		line-height: 1.3333;
		font-size: 14px;
		font-weight: 900;
		margin-bottom: 27px;
		text-transform: uppercase;
	}

	ul{
		padding-left: 20px;
    	list-style-type: disc;
		margin-bottom: 20px;
	}
	li{
		color: #000;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.6;
	}

}

/* -------------- tab-characteristic --------------*/		
.tab-characteristic{
	color: #333;
	&__title{
		font-size: 14px;
		font-weight: 900;
		line-height: 1.7;
		margin-bottom: 24px;
		text-transform: uppercase;
	}
	&__text{
		font-size: 16px;
		font-weight: 300;
		line-height: 1.5;
		margin-bottom: 24px;
		&:last-of-type{margin-bottom: 12px;}
		&-cert{
			font-size: 12px;
			font-weight: 300;
			&--icon-rst{
				&:before{
					content: '';
					display: inline-block;
					vertical-align: middle;
					background: url(../img/rst.png) center center no-repeat;
					width: 16px;
					height: 12px;
					margin-right: 5px;
				}
			}
		}
	}
	&__bold{
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		margin-right: 5px;
		
	}
	&__pay-card{width: 142px;}

}

/* -------------- comment --------------*/		
.comment{
	background-color: #fff;
	padding-left: 10px;
	padding-top: 36px;
	padding-bottom: 34px;
	&__count{
		max-width: 100%;
		position: relative;
		overflow: hidden;
		color: #333;
		font-size: 14px;
		font-weight: 900;
		line-height: 1.714;
		text-transform: uppercase;
		&:after{
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			height: 1px;
			background: #ececec;
			margin-right: -100%;
			margin-left: 20px;
			margin-top: -6px;
			border-left: 6px solid #fff;
		}
	}
	&__item{
		position: relative;
		padding: 19px 10px 19px 22px;
		&:last-of-type{
			border: 0;
			margin-bottom: 20px;
			&:after{content: none;}
		}
		&:after{
			content: '';
			position: absolute;
			width: 1000%;
			bottom: 0;
			left: 0;
			right: 0;
			height: 1px;
			background-color: #efefef;
		}
		&--lv-2{
			padding-left: 71px;
		}
	}
	&__author{
		display: inline-block;
		color: #333;
		font-size: 14px;
		font-weight: 600;
		line-height: 1.714;
		margin-bottom: 0;
		margin-right: 30px;
	}
	&__text{
		color: #666;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.5;
	}
	&__quote{
		display: none;
		color: #4292ac;
		font-size: 12px;
		font-weight: 300;
		line-height: 1;
		text-decoration: underline;
		text-decoration-color: #d4e4f3; 
		&:hover,
		&:active{
			text-decoration: none;
		}
	}
	&__raiting{
		display: inline-block;
		margin-bottom: 5px;
		&-count{
			color: #888;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
		}
		&-val{
			color: #1b6bb2;
			font-size: 21px;
			font-weight: 700;
			line-height: 1;
			margin-left: 2px;
		}
	}
	&.comment--reviews{padding: 0;}
	&--reviews{
		overflow: hidden;
		.comment__item{
			max-width: 510px;
			padding: 22px 0 18px 20px;
			//margin-left: 11px;
		}
	}


	&-form{
		padding-right: 10px;
		&__wrap{
			padding-top: 35px;
		}
		&__title{
			@extend .comment__count;
			max-width: 100%;
		}
		&__input-box,
		&__in-box{margin-bottom: 6px;}
		&__btn-box{margin-top: 12px;}
		&__rating{
			margin: 20px 0;
			span{
				color: #505c72;
				font-size: 14px;
				font-weight: 300;
				line-height: 1.5;
				vertical-align: middle;
				margin-right: 10px;
			}
		}

		&--article{
			.comment-form__title{
				max-width: 100%;
				margin-bottom: 35px;
			}
			
		}
	}

	@media only screen and #{$d-tablet} {
		&__footer{
	      display: flex;
	      justify-content: space-between;
	      .captcha{
	          flex-shrink: 0;
	      }
	      .comment-form__btn-box{
	          width: 49%;
	          margin-top: 0;
	          flex-shrink: 0;
	      }
	    }
	    &--reviews .comment__item{
	    	max-width: 100%;
	    }
	    &-form{
	    	&__wrap{
				max-width: 735px;
			}
	    	&__rating-box{
	    		flex-shrink: 0;
	    		padding: 0 19px;
	    	}
	    	&__in-box{
	    		display: flex;
	    		align-items: center;
	    		justify-content: space-between;
	    		.form-control{-ms-flex: 0 1 auto;}
	    	}
	    	&__footer{
	    		display: flex;
	    		align-items: center;
	    		justify-content: space-between;
	    		.captcha{
	    			-ms-flex: 0 1 auto;
	          	width: 58%;
	    		}
	    		.comment-form__btn-box{width: 40%;}
	    	}
	    	&__btn-box{margin: 0;}
	    }
	}

	@media only screen and #{$d-tablet-hzl} {
		
	}

	@media only screen and #{$d-desktop} {
		&__rating{
	    text-align: center;
	    &-box{
	      width: 48%;
	    }
	  }
	}

}

.captcha{
	display: flex;
	.form-control{-ms-flex: 0 1 auto;}
}

/* -------------- tabs --------------*/
.tabs{
	&__content{
		padding: 0 10px;
		overflow: hidden;
		&-item{
			padding: 23px 0 84px;
			opacity: 0;
			display: none;
			animation: fadeInFromNone 0.8s ease-out;
			&--visible{
				opacity: 1;
				display: block;
			}
		}
	}
	&__manual{//ok
		background-color: #fff;
		border-radius: 10px;
		margin-bottom: 50px;
		margin-left: 10px;
		margin-right: 10px;
	}


	&-info{
		&__list{
			width: 100%;
			display: flex;
    		flex-wrap: wrap;
    		position: relative;
    		padding: 0 10px;
    		&:after{
    			content: '';
    			position: absolute;
    			width: 69px;
    			top: 0;
    			bottom: 0;
    			right: 0;
    			background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #eff0f2  50%);
    		}
    		.owl-carousel .owl-stage{
    			min-width: 1000%!important;
    		}
    		.owl-item{
    			&:last-child{
					.tabs-info__item:after{
						content: none;
					}
				}
			}
			.owl-next{ 
				position: absolute;
				z-index: 1;
				top: 19px;
				right: 11px;
				@include sprite($slider-arrow-next-icon);
				@media only screen and #{$retina} {
				   background-image: url($slider-arrow-next-icon-2x-image);
				   background-size: $slider-arrow-next-icon-2x-total-width / 2 $slider-arrow-next-icon-2x-total-height / 2;
				}
			}
			.owl-prev{
				position: absolute;
				z-index: 1;
				top: 19px;
				right: 25px;
				@include sprite($slider-arrow-prev-icon);
				@media only screen and #{$retina} {
				   background-image: url($slider-arrow-prev-icon-2x-image);
				   background-size: $slider-arrow-prev-icon-2x-total-width / 2 $slider-arrow-prev-icon-2x-total-height / 2;
				}
			}
    	}

		&__item{
			color: #000;
			font-size: 11px;
			font-weight: 900;
			line-height: 1.142;
			position: relative;
			width: 100%;
			padding: 22px 10px;
    		text-align: center;
    		text-transform: uppercase;
			&:hover{
				cursor: pointer;
				color: $main-color;
			}
		}
		&__content{
			height: 610px;
			overflow: auto;
			padding: 0;
			background-color: #fff;
			-webkit-overflow-scrolling: touch;
			&-item{
				padding: 35px 15px;
				&--0{
					padding: 0;
				}
			}

		}

		.tabs__item--active{//ok
			color: $main-color;
		}
	}

	&-map{
		position: relative;
		&__list{
			min-height: 472px;
			overflow: auto;
		}
		&__item{
			transition: all .3s ease-out;
			&-box{
				margin: 0 6%;
				padding: 18px 0;
				border-bottom: 1px solid #f6f6f9;
			}
			&-address{
				display: inline-block;
				font-size: 14px;
				font-weight: 100;
				line-height: 1.4;
				color: $main-color;
				border-bottom: 1px dashed #000;
				margin-bottom: 10px;
			}
			&-tel{
				font-size: 14px;
				font-weight: 100;
				line-height: 1.4;
				color: #222;
				text-decoration: none;
			}
			&:hover,
			&.tabs__item--active{
				text-decoration: none;
				background-color: #f3f2f0;
				cursor: pointer;
			}
		}
		&__content{
			padding-left: 0;
			padding-right: 0;
			&-item{
				position: absolute;
			   top: 0;
			   bottom: 0;
			   left: 0;
			   right: 0;
				height: 100%;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
		&__info{
			position: absolute;
			bottom: 13px;
			left: 20px;
			right: 20px;
			padding: 18px 23px;
			text-align: center;
		   z-index: 3;
		   background: #fff;
		   box-shadow: 0.6px 4px 10px rgba(0, 0, 0, 0.27);
		   &-text{
		   	color: #000;
				font-size: 14px;
				font-weight: 500;
				line-height: 1.4;
		   }
		}
		.ymaps-2-1-56-controls__toolbar_right{margin-right: 75px;}
	}

	@media only screen and #{$d-tablet} {
		&__manual{
			max-width: 548px;
			margin-left: auto;
			margin-right: auto;

		}
		&-info{
			&__list{width: 100%;}

			&__content{
				&-item{
					padding-left: 38px;
   				padding-right: 38px;
   				&--0{
   					padding: 0;
   				}
				}
			}
		}
	}

	@media only screen and #{$d-tablet-hzl} {
		&__manual{
			max-width: 100%;
			margin-left: 0;
			margin-right: 0;
			padding-bottom: 38px;
		}

		&-info{
			&__content{
   			&-item{
					padding-left: 32px;
   				padding-right: 32px;
   				&--0{
   					padding: 0;
   				}
				}
			}
			.owl-stage{margin: 0 auto;}
		}
	}

	@media only screen and #{$d-desktop} {
		&__item--disable{display: none;}

	   &-map{
	   	margin: 40px 0;
	   	display: flex;
	   	overflow: hidden;
	   	&__list{
	   		width: 282px;
	   		height: 372px;
	   		min-height: auto;
    			border: 1px solid rgba(42, 94, 165, 0.16);
	   		overflow: auto;
	   		-webkit-overflow-scrolling: touch;
	   	}
	   	&__item{

	   	}
	   	&__content{
	   		&-item{
	   			left: 282px;
	   			right: 35px;
	   		}
	   	}
	   }

		&-info{
			display: flex;
			align-items: flex-start;
			border: 2px solid #ebeef0;
	      &:after{
	        content: "";
	        display: table;
	        clear: both;
	      }
	      &__content{
	      	-ms-flex: 0 1 auto;
	      	width: 100%;
	      	height: 720px;
				&-item{
					padding: 40px 20px 40px 40px; 
					&--0{padding: 0;}
				}
			}
			&__list{
				order: 2;
				flex-wrap: wrap;
				flex-shrink: 0;
			   width: 336px;
			   background: linear-gradient(9deg, #4d51ad 0%, #2f5da2 100%);
			   box-shadow: -10px 0 10px rgba(27, 44, 85, 0.14);
				border-top: 0;
				padding: 40px 0;
				&:after,
				&:before{
					content: none;
				}
				&.owl-carousel{display: flex;}
			}
			&__item{
				display: flex;
				-ms-flex: 0 1 auto;
    			align-items: center;
				width: 100%;
	         flex-grow: 1;
				color: #fff;
				font-size: 11px;
				font-weight: 900;
				line-height: 1;
				padding: 14px 10px;
				margin-bottom: 13px;
				&:last-child{
					margin-bottom: 0;
					&:after{content: none;}
				}
				&--icon-description{
					&:before{@include sprite($fail-tab-icon);}
				}
				&--icon-characteristic{
					&:before{@include sprite($doc-tab-icon);}
				}
				&--icon-comment{
					&:before{@include sprite($reviews-tab-icon);}
				}
				&--icon-pharmacy{
					&:before{@include sprite($building-tab-icon);}
				}
				&--icon-region{
					&:before{@include sprite($skyscraper-tab-icon);}
				}
				&--icon-country{
					&:before{@include sprite($cityscape-tab-icon);}
				}
				&:before{
					content: '';
					display: block;
					flex-shrink: 0;
					margin-right: 10px;
				}
				&.tabs__item{
					padding-left: 61px;
					position: relative;
					color: #fff;
					text-align: left;
					transition: all .3s ease-out;
					&:after{
						content: '';
						display: block;
						position: absolute;
						left: 0;
						top: 1px;
						bottom: 1px;
						width: 4px;
						background-color: transparent;
					}
					&--active,
					&:hover,
					&:active{
						background-color: #1b6bb2;
						&:after{background-color: #fff;}
					}
				}
			}
			&__social{justify-content: flex-start;}
	   }

	}

}

/* -------------- tab-pharmacy-list --------------*/		
.tab-pharmacy-list{
	color: #333;
	&__title{
		font-size: 14px;
		font-weight: 900;
		line-height: 1.714;
		margin-bottom: 24px;
		text-transform: uppercase;
	}
	&__item{
		margin-bottom: 25px;
	}
	&__name{
		font-size: 16px;
		font-weight: 600;
		line-height: 1.5;
		text-decoration: none;
		color: #333;
		&:hover,
		&:active{
			color: $main-color;
		}
	}
	&__text{
		font-size: 16px;
		font-weight: 300;
		line-height: 1.5;
	}
}
/* -------------- tab-region --------------*/		
.tab-region{
	&__item{margin-bottom: 20px;}
	&__title{
		color: #333;
		font-weight: 700;
		font-size: 14px;
		line-height: 1;
		text-decoration: none;
		&-general{
			font-size: 14px;
			font-weight: 900;
			line-height: 1.714;
			margin-bottom: 24px;
			text-transform: uppercase;
		}
		&-box{
			display: flex;
			align-items: center;
			margin-bottom: 5px;
		}
		&:hover,
		&:active{
			color: $main-color;
		}
	}
	&__img{
		width: 23px;
		height: 15px;
		margin-right: 11px;
	}
	&__text{
		color: #333;
		font-weight: 300;
		font-size: 14px;
		line-height: 1;
		margin-left: 34px;
	}

	@media only screen and #{$tablet-hzl} {
		margin-left: 9px;
	}
	
}

/* -------------- load --------------*/		
.load{
	text-align: center;
	&__btn{margin-bottom: 24px;}
	&__link{
		display: block;
		color: #000;
		font-size: 14px;
		font-weight: 700;
		line-height: 1.285;
		text-decoration: none;
		&:active,
		&:hover{
			color: $main-color;
		}
	}
	&--card{
		width: 270px;
		margin: 25px auto 0 auto;
	}
	&--list{
		width: 270px;
		margin: 25px auto 0 auto;
		.btn{
			font-size: 16px;
			padding: 22px 10px;
		}
	}

	@media only screen and #{$d-mobile-hzl} {
		width: 100%;
		max-width: 544px;
	}
	@media only screen and #{$d-tablet-hzl} {
		max-width: 819px;
	}
	@media only screen and #{$d-desktop} {
		display: none;
		&--list{
			display: block;
			width: 360px;
		}
	}
}


/* -------------- category --------------*/		
.category{
	display: flex;
   flex-wrap: wrap;
   overflow: hidden;
	&__item{
		width: 240px;
		margin: 0 auto 40px auto;
		text-align: center;
	}
	&__img{
		display: inline-block;
		max-height: 160px;
	}
	&__img-box{
		height: 180px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 2px;
		&:hover{opacity: .7;}
		&:active{opacity: .5;}
	}
	&__title-link{
		display: block;
		color: #222;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.68;
		text-decoration: none;
		&:hover,
		&:active{
			text-decoration: none;
			color: $main-color;
		}
	}
}

/* -------------- thanks --------------*/		
.thanks{
	text-align: center;
	margin: 0 auto;
	&__title{
		color: #222;
		font-size: 48px;
		font-weight: 900;
		line-height: 1;
		margin-bottom: 18px;
	}
	&__text{
		max-width: 262px;
		color: #222;
		font-size: 18px;
		font-weight: 300;
		line-height: 1.5;
		margin: 0 auto 30px;
	}

	@media only screen and #{$d-mobile-hzl} {
		text-align: left;
		&__title{margin-bottom: 7px;}
		&__text{
			max-width: 100%;
			margin-left: 0;
			margin-bottom: 21px;
		}
	}
}



.l-header{ 
	position: relative;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
	&__content{
		display: flex;
	   align-items: center;
	   justify-content: space-between;
		background-image: linear-gradient(9deg, #6946b8 0%, #2f5da2 100%);
		padding: 6px 10px 7px;
	}

	@media only screen and #{$d-desktop} {
		box-shadow: none;
		&__box{
			display: flex;
    		align-items: center;
			width: 1093px;
			margin: 0 auto;
			position: relative;
		}
		&__content{
			flex-shrink: 0;
			position: relative;
			width: 160px;
			display: flex;
			flex-direction: column;
			padding: 14px 15px 20px;
			background: linear-gradient(9deg, #4d51ad 0%, #2f5da2 100%);
		}
		&__btn-menu{
			order: 2;
			margin-top: 16px;
		}
		&__btn-control{display: none;}
	}

}

.l-control-site{
	position: relative;
	background-color: #fff;
	margin-bottom: 3px;
	box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
	padding-left: 10px;
	padding-right: 10px;
	&__content{
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		padding: 15px 0 16px;

	}
	&__social-content{display: none;}
	&__td{
		display: none;
		&--mobi-visible{display: block;}
	}
	&__tr{
		max-width: 65%;
		-ms-flex: 0 1 auto;
	}
	&__top{max-width: 34%;}

	@media only screen and #{$d-desktop} {  
	    flex-grow: 2;
	    padding: 0;
	    margin: 0;
	    box-shadow: none;
	    &__content{
	    	display: table;
    		width: 100%;
    		padding: 0;
    		border-spacing: 1px;
    		border: 2px solid #ebeef0;
    		border-left: 0; 
		   border-collapse: separate;
		   background: #eff0f2;
	    }
	    &__tr{
	    	display: table-row;
	    }
	    &__td{
	    	display: table-cell;
    		vertical-align: middle;
    		background-color: #fff;
    		height: 51px;
    		&:nth-child(2){
    			width: 77px;
    			text-align: center;
    		}
    		&:nth-child(3){
    			width: 215px;
			   padding-left: 21px;
			   padding-right: 21px;
    		}
    		&-geo{
    			display: flex;
    			align-items: center;
    			padding: 0 21px;
    		}
    		&--btn-control{
    			transition: all .3s ease-out;
    			&:hover{
    				background-color: aliceblue;
    			}
    		}
	    }
	    &__info{width: 60%;}
	    &__social-content{
	    	display: flex;
	    	width: 39%;
	    }
	    &__basket{justify-content: flex-start;}
	}

}

.l-menu{ 
	position: fixed;
	-webkit-backface-visibility: hidden;
	width: 320px;
	height: 100%;
	z-index: 100;
   transform: translateX(-105%);
	z-index: 30;
	left: 0;
	top: 68px;
	padding: 0 0 23px;
	background-color: #f3f2f0;
	background: linear-gradient(9deg, #4d51ad 0%,#2f5da2 100%);
	box-shadow: 6.2px 7.9px 20px rgba(37, 76, 137, 0.44);
	transition: all 130ms ease;
	-webkit-overflow-scrolling: touch;
	&__search{
		position: relative;
		padding: 17px 18px 5px 18px;
	}
	&__content{ // ok
		max-height: 1300px;
		max-height: 100vh;
		overflow: auto;
		/* ie 10+ */
		//-ms-overflow-style: none;
		/* фф (свойство больше не работает, других способов тоже нет)*/
		//overflow: -moz-scrollbars-none;
		/* хром, сафари */
		//&::-webkit-scrollbar { width: 0; }
	}
	&__logo{display: none;} // ok
	&__basket{
		display: none;
		flex-shrink: 0;
		margin-left: 50px;
	}

	&--open{
		transform: none;
		transition: all 330ms ease;
	}

	@media only screen and #{$d-mobile-hzl} {
		transition: all 200ms ease;
	    &--open{
	       transition: all 500ms ease;
	    }
	}

	@media only screen and #{$d-tablet} {
		height: 612px;
		transition: all .3s ease;
		&__content{
			height: 612px;
			overflow: hidden;
		}
	   &--open{
	      transition: all 800ms ease;
    	}
	}

	@media only screen and (max-height: 679px) and (max-width: 1199px) {
		&__content{
			overflow: auto;
    		padding-bottom: 54px;
		}
	}

	@media only screen and #{$d-desktop} {
		visibility: hidden;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		top: 37px;
    	left: 100%;
	   transform: none;
	   z-index: 3;
	   height: auto;
	   min-height: 90px;
	   padding: 0;
	   box-shadow: 0 1px 6px rgba(0, 0, 0, 0.08);
	   &__search{
	   	display: none;
	   }
	   &__content{
	   	height: 534px;
	   	overflow: visible;
	   	max-height: auto;
	   }
	   &--open{
	   	visibility: visible;
	   	opacity: 1;
	   	pointer-events: auto;
	   }
	}

}

.l-presentation{
	position: relative;
	padding: 28px 0 32px;
	background-color: #fff;
	margin-bottom: 21px;
	&__card{
		padding-left: 10px;
		padding-right: 10px;
		&-box{margin-bottom: 10px;}
	}

	@media only screen and #{$d-desktop} {
		display: flex;
    	align-items: center;
		width: 1093px;
		height: 410px;
		border: 2px solid #ebeef0;
		padding: 20px 0;
    	margin: 0 auto;
    	margin-bottom: 33px;
	}

}

.l-hit{
	margin-bottom: 21px;
	@media only screen and #{$d-mobile-hzl} {margin-bottom: 33px;}
	@media only screen and #{$d-desktop} {
		margin-bottom: 18px;
		&__content{
			position: relative;
		}
	}
}

.l-info{
	&__site{margin-bottom: 4px;}
	&__manual{margin-bottom: 25px;}
	&__advantages{margin-bottom: 28px;}

	@media only screen and #{$d-mobile-hzl} {
		&__site{margin-bottom: 8px;}
		&__manual{margin-bottom: 29px;}
	}
	@media only screen and #{$d-tablet} {
		&__box{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: 8px;
		}
		&__site{
			width: 100%;
			border: 2px solid #ebeef0;
		}
		&__manual{
			order: 1;
			width: calc(50% - 5px);	
			margin-left: 5px;
			margin-bottom: 0;
			border: 2px solid #ebeef0;
		}
		&__advantages{
			display: flex;
			align-items: center;
			width: calc(50% - 5px);	
			margin-right: 5px;
			border: 2px solid #ebeef0;
			background-color: #fff;
			margin-bottom: 0;
			&--default{
				width: 100%;
			}
			&-box{-ms-flex: 0 1 auto;}
		}

	}
	@media only screen and #{$d-tablet-hzl} {
		&__box{margin-bottom: 0;}
		&__advantages{
			order: 2;
			width: 100%;
			margin: 52px 0 0;
			border-bottom-width: 1px;
			&-box{width: 100%;}
		}
		&__manual{
			width: 275px;
			margin: 0;
			&--card{
				width: 100%;
			}
		}
		&__site{
			width: calc(100% - 284px);
		}
	}
	@media only screen and #{$d-desktop} {
		&__site{
			width: calc(100% - 293px);
		}
		&--card{margin-top: -6px;}
	}
}

.l-articles{
	margin-bottom: 25px;
	@media only screen and #{$d-mobile-hzl} {margin-bottom: 40px;}
	@media only screen and #{$d-tablet-hzl} {margin-bottom: 30px;}
	@media only screen and #{$d-desktop} {
		margin-bottom: 56px;
		&__wrap{
			position: relative;
		}
	}
}

.l-card-product-form{
	font-family: $accent-fonts;
	position: relative;
	padding: 60px 30px 45px;
	text-align: center;
	background: linear-gradient(9deg, #4d51ad 0%, #2f5da2 100%);
	box-sizing: border-box;
	&__btn{
		max-width: 236px;
	}
	.product-form{
		position: relative;
		//overflow: hidden;
	}

	@media only screen and #{$d-mobile-hzl} {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media only screen and #{$d-tablet-hzl} {
		width: 101%;
		padding-bottom: 40px;
		&__btn{
			width: 256px;
		}
		&__agreement-text{
			max-width: 300px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}


.l-product{
	position: relative;
	&__info-wrap{
		position: relative;
	}
	&__header{
		text-align: center;
		margin-bottom: 20px;
	}
	&__content{
		background-color: #fff;
		padding: 17px 0 0;
	}
	&__info-box{
		width: 310px;
		padding: 0 10px;
		margin: 0 auto 42px auto;
	}
	&__breadcrumb{
		padding: 13px 10px;
	}
	&__title{
		color: #000;
		font-size: 21px;
		font-weight: 900;
		line-height: 1.142;
		margin-bottom: 12px;
		&-form{
			color: #000;
			background-color: #eff0f2;
			padding: 13px 10px;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.142;
			text-align: center;
		}
	}
	&__gallery-wrapper{
		margin: 0 0 20px;
	}


	&__code{margin-bottom: 20px;}
	&__offers{
		text-align: center;
	}
	&__btn-box{
		width: 227px;
		margin: 0 auto 30px auto;
	}
	&__price{
		margin-bottom: 7px;
		&-top,
		&-bottom{
			display: inline-block;
			text-align: left;
			margin-left: auto;
			margin-right: auto;				
		}
		&-top{
			position: relative;
			padding: 0 4px 17px;
			margin-bottom: 8px;
			&:after{
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				display: block;
				height: 4px;
				background-color: #efefef;
			}
		}
		&-now{
			color: #ff2400;
			font-size: 39px;
			font-weight: 700;
			text-decoration: underline;
		}
		&-old{
			opacity: 0.8;
			color: #000;
			font-size: 21px;
			font-weight: 300;
			line-height: 1;
			text-decoration: line-through;
			display: block;
			margin-bottom: 4px;
			.price-currency{
				font-size: 18px;
			}
		}
		&-new{
			display: inline-flex;
			align-items: baseline;
			color: $main-color;
			font-size: 38px;
			font-weight: 900;
			line-height: 1;
			text-decoration: none;
			.price-currency{
				color: $accent-color;
				font-weight: 400;
				font-size: 32px;
			}
		}
		&-text{
			&-new{
				color: #010200;
				font-size: 10px;
				font-weight: 900;
				line-height: 1;
				text-transform: uppercase;
			}
			&-old{
				opacity: 0.8;
				color: #000;
				font-size: 9px;
				font-weight: 600;
				line-height: 1;
				text-transform: uppercase;
			}
		}
	}
	&__countdown{margin-bottom: 25px;}
	&__system-text{
		color: #999;
		font-size: 12px;
		font-weight: 300;
		line-height: 1;
		margin-bottom: 10px;
	}
	&__raiting{
		margin-bottom: 35px;
	}
	&__raiting-stars{
		margin-bottom: 15px;
	}
	
	&__img-box{
		position: relative;
		text-align: center;
		img{
			max-width: 271px;
			max-height: 310px;
		}
	}
	&__note-text{
		color: #444;
		font-size: 13px;
		font-weight: 300;
		line-height: 1.23;
	}
	&__delivery{

	}
	&__promo{
		color: #000;
		font-size: 26px;
		font-weight: 400;
		margin-bottom: 27px;
	}

	&__agreement-text{
		color: #fff;
		font-size: 12px;
		line-height: 1.333;
	}

	&__form-wrap{
		margin: 0;
	}
	&-form{
		position: relative;
		padding: 45px 30px 32px;
		background: linear-gradient(9deg, #4d51ad 0%, #2f5da2 100%);;
		box-shadow: -10px 0 10px rgba(27, 44, 85, 0.14);
		text-align: center;
	}

	&--offer{
		box-sizing: border-box;
		.delivery{text-align: left;}
		.l-product__content{border: 0;}
	}

	@media only screen and #{$d-mobile-hzl} {
		&__title{
			margin-bottom: 15px;
		}
	}

	@media only screen and #{$d-tablet} {
		overflow: hidden;
		&__header{
			text-align: left;
			margin-bottom: 14px;
			transform: translateY(-4px);
		}
		&__content{
			margin-bottom: 0;
		}
		&__info-wrap{
			max-width: 750px;
			position: relative;
			padding-left: 391px;
		}
		&__info-box{
			width: 100%;
			max-width: 730px;
			margin-left: auto;
			margin-right: auto;
		}
		&__gallery-wrapper{
			position: absolute;
			top: 0;
    		left: 17px;
			z-index: 1;
		}
		&__title{margin-bottom: 5px;}
		&__offers{
			box-shadow: none;
		   border: 0;
		   background-color: transparent;
		   padding: 0;
		   margin: 0;
		   text-align: left;
		}
		&__price{
			display: flex;
    		align-items: center;
    		flex-wrap: wrap;
    		margin-bottom: 1px;
    		&-top{
    			margin-left: 0;
    			margin-bottom: 0;
    			padding: 2px 0 0;
    			order: 2;
    			&:after{
    				top: 0;
    			}
    		}
    		&-bottom{
    			width: 100%;
    			position: relative;
    			margin-left: 0;
    			margin-bottom: 7px;
    		}
    		&-new{
    			-ms-flex: 0 1 auto;
    		}
    		&-old{

    			-ms-flex: 0 1 auto;
    		}
		}
		&__raiting{
			margin-bottom: 16px;
			&-stars{margin-bottom: 12px;}
		}
		&__statistics{margin-bottom: 24px;}
		&__article{
			display: flex;
		   flex-wrap: wrap;
		   align-items: center;
		}
		&__btn-box{
			margin: 0 0 20px;
		}
		&__geo{
			margin: 0 0 5px;
			-ms-flex: 0 1 auto;
		}
		.product-article__text{
			margin: 0 8px 5px 0;
			-ms-flex: 0 1 auto;
			&:last-of-type{
				margin-right: 18px;
			}
		}

		&__delivery{
		
		}
		.delivery__img-box{
			display: flex;
			align-items: center;
			flex-shrink: 0;
			margin: 0;
			margin-bottom: 9px;
			picture, img{
				display: block;
				flex-shrink: 0;
				&:first-child{margin-right: 3px;}
			}
		}
		.delivery__text{
			-ms-flex: 0 1 auto;
			text-align: left;
		}
	}


	@media only screen and #{$d-desktop} {
		width: 100%;
		overflow: visible;
		padding-left: 10px;
		padding-right: 10px;
		transform: translateY(-42px);
		&__container{
			width: 1093px;
			margin: 0 auto;
			margin-bottom: 45px;
		}
		&__content{
			position: relative;
			display: flex;
			justify-content: flex-end;
			padding: 0;
			border: 2px solid #ebeef0;
		}
		&__info-wrap{
			max-width: 726px;
		   width: 100%;
		   left: auto;
    		right: -10px;
			display: flex;
			flex-wrap: wrap;
			float: none;
		   margin: 0;
		   padding-left: 372px;
		}
		&__info-box{
			-ms-flex: 0 1 auto;
			max-width: 100%;
			padding-top: 26px;
			margin-bottom: 0;
		}
		&__header{
			-ms-flex: 0 1 auto;
			margin-bottom: 4px;
		}
		&__breadcrumb{padding-left: 182px;}
		&__gallery-wrapper{
			-ms-flex: 0 1 auto;
			left: 0;
		}
		&__offers{
			-ms-flex: 0 1 auto;
			max-width: 100%;
		}

		&__title-form{display: none;}

		&__delivery{max-width: 464px;}
		&__form-wrap{
			-ms-flex: 0 1 auto;
			flex-shrink: 0;
			margin-bottom: 0;
		}

		&-form{
			width: 336px;
			display: flex;
    		align-items: center;
		}

		.l-product-filter{margin-bottom: -0;}
	}

}

.l-product-info{
	margin-bottom: 23px;
	@media only screen and #{$d-tablet} {
		max-width: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 0;
	}
	@media only screen and #{$d-desktop} {
		width: 1093px;
		position: relative;
	   padding-left: 0;
	   padding-right: 0;
	   background-color: #fff;
	}
}

.l-product-filter{ 
	margin-bottom: 37px;
	&__header{
		text-align: center;
		margin-bottom: 29px;
	}
	&__load{
		margin-top: 15px;
	}

	&--similar{
		padding: 0 10px;
	}

	@media only screen and #{$d-mobile-hzl} {
		margin-bottom: 30px;
		&__list{
			display: flex;
			flex-wrap: wrap;
			width: 546px;
    		margin: 0 auto;
    		.product-card{
    			margin-left: 1px;
    			margin-right: 1px;
    		}
		}
	}


	@media only screen and #{$d-tablet-hzl} {
		margin-bottom: 48px;
		&--similar{padding: 0;}
		&__list{
			width: 819px;
		}
	}

	@media only screen and #{$d-desktop} {
		&__content{
			position: relative;

		}
		&__list{
			width: 100%;
		}

		&__load{display: none;}
	}

}

.l-product-similar{
	padding-top: 20px;
	padding-bottom: 56px;
	&__title{
		margin-bottom: 32px;
	}
}

/* -------------- offer --------------*/		
.offer{
	display: flex;
	align-items:center;
	&__content{
	   width: 100%;
	   display: flex;
	   -ms-flex: 0 1 auto;
	   justify-content: space-between;
	   align-items: center;
	 }
	&__img{
		height: 70px;
		margin-right: 29px;
	}
	&__title{
		-ms-flex: 0 1 auto;
		color: #000;
		font-size: 18px;
		font-weight: 900;
		line-height: 1;
	}
	&__price{
		flex-shrink: 0;
		&-old{
			opacity: 0.8;
			color: #000;
			font-size: 21px;
			font-weight: 300;
			line-height: 2;
			text-decoration: none;
		}
		&-new{
			color: $main-color;
			font-size: 38px;
			font-weight: 900;
			line-height: 42px;
			line-height: 1.105;
			text-decoration: none;
			.price-currency{
				color: #000;
			   font-size: 28px;
			   font-weight: 400;
			   margin-left: -7px;
			}
		}

		&-old-val{text-decoration: line-through;}
	}
}

.l-scroll-bar{
	display: none;
	position: fixed;
	z-index: 200;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f7f8fb;
	//opacity: 0.9;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
	padding: 11px 0px;
	&:hover{
		background-color: #f3f2f0;
	}
	&__left{display: none;}
	&__wrap{
		display: none;
		justify-content: center;
		align-items: center;

	}
	&__btn{
		width: 301px;
		color: #fff;
		font-size: 14px;
		font-weight: 700;
		line-height: 1.142;
		background-image: linear-gradient(9deg, #2f73fc 0%, #1c48a2 100%);
		border-radius: 2px;
		box-shadow: 0 5px 20px rgba(20, 57, 133, 0.4);
		text-transform: uppercase;
		text-decoration: none;
		letter-spacing: 5px;
		display: block;
		padding: 17px 15px;
		text-align: center;
		margin: 0 auto;
	}
	&__btn-box{width: 302px;}

	&--fixed{
		display: block;
		animation: fade 0.5s linear;
		transition: all .7s linear;
	}

	@media only screen and #{$d-tablet} {
		padding-top: 5px;
		padding-bottom: 5px;
		background-color: #fff;
		&__wrap{
			display: flex;
			justify-content: space-between;
		}
		&__left{
	      flex-grow: 2;
	      flex-shrink: 1;
	      display: block;
	      margin-right: 20px;
	    }
		&__btn{display: none;}
		&__btn-box{width: auto;}
	}

	@media only screen and #{$d-tablet-hzl} {
		padding-left: 10px;
		padding-right: 10px;
	}

	@media only screen and #{$d-desktop} {
		width: 1093px;
	   margin: 0 auto;
	   padding: 0;
		&__wrap{
			padding-left: 25px;
    		padding-right: 25px;
		}
		&>.container{
			width: auto;
    		padding: 0;
		}
	}

}

.l-category{
	margin-bottom: 22px;
	&__container{
		background-color: #fff;
	}
	&__breadcrumb{
		background-color: #fff;
		padding: 16px 10px 28px;
	}
	&__title{
		text-align: center;
		color: #222;
		font-size: 24px;
		font-weight: 900;
		line-height: 1.125;
	}
	&__text{
		text-align: center;
		color: #222;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.68;
		&--margin{margin-bottom: 28px;}
	}
	&__site-info{
		min-width: auto;
		margin-bottom: 25px;
	}

	@media only screen and #{$d-mobile-hzl} {
		margin-bottom: 18px;
	}

	@media only screen and #{$d-desktop} {
		margin-bottom: 36px;
		&__container{
			padding: 40px 0;
			border: 2px solid #ebeef0;
		}
		&__breadcrumb{
			width: 1093px;
    		margin: 0 auto;
    		background-color: transparent;
		   margin-top: -36px;
		   padding: 11px 10px 11px 182px;
		}
	}
}

.l-block{
	@media only screen and #{$d-desktop} {
		&-1{
			position: relative;
			&:after{
				content: '';
				position: absolute;
				z-index: -1;
				top: 61px;
				left: -74px;
				display: block;
				width: 633px;
				height: 352px;
				border: 29px solid rgba(212, 221, 241, 0.51);
			}
		}
		&-2{
			position: relative;
			&:after{
	    		content: '';
			   position: absolute;
			   z-index: -1;
			   top: 50%;
	    		right: -74px;
			   transform: translateY(-14px);
			   display: block;
			   width: 633px;
			   height: 700px;
			   border: 29px solid rgba(212, 221, 241, 0.51);
	    	}
		}
		&-3{
			position: relative;
			&:after{
	    		content: '';
			   position: absolute;
			   z-index: -1;
			   top: 50%;
	    		left: -74px;
			   transform: translateY(-48px);
			   display: block;
			   width: 633px;
			   height: 690px;
			   border: 29px solid rgba(212, 221, 241, 0.51);
	    	}
		}
		&-4{
			position: relative;
			&:after{
	    		 content: '';
			    position: absolute;
			    z-index: -1;
			    top: 50%;
	    		 right: -74px;
			    transform: translateY(-27px);
			    display: block;
			    width: 633px;
			    height: 395px;
			    border: 29px solid rgba(212, 221, 241, 0.51);
	    	}
		}
		&-6{
			position: relative;
			&:after{
				content: '';
			    position: absolute;
			    z-index: -1;
			    top: 50%;
			    right: -74px;
			    transform: translateY(-33px);
			    display: block;
			    width: 633px;
			    height: 700px;
			    border: 29px solid rgba(212, 221, 241, 0.51);
			}
		}
		&-7{
			position: relative;
			&:after{
		   	 content: '';
			    position: absolute;
			    z-index: -1;
			    top: 51%;
			    left: -74px;
			    display: block;
			    width: 633px;
			    height: 690px;
			    border: 29px solid rgba(212, 221, 241, 0.51);
		   }
		}
		&-8{
			position: relative;
			&:after{
				content: '';
			   position: absolute;
			   z-index: -1;
			   top: 54%;
			   right: -74px;
			   width: 633px;
			   height: 387px;
			   border: 29px solid rgba(212, 221, 241, 0.51);
			}
		}
		&-9{
			position: relative;
			&:after{
				content: '';
			   position: absolute;
			   z-index: -1;
			   top: 50%;
			   right: -74px;
			   transform: translateY(-18px);
			   display: block;
			   width: 633px;
			   height: 670px;
			   border: 29px solid rgba(212, 221, 241, 0.51);
			}
		}
		&-10{
			position: relative;
			&:after{
				content: '';
			   position: absolute;
			   z-index: -1;
			   top: 381px;
			   left: -74px;
			   display: block;
			   width: 633px;
			   height: 433px;
			   border: 29px solid rgba(212, 221, 241, 0.51);
			}
		}
		&-12{
			position: relative;
			&:after{
				content: '';
			   position: absolute;
			   z-index: -1;
			   top: 46%;
			   right: -74px;
			   width: 633px;
			   height: 430px;
			   border: 29px solid rgba(212, 221, 241, 0.51);
			}
		}

	}
	@media only screen and (min-width: 1280px) {
		&-5{
			position: relative;
			&:after{
	    		 content: '';
			    position: absolute;
			    z-index: -1;
			    bottom: -51px;
	    		 left: -74px;
			    display: block;
			    width: 633px;
			    height: 629px;
			    border: 29px solid rgba(212, 221, 241, 0.51);
	    	}
		}
		&-11{
			position: relative;
			&:after{
	    		 content: '';
			    position: absolute;
			    z-index: -1;
			    bottom: -51px;
	    		 right: -74px;
			    display: block;
			    width: 633px;
			    height: 979px;
			    border: 29px solid rgba(212, 221, 241, 0.51);
	    	}
		}
	}

}

.l-thanks{
	padding: 15px 0 38px;
	margin-bottom: 8px;
	background-color: #fff;
   position: relative;
	&__text{
		color: #000;
		font-size: 14px;
		font-weight: 600;
		line-height: 1.928;
		margin-bottom: 11px;
	}
	&__form{
		max-width: 291px;
		margin: 0 auto;
	}
	&__footer{
		margin-top: 26px;
		text-align: center;
	}
	@media only screen and #{$d-mobile-hzl} {
		background-image: url(../img/women-thanks.png);
   	background-position: 100% 0;
   	background-size: auto 100%;
   	background-repeat: no-repeat;
		&__content{
			max-width: 300px;
			margin-left: 10px;
		}
		&__form{margin-left: 0;}
		&__footer{text-align: left;}

	}
	@media only screen and #{$d-tablet} {
		&__content{margin-left: 46px;}
	}
	@media only screen and #{$d-tablet-hzl} {
		padding: 81px 0 51px;
		&__content{
			max-width: 570px;
		   margin-left: auto;
		   margin-right: 320px;
		}
		&__form{max-width: 417px;}
		&__footer{margin-top: 47px;}
	}
	@media only screen and #{$d-desktop} {
		width: 1093px;
    	margin: 0 auto;
		margin-bottom: 51px;
		border: 2px solid #ebeef0;
	}

}

.l-footer{ 
	&__content{
		background-color: #fff;
		padding-top: 38px;
		padding-bottom: 19px;
		margin-bottom: 10px;
	}
	&__wrap{
		width: 262px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
	}
	&__item{
		margin-bottom: 22px;
		&--logo{margin-bottom: 30px;}
		&--contacts{
			.l-footer__item-title{margin-bottom: 11px;}
		}
		&--card{margin-bottom: 14px;}
		&--more{
			width: 180px;
			margin-left: auto;
			margin-right: auto;
		}
	}
	&__copyright{
		color: #888;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.5;
	}
	&__logo{margin-bottom: 6px;}
	&__item-title{
		color: $accent-color;
		font-size: 14px;
		font-weight: 900;
		line-height: 1.285;
		margin-bottom: 20px;
	}
	&__item-link{
		display: block;
		color: $accent-color;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.285;
		text-decoration: none;
		margin-bottom: 20px;
		&:hover{text-decoration: underline;}
	}
	&__text{
		color: #888;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.5;
	}
	&__link{
		color: #245bc9;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.5;
		text-decoration: none;
		&:hover{text-decoration: underline;}
	}
	&__support{
		.l-footer__item-title{margin-bottom: 6px;}
	}
	&__pay-content-tablet{display: none;}
	
	@media only screen and #{$d-tablet} {
		&__content{
			padding-bottom: 27px;
			border: 2px solid #ebeef0;
		}
		&__wrap{
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			text-align: left;
			padding-left: 25px;
			padding-right: 20px;
		}
		&__group-item{
			width: 32%;
			&:first-child,
			&:nth-child(2){
				padding-right: 5px;
			}
			&--info{width: 36%;}
			.pay-card{
				margin-left: -10px;
				margin-right: 0;
			}
			&--copyright{width: 100%;}
		}
		&__item{
			&--contacts{
				.l-footer__item-title{margin-bottom: 17px;}
			}
			&--logo{display: inline-block;}
			&--more{
				margin-left: 0;
				margin-right: 0;
			}
			&--card-tablet-hidden{display: none;}
		}
		&__pay-content-tablet{display: block;}
		&__copyright{display: inline;}
		&__text{display: inline;}
		&__link{display: block;}
	}

	@media only screen and #{$d-tablet-hzl} {
		&__content{border-top: 0;}
		&__group-item{
			width: 25%;
			&--info{
				width: 50%;
				display: flex;
				.l-footer__item{
					width: 50%;
				}
			}
			&--copyright{
				width: 100%;
				display: flex;
				justify-content: space-between;
				margin-top: 10px;
			}
		}
		&__item{
			&--card{
				order: 1;
				flex-shrink: 0;
			}
			&--contacts{
				.l-footer__item-title{margin-bottom: 11px;}
			}
			&--copyright{padding-right: 10px;}
			&--card-tablet-hidden{display: block;}
		}
		&__logo{margin-bottom: 10px;}
		&__item-title{margin-bottom: 14px;}
		&__adress{padding-right: 5px;}
		&__pay-content-tablet{display: none;}
	}



	@media only screen and (min-width: 1280px) {
		&__content{
			position: relative;
			padding-bottom: 3px;
			margin-bottom: 49px;
		}
		&__up{
			position: absolute;
			top: -57px;
    		right: -70px;
		}
	}

}





/* -------------- geo --------------*/		
.geo{ //ok
	width: 100%;
	max-width: 154px;
	margin: 0 auto;
	&__title{
		color: #fff;
		font-size: 16px;
		line-height: 1.5;
		text-align: center;
		margin-bottom: 40px;
	}
	&__btn{margin-bottom: 25px;}
}

/* -------------- statistics --------------*/		
.statistics{
	display: flex;
	&__bought{
		margin-right: 46px;
	}
	&__text{
		color: #000;
		font-size: 16px;
		font-weight: 400;
		line-height: 1;
	}
	&__count{
		color: $main-color;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.75;
		&--red{color: #ff2400;}
	}
}



/* -------------- article-area --------------*/		
.article-area{ //ok
	&__first-text{
		text-indent: 12px;
	}
	h2,h3,h4,h5,p,span{
		color: #444;
		font-size: 14px;
		line-height: 1.714;
		text-align: justify;
		margin-bottom: 24px;
	}
	h2,h3,h4,h5,h6{font-weight: 700;}
	p, span{font-weight: 100;}

	ul{
		padding-left: 5px;
		margin-bottom: 24px;
		li{
			color: #444;
			font-size: 14px;
			font-weight: 100;
			line-height: 1.714;
			text-align: justify;
			&:before{
				content: '-';
				position: relative;
				left: -5px;
			}
		}
	}

}



/* -------------- product-gallery-wrapper --------------*/		
.product-gallery-wrapper{
	text-align: center;
}


/* -------------- product-article --------------*/		
.product-article{//ok
	&__text{
		color: #999;
		font-size: 12px;
		line-height: 1.666;
		font-weight: 300;
	}
}

/* -------------- item-link--------------*/		
.item-link{ //ok
	color: #000;
	font-size: 12px;
	font-weight: 100;
	line-height: 1.333;
	text-decoration: none;
	&:hover{
		color: $main-color;
	}
	&--line{
		&:before{
			content: ' /';
			padding: 0 9px;
		}
	}
}


/* -------------- raiting --------------*/		
.raiting{
	&__text{
		color: #000;
		font-size: 11px;
		font-weight: 900;
		line-height: 1;
		text-transform: uppercase;
		margin-bottom: 3px;
		letter-spacing: 1px;
	}
	@media only screen and #{$d-tablet} {
		&__text{margin-bottom: 0;}
	}
}

/* -------------- raiting-static --------------*/		
.raiting-static{
	display: block;
	line-height: 1;
	&__item{
		display: inline-block;
		width: 17px;
		height: 4px;
		background-color: #1c48a2;
	}
}


/* -------------- footer-menu --------------*/		
.footer-menu{ //ok
	text-align: center;
	&__list{
		display: inline-flex;
		flex-wrap: wrap;
	}
	&__item{
		position: relative;
		padding-right: 24px;
		margin-bottom: 5px;
		&:before{
			content: '';
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			display: block;
			width: 4px;
			height: 4px;
			background-color: #666;
		}
		&:last-child{
			padding-right: 0;
			&:before{content: none;}
		}
	}
	&__link{
		color: #666;
		font-size: 14px;
		font-weight: 300;
		line-height: 1.5;
		text-decoration: none;
		&:hover{color: $main-color;}
	}

}

/* -------------- footer-contacts --------------*/		
.footer-contacts{//ok
	&__tel{
		display: inline-block;
		color: #666;
		font-size: 26px;
		line-height: 1.2;
		text-decoration: none;
		margin-bottom: 6px;
		&:active,
		&:hover{
			color: $main-color;
		}
	}
	&__text{
		color: #666;
		font-size: 14px;
		line-height: 1.5;
	}

	@media only screen and #{$d-tablet-hzl} {
		&__tel{margin-bottom: 0;}
	}
}

/* -------------- line-2 --------------*/		
.line-2{
	display: flex;
	justify-content: center;
	width: 10.6%;
	&__content{
		position: relative;
		display: block;
		width: 15px;
		height: 85px;
		&:before,
		&:after{
			content: '';
			display: block;
			position: absolute;
			width: 1px;
			height: 65px;
			opacity: 0.68;
			background-color: #4e80be;
		}
		&:before{
			top: 0;
			left: 0;
		}
		&:after{
			bottom: 0;
			right: 0;
		}
	}

	&--last:last-child{display: none;}

	@media only screen and (max-width: 380px) {
		width: 5%;
		&--last{display: none;}
		&:first-child{display: none;}
		&:nth-child(5n){display: none;}
	}

	@media only screen and (min-width: 620px) {
		width: 3%;
		&--last{display: none;}
		&:first-child{display: none;}
		&:nth-child(10n){display: none;}
	}

	@media only screen and #{$mobile-hzl} {
		width: 3%;
		&--last{
			&:nth-child(10n+1){
				display: flex;
			}
		}
		&:first-child{display: flex;}
		&:nth-child(10n){display: flex;}
	}

	@media only screen and #{$laptops} {
		width: 5%;
		&--last{
			&:nth-child(10n+1){
				display: none;
			}
		}
		&:first-child{display: none;}
		&:nth-child(15n){
			display: none;
		}
	}

}

/* -------------- Yandex maps --------------*/		
.ymaps-2-1-53-controls__toolbar_right{
	margin-right: 65px;
}

/* -------------- product-geo --------------*/		
.product-geo{
	display: flex;
	align-items: center;
	justify-content: center;
	&__icon{
		width: 23px;
	   height: 15px;
	   flex-shrink: 0;
	   display: block;
	   margin-right: 10px;
	}
	&__map{
		-ms-flex: 0 1 auto;
		display: block;
		color: #051a37;
		font-size: 12px;
		font-weight: 100;
		line-height: 1.333;
	   border-bottom: 1px dashed #051a37;
	   text-decoration: none;
	   &:hover{
	   	border-color: transparent;
    		opacity: .7
	   }
	}
}

/* -------------- presentation --------------*/		
.presentation{ //ok
	width: 300px;
	text-align: center;
	margin: 0 auto;
	padding: 30px 10px 40px;
	&__title{
		color: #fff;
		font-family: 'AcsiomaNextC', 'Museo Sans', sans-serif, -apple-system,BlinkMacSystemFont,Roboto,Open Sans,Helvetica Neue;
		font-size: 42px;
		font-weight: 400;
		line-height: 1.25;
		letter-spacing: 5px;
		margin-bottom: 18px;
		text-transform: uppercase;
		text-shadow: 4px -4px 0 rgba(255,255,255,.14);
	}
	&__text{
		color: #fff;
		font-size: 16px;
		font-weight: 100;
		line-height: 1.5;
		margin-bottom: 40px;
	}

	.owl-stage-outer{margin-bottom: 18px;}

	@media only screen and #{$d-tablet-hzl} {
		width: 748px;
		padding-left: 0;
		padding-right: 0;
		&__text{
			width: 445px;
			margin-left: auto;
			margin-right: auto;
		}
		&__text-box{
			width: 525px;
			margin: 0 auto;
		}
	}

	@media only screen and #{$d-desktop-small} {
		width: 100%;
		padding-left: 50px;
		padding-right: 50px;
		&__title{font-size: 60px;}
		&__text{
			width: 500px;
			font-size: 21px;
		}
		&__text-box{width: 750px;}
	}
}


/* -------------- slide --------------*/		
.slide{
	&__wrap{
		display: inline-block;
		text-align: left;
	}
	&__title-action{
		display: block;
		color: #222;
		font-size: 16px;
		font-weight: 300;
		line-height: 1.75;
		margin-bottom: 30px;
		&:after{
			content: '\e803';
			font-family: "fontello";
   		font-size: 12px;
    		line-height: 2;
    		color: #1e467f;
    		 margin-left: 10px;
		    vertical-align: middle;
		    display: inline-block;

		}
		&:hover{
			cursor: pointer;
		}
		&--hidden{
			&:after{
				content: '\e804';
			}
			~ .slide__content-box{display: none;}
		}
	}
	&__content-box{
		display: inline-block;
		text-align: left;
		margin-bottom: 15px;
	}
	&__item,
	&__item span,
	&__link{
		color: #333;
		font-size: 13px;
		font-weight: 300;
		line-height: 2;
	}
	&__link{
		transition: all .3s ease-out;
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
	}
	&__city{
		text-decoration: underline;
	}
	&--center{
		text-align: center;
	}
	&__country-icon{
		margin-right: 9px;
	}
}


/* -------------- clearfix --------------*/		
.clearfix{
	&:before{
		content:" ";
	    display:table;
	    clear:both;
	}
	@media only screen and #{$d-desktop-small} {
		&:before{
			content: none;
		}
		&:after{
			content:" ";
		   display:table;
		   clear:both;
		}
	}

	&-thanks{
		&:after{
			content:" ";
		   display:table;
		   clear:both;
		}
	}
}


p.city-list__letter{
	color: #444;
	font-size: 14px;
	font-weight: 700;
	line-height: 1.5;
}

.browserupgrade{
	text-align: center;
	padding: 100px 0;
	font-size: 20px;
}

.modal-container{
	@media only screen and #{$d-desktop-small} {
		position: absolute;
		z-index: 100;
		top: 0;
		left: 10px;
	}
	@media only screen and #{$d-desktop} {
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 1280px;
    	
	}
}

/* -------------- agreement --------------*/		
.agreement{
	&-user{
		&__text{
			text-align: left;
			color: #fff;
			font-size: 12px;
			font-weight: 300;
			line-height: 1.333;
		}
	}
	// @media only screen and #{$mobile-hzl} {
	// 	max-height: 400px;
	//    overflow: auto;
	// }
}




/*-- =========================
     layout
============================== --*/	
.l-container{
	background-color: #f8f8f8;
	padding-top: 20px;
	padding-bottom: 40px;
}

.l-block{
	background-color: #fff;
	padding: 30px 4%;
	@media only screen and #{$desktop} {
		width: 1173px;
    	margin: 0 auto;
	}
}

.l-top-line{ //ok
	background-color: #fff;
	display: none;
	@media only screen and #{$d-desktop-small} {
		display: block;
		 border-bottom: 1px solid #eeedeb;
	    padding: 5px 0;
	    &__content{
	      display: flex;
	      justify-content: space-between;
	      align-items: center;
	    }
	    &__item{
	    	flex-wrap: wrap;
	      &:first-child{
	        margin-right: 15px;
	      }
	      &--social{
	        display: flex;
	        justify-content: flex-end;
	        align-items: center;
	        .social{flex-shrink: 0;}
	      }
	    }
	   &__social{
	      margin: 0 25px;
	   }
	}
}

.l-article{
	&__header{ //ok
		padding: 33px 0 23px;
	}
	&__content{//ok
		background-color: #fff;
		border-radius: 10px;
		padding-top: 27px;
		padding-bottom: 62px;
	}
	&__breadcrumb{margin-bottom: 6px;}
	&__title{//ok
		color: #000;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.125;
		text-align: center;
		margin-bottom: 9px;
	}
	&__info-comment{//ok
		margin-left: 6px;
	}
	&__info-box{
		margin-bottom: 15px;
	}
	&__date,
	&__info-comment{//ok
		opacity: 0.71;
		color: #888;
		font-size: 12px;
		font-weight: 100;
		line-height: 2;
		display: inline-block;
	}
	&__date-box{
		margin-bottom: 15px;
		text-align: right;
	}

	.container-wrap{padding: 0 10px;}


	@media only screen and #{$d-mobile-hzl} {
		&__header{
			padding: 40px 0 35px;
		}
		&__content{padding-bottom: 93px;}
		.container-wrap{padding: 0 15px;}
	}

	@media only screen and #{$d-tablet-hzl} {
		&__header{
			padding: 48px 0 58px;
		}
		&__content{
			width: 959px;
			padding-top: 40px;
			padding-bottom: 145px;
			margin-left: auto;
			margin-right: auto;
		}
		.container-wrap{padding: 0 62px;}
	}

	@media only screen and #{$d-desktop-small} {
		
	}

}

.l-description{
	padding: 40px 0 25px;
	background-color: #f8f8f8;
	@media only screen and #{$laptops} {
		padding: 90px 0 93px;
	}

}

.l-breadcrumb{

}

.l-breadcrumb-box{margin-bottom: 10px;}

.l-category-article { //ok
	padding-bottom: 68px;
	&__header{
		padding: 33px 0 23px;
	}

	@media only screen and #{$d-mobile-hzl} {
		padding-bottom: 100px;
		&__header{
			padding: 40px 0 35px;
		}
	}

	@media only screen and #{$d-tablet-hzl} {
		padding-bottom: 84px;
		&__header{
			padding: 48px 0 58px;
		}
	}

	@media only screen and #{$d-desktop-small} {
		padding-bottom: 102px;
	}

}


.l-product-4_item{
	@media only screen and (min-width: 570px) {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 15px;
		&:after{
			content: '';
			height: 0;
			flex: auto;
		   max-width: 258px;
		   min-width: 258px;
		   margin: 0 auto;
		}
	}

	@media only screen and (min-width: 794px) { 
		max-width: 858px;
		margin: 0 auto;
		justify-content: space-between;
		&:after{
			flex-grow: 1;
			width: 33.333%;
			margin: 0;
		}
		.product-card{
			margin-left: 0;
			margin-right: 0;
		}
	}

	@media only screen and (min-width: 1191px) {
		max-width: 1125px;
		justify-content: flex-start;
		.product-card{
			margin-right: 31px;
			&:nth-child(4n){
				margin-right: 0;
			}
		}
	}
}

.l-product-list{//ok

	&__text{
		color: #222;
		font-size: 13px;
		font-weight: 400;
		line-height: 1.615;
		&-box{
			margin-top: 45px;
		}
	}

	&-box{margin-bottom: 65px;}

	.product-card:nth-child(3n+2){
		.btn--basket{
			background-color: $main-color;
			&:before{
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@include sprite($cart-white-icon);
				@media only screen and #{$retina} {
		   		background-image: url($cart-white-icon-2x-image);
		    		background-size: $cart-white-icon-2x-total-width / 2 $cart-white-icon-2x-total-height / 2;
		   	}
			}
		}
	}

	@media only screen and #{$d-tablet-hzl} {
		width: 960px;
		margin: 0 auto;
		padding-bottom: 30px;
		display: flex;
		flex-wrap: wrap;
	}
	@media only screen and #{$d-desktop-small} {
		width: 1002px;

		&-box{margin-bottom: 105px;}
	}
}

.l-product-country{
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	box-shadow: 4.6px 2px 35px rgba(0, 0, 0, 0.16);
	padding: 35px 0 15px;
	margin-bottom: 63px;
	&__box{margin-bottom: 27px;}
	@media only screen and #{$d-tablet-hzl} {
		padding: 54px 0 25px;
		&__wrap-box{
			display: flex;
		}	
		&__box{width: 50%;}
	}
}

.l-geo{
	padding: 55px 0 60px;
	overflow: hidden;
	position: relative;
	&__content{
		position: relative;
	}
	&:before{
		content: '';
		display: block;
		position: absolute;
		bottom: 18px;
		left: 17px;
		width: 383px;
		height: 383px;
		border-radius: 50%;
		background-color: $main-color;
	} 

}

.l-modal-product{
	display: none!important;
	&__countdown{margin-bottom: 17px;}
	&__statistics{margin-bottom: 14px;}
	.product-form{box-sizing: border-box;}
	.l-product-form{padding: 47px 0px 50px 55px;}
	@media only screen and (min-width: 1200px) {
		display: flex!important;
	}
}

.l-product-slider{
	padding: 57px 0;
	overflow: hidden;
	&__title{
		color: #444;
		font-size: 18px;
		font-weight: 600;
		line-height: 1.3333;
		text-align: center;
		margin-bottom: 30px;
	}
}




.l-article-list{ //ok
	width: 100%;
	&__header{

	}
	&__item{
		width: 100%;
		max-width: 445px;
		margin: 0 auto 32px auto;
	}

	&-box{margin-bottom: 65px;}

	@media only screen and #{$d-tablet-hzl} {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		&__item{
			margin-left: 0;
			margin-right: 0;
			margin-bottom: 35px;
		}

		&-box{
			max-width: 930px;
			margin: 0 auto 35px auto;
		}
	}

	@media only screen and #{$d-desktop-small} {
		&__item{
			margin-bottom: 66px;
		}
		&-box{
			max-width: 960px;
			margin-bottom: 0;
		}
	}
	
}





/*-- =========================
     animation
============================== --*/	
@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
        transform: translate3d(0,100%,0);
    }

    1% {
        display: block;
        opacity: 0;
    }

    100% {
        display: block;
        opacity: 1;
        transform: none;
    }
}

@keyframes fade {
    0% {
        display: none;
        opacity: 0;
    }

    1% {
        display: block;
        opacity: 0;
    }

    50% {
    	opacity: 0.5;
    }

    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
/*-- =========================
     libs custom
============================== --*/	
.owl-carousel .owl-item img{width: auto;}

.modal--default .modal-content{
	max-height: 100vh;
	overflow-y: auto;
}




/*-- =========================
     media
============================== --*/	

@media #{$retina} {
  /* -------------- icon --------------*/    
  .btn-icon{

    // &--menu{
    //   background-image: url($menu-icon-2x-image);
    //   background-size: $menu-icon-2x-total-width / 2 $menu-icon-2x-total-height / 2;
    // }
    
  }

  .EX{
    background-image: url(-2x-image);
    background-size: -2x-total-width / 2 -2x-total-height / 2;
  }
  
}



// правки
@media only screen and (max-width: 380px) {
	.line-2:last-of-type{
		display: none;
	}
}

@media only screen and (max-width: 445px) {
	
}

@media only screen and (min-width: 620px){
	.line-2:last-of-type{
		display: none;
	}
	.line-2:nth-last-of-type(2){
		display: none;
	}
}

@media only screen and (min-width: 769px){
	.line-2:last-of-type{
		display: flex;
	}
	.line-2:nth-last-of-type(2){
		display: none;
	}
}

@media only screen and (min-width: 1025px){
	.line-2:last-of-type{
		display: none;
	}
}










    







